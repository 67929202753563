import * as React from 'react';
import { observer } from 'mobx-react';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import { FieldState } from 'formstate';
import Flatpickr from 'react-flatpickr';
import moment from 'moment';
import _ from 'lodash';

export interface TimeslotInterface {
    "from": string,
    "to": string
}

export interface TimeslotsInterface {
    0: { timeSlots: Array<TimeslotInterface> },
    1: { timeSlots: Array<TimeslotInterface> },
    2: { timeSlots: Array<TimeslotInterface> },
    3: { timeSlots: Array<TimeslotInterface> },
    4: { timeSlots: Array<TimeslotInterface> },
    5: { timeSlots: Array<TimeslotInterface> },
    6: { timeSlots: Array<TimeslotInterface> }
}

interface Props {
    fieldState: FieldState<TimeslotsInterface>,
    timeslotDurationMinutes: FieldState<number>,
    timeslotBuffer: FieldState<number>
}

interface State {
    showTimeslotDetails: boolean
}

@observer class Timeslots extends React.Component<Props, State> {

    state = { showTimeslotDetails: false }

    constructor(props: Props) {
        super(props);

        props.timeslotDurationMinutes.onDidChange(({ newValue, oldValue }: any) => {
            if (newValue > 0) {
                this.refreshWeek();
            }
        })

        props.timeslotBuffer.onDidChange(({ newValue, oldValue }: any) => {
            if (newValue !== oldValue) {
                this.refreshWeek();
            }
        })
    }

    refreshWeek = () => {
        for (let day = 0; day < 7; day++) {
            const from = moment(this.getStartTime(day), 'HH:mm');
            const to = moment(this.getEndTime(day) || '00:00', 'HH:mm');
            this.onTimeChanged(day, from, to);
        }
    }

    getSaveableFieldStateObject(): TimeslotsInterface {
        const { fieldState } = this.props
        return fieldState.$ as TimeslotsInterface;
    }

    getStartTime(day: number) {
        const { fieldState } = this.props;
        if (!fieldState) return '';
        return fieldState.$ && fieldState.$[day] && fieldState.$[day].timeSlots[0] && fieldState.$[day].timeSlots[0].from || '';
    }

    getEndTime(day: number) {
        const { fieldState } = this.props;
        if (!fieldState) return '';

        const sizeOfSlots = fieldState.$ && fieldState.$[day].timeSlots.length;
        return fieldState.$ && fieldState.$[day] && fieldState.$[day].timeSlots[sizeOfSlots - 1] && fieldState.$[day].timeSlots[sizeOfSlots - 1].to || '';
    }

    onTimeChanged(day: number, from: moment.Moment, to: moment.Moment) {
        const { fieldState, timeslotDurationMinutes, timeslotBuffer } = this.props;
        const timeslots = [];
        while (from.isBefore(to)) {

            timeslots.push({
                from: from.format('HH:mm'),
                to: from.add(timeslotDurationMinutes.value, 'minutes').format('HH:mm')
            })

            from.add(timeslotBuffer.value, 'minutes');
        }

        if (timeslots.length === 0) {
            timeslots.push({ from: from.format('HH:mm'), to: from.format('HH:mm'), })
        }

        const fs = this.getSaveableFieldStateObject();
        fs[day].timeSlots = timeslots;
        // console.log(timeslots);
        fieldState.onChange(fs);
    }

    onFromChanged(day: number, value: any) {
        const from = moment(value[0], 'HH:mm');
        const to = moment(this.getEndTime(day) || '00:00', 'HH:mm');
        this.onTimeChanged(day, from, to);
    }

    onToChanged(day: number, value: any) {
        const from = moment(this.getStartTime(day) || '00:00', 'HH:mm');
        const to = moment(value[0], 'HH:mm');
        this.onTimeChanged(day, from, to);
    }

    deleteTimeslot(dayNo: number, index: number) {
        const { fieldState } = this.props;
        const timeSlots = fieldState && fieldState.$ && fieldState.$[dayNo] && fieldState.$[dayNo].timeSlots || [];

        if (timeSlots[index]) {
            const fs = this.getSaveableFieldStateObject();
            const slots = _.clone(fs[dayNo].timeSlots);
            slots.splice(index, 1);

            fs[dayNo].timeSlots = slots;
            fieldState.onChange(fs);
        }
    }


    renderSection(dayStr: string, dayNo: number, hideSeperator: boolean = false) {
        const { fieldState } = this.props;
        const { showTimeslotDetails } = this.state;

        const timeSlots = fieldState && fieldState.$ && fieldState.$[dayNo] && fieldState.$[dayNo].timeSlots || [];

        return (
            <>
                <SectionItem
                    hideSeparator={showTimeslotDetails || hideSeperator}
                    title={dayStr}>

                    <div className='flex'>
                        <div className='DateInput-light w-1/2'>
                            <Flatpickr
                                onChange={(value: any) => this.onFromChanged(dayNo, value)}
                                value={this.getStartTime(dayNo)}
                                options={{ enableTime: true, noCalendar: true, dateFormat: "h:i K" }} />
                        </div>

                        <div className='DateInput-light w-1/2 ml-4'>
                            <Flatpickr
                                onChange={(value: any) => this.onToChanged(dayNo, value)}
                                value={this.getEndTime(dayNo)}
                                options={{ enableTime: true, noCalendar: true, dateFormat: "h:i K" }} />
                        </div>
                    </div>
                </SectionItem>

                {
                    showTimeslotDetails &&
                    <SectionItem
                        hideSeparator={hideSeperator}
                        title=''>
                        <div>
                            {
                                timeSlots.map((slot: any, index: number) => (
                                    <div
                                        key={index}
                                        style={{ marginBottom: '10px', fontSize: '12px' }}>
                                        {moment(slot.from, 'HH:mm').format('h:mm A')} - {moment(slot.to, 'HH:mm').format('h:mm A')} <a style={{ color: '#545454', cursor: 'pointer' }} onClick={() => this.deleteTimeslot(dayNo, index)}>[x]</a>
                                    </div>
                                ))
                            }
                        </div>
                    </SectionItem>
                }

            </>
        )
    }

    render() {
        const { showTimeslotDetails } = this.state;

        return (
            <>
                <Section title='Opening hours (from - to)'>
                    {
                        !showTimeslotDetails &&
                        <div style={{ paddingTop: '6px', textAlign: 'right' }}>
                            <span style={{ marginTop: '30px', textDecoration: 'underline', fontSize: '12px', cursor: 'pointer' }} onClick={() => this.setState({ showTimeslotDetails: true })}>Show timeslot details</span>
                        </div>
                    }

                    {this.renderSection('Monday', 0)}
                    {this.renderSection('Tuesday', 1)}
                    {this.renderSection('Wednesday', 2)}
                    {this.renderSection('Thursday', 3)}
                    {this.renderSection('Friday', 4)}
                    {this.renderSection('Saturday', 5)}
                    {this.renderSection('Sunday', 6, true)}
                </Section>
            </>
        )
    }
}

export default Timeslots;