import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import Input from 'components/Forms/Input';
import Form from 'components/Forms/Form';
import ActionButton from 'components/ActionButton';
import { required, isPositiveInteger } from 'helpers/FormValidation';
import { navigationStore } from 'stores';
import _ from 'lodash';
import { courtStore } from 'stores/CourtStore';
import RadioGroup from 'components/Forms/RadioGroup';
import Timeslots, { TimeslotsInterface } from './Timeslots';
import SelectVenue from 'components/Forms/ActivityForm/SelectVenue';
import StartDateEndDate from './StartDateEndDate';
import SelectActivity from 'components/Forms/ActivityForm/SelectActivity';
import BookingClosed from 'components/BookingClosed';

enum TypeEnum {
    CREATE = 'create',
    EDIT = 'edit',
    COPY = 'copy'
}

@observer class CreateCourtPage extends React.Component<any> {

    form = new FormState({
        id: new FieldState<any>(null),
        name: new FieldState('').validators(required),
        info: new FieldState(null),
        reminderShouldSend: new FieldState(false),
        reminderMinsBefore: new FieldState(null),
        timeslotLimit: new FieldState(1).validators(isPositiveInteger),
        timeslots: new FieldState<TimeslotsInterface>({
            0: { timeSlots: [] },
            1: { timeSlots: [] },
            2: { timeSlots: [] },
            3: { timeSlots: [] },
            4: { timeSlots: [] },
            5: { timeSlots: [] },
            6: { timeSlots: [] }
        }),
        timeslotDuration: new FieldState(20).validators(isPositiveInteger),
        timeslotBuffer: new FieldState(0),
        venueId: new FieldState<null | string>(null),
        bookingAvailableDaysFromStart: new FieldState<null | number>(null),
        bookingStartDate: new FieldState(null),
        bookingEndDate: new FieldState(null),
        sportKey: new FieldState(null)
    })

    getId(): string {
        return this.props.match.params.id;
    }

    getType(): TypeEnum {
        return this.props.match.params.type;
    }

    componentWillUnmount() {
        courtStore.resetStore();
    }

    async componentDidMount() {
        const form = this.form.$;

        const type = this.getType();

        if (type === TypeEnum.EDIT || type === TypeEnum.COPY) {
            await courtStore.getCourt(this.getId());
            const court = _.find(courtStore.courts, { id: this.getId() }) as Parse.Object;

            if (court) {

                if (type !== TypeEnum.COPY) {
                    form.id.reset(court.id);
                    form.name.reset(court.get('name'));
                }

                form.venueId.reset(court.get('venue') ? court.get('venue').id : null);
                form.info.reset(court.get('info'));
                form.reminderShouldSend.reset(court.get('reminderShouldSend'));
                form.reminderMinsBefore.reset(court.get('reminderMinsBefore'));
                form.timeslotLimit.reset(court.get('timeslotLimit'));
                form.timeslots.reset(JSON.parse(court.get('timeslots')));
                form.timeslotDuration.reset(court.get('timeslotDuration'));
                form.bookingAvailableDaysFromStart.reset(court.get('bookingAvailableDaysFromStart'));
                form.bookingStartDate.reset(court.get('bookingStartDate'));
                form.bookingEndDate.reset(court.get('bookingEndDate'));
                form.timeslotBuffer.reset(court.get('timeslotBuffer'));
                form.sportKey.reset(court.get('sportKey'));
            }
        }
    }

    onSubmit = async () => {
        const form = this.form.$;

        const timeSlots = form.timeslots.$;

        for (let dayNo = 0; dayNo < 7; dayNo++) {
            if (timeSlots[dayNo].timeSlots && timeSlots[dayNo].timeSlots.length === 1) {
                var slot = timeSlots[dayNo].timeSlots[0];
                if (slot.from && slot.to && slot.from === slot.to) {
                    timeSlots[dayNo].timeSlots = [];
                }
            }
        }

        let bookingAvailableDaysFromStart = form.bookingAvailableDaysFromStart.$;
        if (bookingAvailableDaysFromStart === 0) {
            bookingAvailableDaysFromStart = null;
        }

        const opts = {
            id: form.id.$,
            name: form.name.$,
            info: form.info.$,
            reminderMinsBefore: form.reminderMinsBefore.$,
            reminderShouldSend: form.reminderShouldSend.$,
            timeslotLimit: form.timeslotLimit.$,
            timeslotDuration: form.timeslotDuration.$,
            timeslots: JSON.stringify(timeSlots),
            bookingAvailableDaysFromStart: bookingAvailableDaysFromStart,
            bookingStartDate: form.bookingStartDate.$,
            bookingEndDate: form.bookingEndDate.$,
            timeslotBuffer: form.timeslotBuffer.$,
            sportKey: form.sportKey.$
        };

        if (this.getType() === TypeEnum.EDIT) {
            await courtStore.updateCourt(opts);
        }
        else {
            const venueId = form.venueId.$;
            if (!venueId) {
                alert('Venue is required');
                return;
            }

            await courtStore.createCourt(venueId!, opts);
        }

        navigationStore.push('/courts');

    }

    duplicate = () => {
        navigationStore.push(`/courts/copy/${this.getId()}`);
        window.location.reload();
    }

    render() {
        const form = this.form.$;

        return (
            <div className='w-full mb-20'>


                <Form onValidSubmit={this.onSubmit} formState={this.form}>
                    <div className='text-right mb-6'>
                        {
                            this.getType() === TypeEnum.EDIT &&
                            <ActionButton width='narrow' text='Duplicate' onClick={this.duplicate} />
                        }

                        <ActionButton
                            className='ml-2'
                            text='Save subvenue'
                            type='submit'
                            width='wide'
                            primary={true} />
                    </div>


                    <>
                        <Section title='Subvenue details'>

                            {
                                this.getType() === TypeEnum.CREATE &&
                                <SectionItem
                                    title='Venue'>
                                    <SelectVenue fieldState={form.venueId} />
                                </SectionItem>
                            }

                            <SectionItem
                                title='Name of subvenue'>
                                <Input fieldState={form.name} id='name' />
                            </SectionItem>

                            <SectionItem
                                title='Activity'>
                                <SelectActivity fieldState={form.sportKey} />
                            </SectionItem>

                            <SectionItem
                                title='Info'
                                hideSeparator={true}>
                                <Input fieldState={form.info} id='info' textArea={true} />
                            </SectionItem>
                        </Section>

                        <Section title='Settings'>
                            <SectionItem
                                hideSeparator={form.reminderShouldSend.value === true}
                                title='Send reminder in app'>
                                <RadioGroup
                                    fieldState={form.reminderShouldSend}
                                    items={[
                                        {
                                            id: 'remind-no',
                                            name: 'No',
                                            value: false
                                        },
                                        {
                                            id: 'remind-yes',
                                            name: 'Yes',
                                            value: true
                                        }
                                    ]} />
                            </SectionItem>
                            {
                                form.reminderShouldSend.value === true &&
                                <SectionItem
                                    title='Remind before booking ends (minutes)'>
                                    <Input
                                        placeholder='minutes'
                                        type='number'
                                        fieldState={form.reminderMinsBefore}
                                    />
                                </SectionItem>
                            }

                            <SectionItem
                                hideSeparator={true}
                                title='Timeslot length (minutes)'>
                                <Input
                                    type='number'
                                    fieldState={form.timeslotDuration} />
                            </SectionItem>

                            <SectionItem
                                hideSeparator={true}
                                title='Buffer between timeslots (minutes)'>
                                <Input
                                    type='number'
                                    fieldState={form.timeslotBuffer} />
                            </SectionItem>

                            <SectionItem
                                title='Max participants per timeslot'>
                                <Input
                                    type='number'
                                    fieldState={form.timeslotLimit} />
                            </SectionItem>

                            <StartDateEndDate
                                startDate={form.bookingStartDate}
                                endDate={form.bookingEndDate} />

                            <SectionItem
                                hideSeparator={true}
                                title='Booking opens in days (optional)'>
                                <Input
                                    type='number'
                                    fieldState={form.bookingAvailableDaysFromStart} />
                            </SectionItem>

                        </Section>

                        <Timeslots
                            fieldState={form.timeslots}
                            timeslotDurationMinutes={form.timeslotDuration}
                            timeslotBuffer={form.timeslotBuffer}
                        />

                        {
                            this.getId() &&
                            <BookingClosed courtId={this.getId()} />
                        }

                    </>
                </Form>
            </div>
        )
    }

}

export default CreateCourtPage;