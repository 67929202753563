import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown, { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';
import { hostStore, authStore } from 'stores';

interface Props {
    fieldState: FieldState<any>,
    className?: string
}

@observer class SelectHost extends React.Component<Props> {

    componentDidMount() {
        hostStore.getHosts(authStore.customer.id);
    }

    componentWillUnmount() {
        hostStore.resetStore();
    }

    mapUsers(users: Array<Parse.Object>) {
        const userList: Array<DropdownOptionInterface> = [];
        if (users) {
            users.forEach(function (element) {
                userList.push({
                    name: element.get('name') || 'No name',
                    value: element.id
                });
            });

        }

        return userList;
    }

    render() {
        const { fieldState, className } = this.props;
        const opts = this.mapUsers(hostStore.hosts);

        return (
            <Dropdown
                placeholderText='Select host'
                className={`Dropdown-light ${className || ''}`}
                fieldState={fieldState}
                options={opts}
            />
        )
    }

}

export default SelectHost;