import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown, { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';
import { venueStore, authStore } from 'stores';
import { Parse } from 'helpers/Parse';

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectVenue extends React.Component<Props> {

    componentDidMount() {
        venueStore.getVenues(authStore.customer.id, true);
    }

    componentWillUnmount() {
        venueStore.resetStore();
    }

    mapVenues(venues: Array<Parse.Object>) {
        const { fieldState } = this.props;
        
        var venuesList: Array<DropdownOptionInterface> = [];

        if (venues) {
            venues.forEach(function(element: Parse.Object) {
                if (element.id === fieldState.value || element.get('active') === true) {
                    venuesList.push({
                        name: element.get('name'),
                        value: element.id
                    });
                }
            });
        }

        return venuesList;
    }

    render() {
        const { fieldState } = this.props;
        const opts = this.mapVenues(venueStore.venues);
    
        return (
            <Dropdown
                placeholderText='Select venue'
                className='Dropdown-light'
                fieldState={fieldState}
                options={opts}
                />
        )
    }

}

export default SelectVenue;