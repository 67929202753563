import * as React from 'react';
import { observer } from 'mobx-react';
import Section from 'components/Forms/Section';
import ActionButton from 'components/ActionButton';
import DateInput from 'components/Forms/DateInput';
import { FormState, FieldState } from 'formstate';
import { bookingClosedStore } from 'stores/BookingClosedStore';

interface Props {
    courtId?: string,
    venueId?: string
}

@observer class BookingClosed extends React.Component<Props> {

    form = new FormState({
        addDate: new FieldState(null)
    })

    async componentDidMount() {
        const { courtId, venueId } = this.props;

        if (courtId) {
            await bookingClosedStore.getClosingDays(undefined, courtId);
        }
        else if (venueId) {
            await bookingClosedStore.getClosingDays(venueId);
        }

    }

    componentWillUnmount() {
        bookingClosedStore.resetStore();
    }

    onAddClosingDay = async () => {
        const { courtId, venueId } = this.props;

        const form = this.form.$;
        const addDate = form.addDate.$;

        if (!addDate) {
            alert('No date selected');
            return;
        }

        if (courtId) {
            await bookingClosedStore.createClosingDay(addDate, undefined, courtId);
        }
        else if (venueId) {
            await bookingClosedStore.createClosingDay(addDate, venueId);
        }

        this.form.reset();
    }

    deleteClosingDay = async (id: string) => {
        await bookingClosedStore.deleteClosingDay(id);
    }

    render() {
        const closingDays = bookingClosedStore.closingDays || [];
        const form = this.form.$;

        return (
            <Section title='Closed dates'>
                <div style={{ padding: '20px 0' }}>
                    {
                        closingDays.map((d: any, index: number) => (
                            <p style={{ padding: '8px 0' }} key={index}>
                                {d.get('date')} <button type='button' style={{ color: '#545454' }} onClick={() => this.deleteClosingDay(d.id)}>[x]</button>
                            </p>
                        ))
                    }

                    <div className='flex'>
                        <div className='w-1/6 mr-2'>
                            <DateInput placeholder='Select date' fieldState={form.addDate} />
                        </div>
                        <ActionButton
                            onClick={() => this.onAddClosingDay()}
                            type='button'
                            text='Add'
                            width='narrow' />
                    </div>
                </div>
            </Section>
        )
    }
}

export default BookingClosed;