import * as React from 'react';
import { observer } from 'mobx-react';
import { bookingStore } from 'stores';
import Table from 'components/Table';
import Moment from 'moment';
import SectionTitle from 'components/SectionTitle';
import Parse from 'parse';
import ActionButton from 'components/ActionButton';

interface Props {
    userId: string
}

@observer class Bookings extends React.Component<Props> {

    componentDidMount() {
        const { userId } = this.props;
        bookingStore.getUserBookings(userId);
        bookingStore.getUserQuarantines(userId);
    }

    componentWillUnmount() {
        bookingStore.resetBookings();
        bookingStore.resetQuarantines();
    }

    render() {
        const bookings = bookingStore.bookings || [];
        const quarantines = bookingStore.quarantines || [];

        const quarantineCols = [
            {
                Header: 'Start',
                id: 'start',
                width: 180,
                accessor: (d: Parse.Object) => {
                    return Moment(d.get('start'))
                        .local()
                        .format('MM-DD-YYYY');
                }
            },
            {
                Header: 'End',
                id: 'end',
                width: 180,
                accessor: (d: Parse.Object) => {
                    return Moment(d.get('end'))
                        .local()
                        .format('MM-DD-YYYY');
                }
            },
            {
                Header: 'Quarantined by',
                id: 'quarantinedBy',
                accessor: (d: Parse.Object) => d.get('quarantinedBy') ? d.get('quarantinedBy').get('name') : ''
            },
            {
                Header: 'Action',
                id: 'action',
                width: 220,
                accessor: (d: Parse.Object) => {
                    const deleteQuarantine = () => {
                        if (!window.confirm('Are you sure you want to delete this quarantine?')) return;

                        bookingStore.deleteQuarantine(d.id);
                    }

                    return (
                        <ActionButton width='narrow' text='Delete' onClick={deleteQuarantine} />
                    )
                }
            }
        ]

        const bookingCols = [
            {
                Header: 'Date',
                id: 'date',
                width: 150,
                accessor: (booking: Parse.Object) => booking.get('date')
            },
            {
                Header: 'Timeslot',
                id: 'timeslot',
                width: 200,
                accessor: (booking: Parse.Object) => `${Moment(booking.get('from'), 'HH:mm').format('h:mm A')} - ${Moment(booking.get('to'), 'HH:mm').format('h:mm A')}`
            },
            {
                Header: 'Court',
                id: 'court',
                accessor: (booking: Parse.Object) => booking.get('court') ? booking.get('court').get('name') : ''
            },
            {
                Header: 'Checked in',
                id: 'checkedIn',
                width: 130,
                accessor: (booking: Parse.Object) => booking.get('checkedIn') ? 'Yes' : 'No'
            },
            {
                Header: 'No show',
                id: 'noShow',
                width: 130,
                accessor: (booking: Parse.Object) => booking.get('noShow') ? 'Yes' : 'No'
            }
        ]

        return (
            <div>
                {
                    quarantines.length > 0 &&
                    <>
                        <SectionTitle title='Quarantine' skipMarginBottom={true} />
                        <div className='mt-4' />
                        <Table
                            columns={quarantineCols}
                            data={quarantines}
                        />
                    </>
                }

                <SectionTitle title='Bookings' skipMarginBottom={true} />
                <div className='mt-4' />
                <Table
                    columns={bookingCols}
                    data={bookings}
                />
            </div>

        )
    }
}

export default Bookings;