import * as React from 'react';
import { observer } from 'mobx-react';
import { Parse } from 'helpers/Parse';
import ActionButton from 'components/ActionButton';

import './style.css';
import { navigationStore } from 'stores';
import { courtStore } from 'stores/CourtStore';

interface Props {
    court: Parse.Object
}

@observer class Court extends React.Component<Props> {

    toggleActive = (e: React.MouseEvent, active: boolean) => {
        e.stopPropagation();
        const { court } = this.props;
        courtStore.toggleActive(court.id, active);
    }

    render() {
        const { court } = this.props;
        const isActive = court.get('active');

        let className = "Court";

        if (!isActive) {
            className += " Court-disabled";
        }

        return (
            <div className={`${className} flex justify-between pl-25px pr-25px pt-25px pb-25px`}>
                <div>
                    <h2 className={`${isActive ? 'text-darkest' : 'text-inactive'} font-medium text-15px`}>
                        {court.get('venue').get('name')}: {court.get('name')}
                    </h2>
                </div>
                <div className='text-right'>
                    {
                        !isActive &&
                        <ActionButton width='narrow' style={{background: '#ff6e6e'}} text='Not active' onClick={(e: any) => this.toggleActive(e, true)} />
                    }

                    {
                        isActive &&
                        <ActionButton width='narrow' primary={true} text='Active' onClick={(e: any) => this.toggleActive(e, false)} />
                    }

                    {
                        <ActionButton className='ml-2' width='narrow' text='Edit' onClick={(e: any) => navigationStore.push(`/courts/edit/${court.id}`)} />
                    }

                </div>
            </div>
        )
    }

}

export default Court;