import * as React from 'react';
import { observer } from 'mobx-react';
import BackButton from 'components/BackButton';
import ActionButton from 'components/ActionButton';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import { FormState, FieldState } from 'formstate';
// import Form from 'components/Forms/Form';
import Input from 'components/Forms/Input';
import Editor from 'components/Forms/Editor';
import { WaiverStatusEnum, waiverStore, navigationStore } from 'stores';
import SelectWaiverStatus from './SelectWaiverStatus';
import DateInput from 'components/Forms/DateInput';
import Moment from 'moment';

@observer class CreateWaiverPage extends React.Component<any> {

    form = new FormState({
        title: new FieldState(''),
        content: new FieldState(''),
        status: new FieldState(WaiverStatusEnum.DRAFT),
        publishedDate: new FieldState(Moment().toISOString())
    })

    onCreateWaiver = async () => {
        const form = this.form.$;
        const title = form.title.$;
        const content = form.content.$;

        if (!title || !content) {
            alert('Title as well as content must be filled out.')
            return;
        }

        if (form.status.$ === WaiverStatusEnum.ACTIVE && !form.publishedDate.$) {
            alert('If waiver should be active, you must set a publish date');
            return;
        }

        const groupId = this.props.match.params.groupId;
        
        await waiverStore.createWaiver(groupId, title, content, form.status.value, form.publishedDate.value);
        navigationStore.goBack();
    }

    render() {
        const form = this.form.$;

        return (
            <div className='w-full'>
                <div className='text-right mb-8'>
                    <div className='flex'>
                        <BackButton />

                        <div className="text-right w-full">
                            <ActionButton primary={true} width='wide' text='Save waiver' onClick={this.onCreateWaiver} />
                        </div>
                    </div>
                </div>

                <Section>
                    <SectionItem title='Title'>
                        <Input fieldState={form.title} />
                    </SectionItem>
                    <SectionItem title='Status'>
                        <SelectWaiverStatus fieldState={form.status} />
                    </SectionItem>
                    {
                        form.status.$ === WaiverStatusEnum.ACTIVE &&
                        <SectionItem title='Active from'>
                            <DateInput fieldState={form.publishedDate} />
                        </SectionItem>
                    }
                    <SectionItem title='Content' hideSeparator={true}>
                        <Editor fieldState={form.content} />
                    </SectionItem>
                </Section>
            </div>
        )
    }

}

export default CreateWaiverPage;