export const activities = {
    "ATSportTennis": "Tennis",
    "ATSportSoccer": "Soccer",
    "ATSportFitness": "Fitness",
    "ATSportBasketball": "Basketball",
    "ATSportCycling": "Cycling",
    "ATSportSquash": "Squash",
    "ATSportRacquetball": "Racquetball",
    "ATSportFootball": "Football",
    "ATSportYoga": "Yoga",
    "ATSportBeachVolley": "Beach Volleyball",
    "ATSportVolleyball": "Volleyball",
    "ATSportMountainBiking": "Mountain Biking",
    "ATSportTriathlon": "Triathlon",
    "ATSportSoftball": "Softball",
    "ATSportRugby": "Rugby",
    "ATSportUltimateFrisbee": "Ultimate Frisbee",
    "ATSportMartialArt": "Martial Arts",
    "ATSportSkateboarding": "Skateboarding",
    "ATSportSkiing": "Skiing",
    "ATSportGolf": "Golf",
    "ATSportRunning": "Running",
    "ATSportLacrosse": "Lacrosse",
    "ATSportSurfing": "Surfing",
    "ATSportBowling": "Bowling",
    "ATSportBaseball": "Baseball",
    "ATSportBadminton": "Badminton",
    "ATSportBoxing": "Boxing",
    "ATSportClimbing": "Climbing",
    "ATSportCricket": "Cricket",
    "ATSportDance": "Dance",
    "ATSportDodgeball": "Dodgeball",
    "ATSportFlagFootball": "Flag football",
    "ATSportHandball": "Handball",
    "ATSportHiking": "Hiking",
    "ATSportHockey": "Field Hockey",
    "ATSportIceHockey": "Ice hockey",
    "ATSportKayaking": "Kayaking",
    "ATSportKickball": "Kickball",
    "ATSportStandUpPaddle": "Stand Up Paddle",
    "ATSportSwimming": "Swimming",
    "ATSportTableTennis": "TableTennis",
    "ATSportWalking": "Walking",
    "ATSportTrailrun": "Trailrun",
    "ATSportArchery": "Archery",
    "ATSportQuidditch": "Quidditch",
    "ATSportEvent": "College Event",
    "ATSportESport": "ESport",
    "ATSportCheerleading": "Cheerleading",
    "ATSportFencing": "Fencing",
    "ATSportWorkout": "Workout",
    "ATSportOpenRec": "Open Rec",
    "ATSportWellness": "Wellness"
}

export const skillLevels = {
    100: "Beginner",
    200: "Intermediate",
    300: "Advanced",
    400: "Excellent"
}

export const modes = {
    100: "Fun",
    200: "Exercise",
    300: "Competitive"
}

export const participantStatus = {
    ATPlayerStatusHosting: "Hosting",
    ATPlayerStatusDeclined: "Declined",
    ATPlayerStatusInvited: "Invited",
    ATPlayerStatusAccepted: "Accepted",
    ATPlayerStatusWaitlist: "Waitlist"
}

export function readableActivity(key: string) {
    return activities[key];
}