import * as React from 'react';
import { observer } from 'mobx-react';

import './style.css';

interface Props {
    text: string,
    count: number,
    countClass?: string,
    onClick?: Function
}

@observer class StatItem extends React.Component<Props> {

    onClick = () => {
        const { onClick } = this.props;

        if (onClick) {
            onClick();
        }
    }

    render() {
        const { text, count, countClass, onClick } = this.props;

        return (
            <div onClick={this.onClick} className={`StatItem bg-white text-center rounded mb-2 ${onClick ? 'cursor-pointer' : ''}`}>
                <h2 className={`pt-6 font-medium ${countClass}`}>
                    {count ? count.toLocaleString('en-US') : 0}
                </h2>
                <p className='py-6'>
                    {text}
                </p>
            </div>
        )
    }

}

export default StatItem;