import * as React from 'react';
import { observer } from 'mobx-react';
import Table from 'components/Table';
import { groupMemberStore, authStore, GroupMemberStatusEnum } from 'stores';
import AvatarCell from 'components/Table/AvatarCell';
import { CellInfo } from 'react-table';
import ActionsCell from './ActionsCell';
import TablePlaceholder from 'components/TablePlaceholder';

import NoPendingImg from './NoPending.svg';

@observer class PendingMembersPage extends React.Component {

    componentDidMount() {
        groupMemberStore.resetMembers();

        const customerId = authStore.customer.id;
        groupMemberStore.getMembers(customerId, true, { memberStatus: [GroupMemberStatusEnum.PENDING] });
    }

    render() {
        const data = groupMemberStore.members || [];
        const pendingMembers = data.filter((member) => member.get('memberStatus') === GroupMemberStatusEnum.PENDING)

        if (pendingMembers.length === 0) {
            return <TablePlaceholder
                imageSrc={NoPendingImg}
                title='No pending requests'
                description='As users sign up to ATLETO by using their school account, they will appear here to be approved or declined.' />
        }

        const columns = [
            {
                Header: 'Name',
                id: 'name',
                Cell: (cellInfo: CellInfo) => {
                    const row = cellInfo.original as Parse.Object;
                    const user = row.get('user');
                    const name = user.get('name') || 'No name';
                    const avatar = user.get('avatar') ? user.get('avatar')._url : null;

                    return <AvatarCell
                        imageUrl={avatar}
                        title={name}
                    />
                }
            },
            {
                Header: 'Email',
                id: 'email',
                accessor: (d: any) => d.get('user').get('username')
            },
            {
                Header: 'Group',
                id: 'groups',
                accessor: (d: any) => d.get('group').get('name')
            },
            {
                Header: 'Actions',
                id: 'actions',
                width: 270,
                Cell: (cellInfo: CellInfo) => {
                    const row = cellInfo.original as Parse.Object;
                    return <ActionsCell groupMemberId={row.id} />
                }
            }
        ]

        return (
            <div className='w-full'>
                <div className='mt-5'>
                    <Table
                        condensed={true}
                        data={pendingMembers}
                        columns={columns} />
                </div>
            </div>
        )
    }

}

export default PendingMembersPage;