import * as React from 'react';
import { observer } from 'mobx-react';
import { Parse } from 'helpers/Parse';

import './style.css';

import ExpandArrow from './expand-arrow.svg';
import { authStore, customerRoleStore, navigationStore } from 'stores';

interface State {
    showMenu: boolean
}

@observer class TopProfile extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            showMenu: false
        }
    }

    showMenu(shouldDisplay: boolean) {
        this.setState({
            showMenu: shouldDisplay
        })
    }

    logOut = () => {
        authStore.logout();
    }

    settings = () => {
        navigationStore.push('/settings');
    }

    canAccessSettings = (userRole: Parse.Object) => {
        if (!userRole) return true;
        return !userRole.get('limitedAccess');
    }

    render() {
        const { showMenu } = this.state;
        const user = Parse.User.current();
        if (!user) return null;
        const userRole = customerRoleStore.loggedInUserRole;

        const avatar = user.get('avatar') ? user.get('avatar')._url : null;

        return (
            <div
                onMouseEnter={() => this.showMenu(true)}
                onMouseLeave={() => this.showMenu(false)}
                className='TopProfile flex items-center cursor-pointer'>

                <p className='text-inactive inline mr-2'>
                    {user.get('name')}
                </p>

                {
                    avatar &&
                    <img src={avatar} alt='Profile' className='Header-avatar' />
                }

                <img src={ExpandArrow} alt="Expand" className='ml-2' />

                {
                    showMenu &&
                    <ul className="TopProfile-menu">
                        {
                            this.canAccessSettings(userRole) &&
                            <li className='cursor-pointer' onClick={this.settings}>
                                Settings
                            </li>
                        }

                        <li className='cursor-pointer' onClick={this.logOut}>
                            Log out
                        </li>
                    </ul>
                }
            </div>
        )
    }

}

export default TopProfile;