import * as React from 'react';
import { observer } from 'mobx-react';
import { waiverAgreementStore, navigationStore } from 'stores';
import Table from 'components/Table';
import SectionTitle from 'components/SectionTitle';
import Moment from 'moment';

interface Props {
    waiverId: string
}

@observer class WaiverAgreementTable extends React.Component<Props> {

    componentDidMount() {
        const { waiverId } = this.props;
        waiverAgreementStore.getAgreementsByWaiver(waiverId);
    }

    componentWillUnmount() {
        waiverAgreementStore.resetStore();
    }

    gotoMemberDetails = (row: Parse.Object) => {
        console.log(row)
        navigationStore.push(`/memberdetails/${row.get('user').id}`);
    }

    render() {
        const data = waiverAgreementStore.agreements || [];
        if (data.length === 0) return null;

        const columns = [
            {
                Header: 'Name',
                id: 'name',
                accessor: (d: Parse.Object) => d.get('firstname') + ' ' + d.get('lastname')
            },
            {
                Header: 'Student ID',
                id: 'studentId',
                accessor: (d: Parse.Object) => d.get('studentID')
            },
            {
                Header: 'Signed Date',
                id: 'signedDate',
                accessor: (d: Parse.Object) => d.get('signedDate') ? Moment(d.get('signedDate')).local().format('MM-DD-YYYY hh:mm') : null
            },
        ]

        return (
            <div>
                <SectionTitle title='Signed by' skipMarginBottom={true} />
                <div className='mt-4' />
                <Table
                    onRowClicked={this.gotoMemberDetails}
                    data={data}
                    columns={columns}
                />
            </div>

        )
    }

}

export default WaiverAgreementTable;