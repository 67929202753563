import { FieldState, FormState } from 'formstate';
import { observer } from 'mobx-react';
import * as React from 'react';
import DateInput from '../DateInput';
import Form from '../Form';
import Input from '../Input';
import Section from '../Section';
import SectionItem from '../SectionItem';
import Switch from '../Switch';
import SelectActivity from './SelectActivity';
import SelectAthleteVisibility from './SelectAthleteVisibility';
import SelectDayOfWeek from './SelectDayOfWeek';
import SelectDeadline, { ActivityDeadlineEnum } from './SelectDeadline';
import SelectHost from './SelectHost';
// import SelectMode from './SelectMode';
import SelectNumberOfAthletes from './SelectNumberOfAthletes';
import SelectSkillLevel from './SelectSkillLevel';
import SelectVenue from './SelectVenue';
import _ from 'lodash';
import { venueStore, authStore } from 'stores';
import { Parse } from 'helpers/Parse';
import Moment from 'moment';
import BackButton from 'components/BackButton';
import ActionButton from 'components/ActionButton';
import Separator from 'components/Separator';
import { required, isZeroOrAbove } from 'helpers/FormValidation';
import SelectRecurringDeadline from './SelectRecurringDeadline';
import UploadFile from '../UploadFile';
import SelectRecurringVisibility from './SelectRecurringVisibility';
const GoogleTimezoneApi = require('google-timezone-api');

export interface EditActivityDataInterface {
    customTitle?: any
    groupId?: any
    hostId?: any
    sportKey?: any
    minSkillLevel?: any
    maxSkillLevel?: any
    gameMode?: any
    venueId?: any
    note?: any
    athletesHidden?: any
    minPlayers?: any
    maxPlayers?: any
    gameStartTimeOfDay?: any
    gameDeadlineOption?: any
    intervalOption?: any
    firstDate?: any
    lastDate?: any
    gameDayOfWeek?: any,
    customImageUrl?: any,
    gameStart?: any,
    deadline?: any,
    gameDurationMinutes?: any,
    hostHoursBeforeGameStart?: any

    isRecurring: boolean
}

interface Props {
    onSubmit: Function,
    groupId?: string,
    editData?: EditActivityDataInterface,
    submitButtonText?: string,
    additionalButtons?: React.ReactNode
}

interface State {
    isEditMode: boolean,
    activityImage: string | null
}

@observer class ActivityForm extends React.Component<Props, State> {

    form = new FormState({
        sportKey: new FieldState(null).validators(required),
        minSkillLevel: new FieldState(0),
        maxSkillLevel: new FieldState(0),
        customTitle: new FieldState('').validators(required),
        note: new FieldState(''),
        host: new FieldState(Parse.User.current()!.id).validators(required),
        gameMode: new FieldState(null),
        venueId: new FieldState(null).validators(required),
        gameStart: new FieldState(null),
        gameDurationMinutes: new FieldState(null),
        gameStartTimeOfDay: new FieldState<Date | null>(Moment().startOf('hour').toDate()), // Recurring
        deadline: new FieldState(null),
        gameDeadlineOption: new FieldState(null), // Recurring
        athletesHidden: new FieldState(true),
        minPlayers: new FieldState(0).validators(isZeroOrAbove),
        maxPlayers: new FieldState(0).validators(isZeroOrAbove),
        gameDayOfWeek: new FieldState(null),
        firstDate: new FieldState(null),
        lastDate: new FieldState(null),
        intervalOption: new FieldState('ATIntervalOptionWeekly'),
        customImageUrl: new FieldState(null),
        hostHoursBeforeGameStart: new FieldState(null),

        isRecurring: new FieldState(false),
        emailNotification: new FieldState(false)
    })

    constructor(props: Props) {
        super(props);

        let isEditMode = false;

        if (props.editData) {
            const form = this.form.$;
            const data = props.editData;
            form.sportKey.reset(data.sportKey);
            form.minSkillLevel.reset(data.minSkillLevel);
            form.maxSkillLevel.reset(data.maxSkillLevel);
            form.customTitle.reset(data.customTitle);
            form.note.reset(data.note);
            form.host.reset(data.hostId);
            form.gameMode.reset(data.gameMode);
            form.venueId.reset(data.venueId);
            form.gameStart.reset(data.gameStart);
            form.deadline.reset(data.deadline);
            form.athletesHidden.reset(data.athletesHidden);
            form.minPlayers.reset(data.minPlayers);
            form.maxPlayers.reset(data.maxPlayers);
            form.isRecurring.reset(data.isRecurring);
            form.customImageUrl.reset(data.customImageUrl);
            form.gameDurationMinutes.reset(data.gameDurationMinutes);
            form.hostHoursBeforeGameStart.reset(data.hostHoursBeforeGameStart);

            if (data.isRecurring) {
                form.gameStartTimeOfDay.reset(data.gameStartTimeOfDay);
                form.gameDeadlineOption.reset(data.gameDeadlineOption);
                form.intervalOption.reset(data.intervalOption);
                form.firstDate.reset(data.firstDate);
                form.lastDate.reset(data.lastDate);
                form.gameDayOfWeek.reset(data.gameDayOfWeek);
            }

            isEditMode = true;
        }

        this.state = {
            isEditMode: isEditMode,
            activityImage: null
        }
    }

    deadlineToDate(deadlineVal: any, gameStart: any, gameDurationMinutes: any = 180) {
        if (!deadlineVal) return undefined;

        switch (deadlineVal) {
            case ActivityDeadlineEnum.TIME_OF_EVENT:
                return gameStart;
            case ActivityDeadlineEnum.ONE_HOUR_BEFORE:
                return Moment(gameStart).subtract(1, 'hours').toDate();
            case ActivityDeadlineEnum.ONE_DAY_BEFORE:
                return Moment(gameStart).subtract(1, 'days').toDate();
            case ActivityDeadlineEnum.END_TIME:
                return Moment(gameStart).add(!Number(gameDurationMinutes) ? 180 : gameDurationMinutes, 'minutes').toDate();

            default:
                return gameStart;
        }
    }

    getTimezoneByVenueId(venueId: string) {
        const venues = venueStore.venues;
        const venue = _.find(venues, { id: venueId }) as Parse.Object;
        const lat = venue.get('geoLocation')._latitude;
        const lng = venue.get('geoLocation')._longitude;

        return GoogleTimezoneApi({
            key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
            location: `${lat},${lng}`
        });
    }

    onSubmit = async () => {
        const { onSubmit, groupId, editData } = this.props;
        const { activityImage } = this.state;

        const form = this.form.$;
        const isRecurring = form.isRecurring.value;

        let data = {} as any;

        if (groupId) {
            data.group = groupId;
        }
        else if (editData) {
            data.group = editData.groupId;
        }

        data.sportKey = form.sportKey.$;

        const minSkillLevel = Number(form.minSkillLevel.$);
        const maxSkillLevel = Number(form.maxSkillLevel.$);

        if ((minSkillLevel === 0 && maxSkillLevel !== 0) || (maxSkillLevel === 0 && minSkillLevel !== 0)) {
            alert('Both minimum and maximum skill level must be set');
            return;
        }

        if (Number(maxSkillLevel) < Number(minSkillLevel)) {
            alert('Maximum skill level must be greater than minimum skill level');
            return;
        }

        data.minSkillLevel = minSkillLevel;
        data.maxSkillLevel = maxSkillLevel;
        data.customTitle = form.customTitle.$;
        data.note = form.note.$;
        data.host = form.host.$;
        // data.gameMode = form.gameMode.$;
        data.gameStart = form.gameStart.$;
        data.athletesHidden = form.athletesHidden.$;

        if (activityImage) {
            data.customImageBase64 = activityImage;
        }

        const minPlayers = Number(form.minPlayers.$);
        const maxPlayers = Number(form.maxPlayers.$);
        if (minPlayers >= 1 && maxPlayers < minPlayers) {
            alert('Maximum players must be greater than or equal to minimum players');
            return;
        }

        if (minPlayers < 1 && maxPlayers < 1) {
            data.minPlayers = null;
            data.maxPlayers = null;
            data.unlimitedPlayers = true;
        }
        else {
            data.minPlayers = minPlayers;
            data.maxPlayers = maxPlayers;
            data.unlimitedPlayers = false;
        }

        data.gameDayOfWeek = form.gameDayOfWeek.$;
        data.firstDate = form.firstDate.$;
        data.lastDate = form.lastDate.$;
        data.autoAccept = true;
        data.privateGame = true;
        data.gameStatus = "ATGameStatusOpen";
        data.venue = form.venueId.$;
        data.customerAffiliation = authStore.customer.id;
        data.gameDurationMinutes = form.gameDurationMinutes.$ || undefined;

        const venue = _.find(venueStore.venues, { id: form.venueId.$! });

        if (venue) {
            data.geoLocation = new Parse.GeoPoint({ latitude: venue.get('geoLocation')._latitude, longitude: venue.get('geoLocation')._longitude });
            data.gameArea = venue.get('name');
        }

        if (isRecurring) {
            data.concurrentActiveGames = 2;
            data.gameStartTimeOfDay = Moment(form.gameStartTimeOfDay.$!).format('HH:mm');
            data.gameDayOfWeek = Number(form.gameDayOfWeek.$);
            data.intervalOption = form.intervalOption.$;
            data.gameDeadlineOption = form.gameDeadlineOption.$;
            data.hostHoursBeforeGameStart = form.hostHoursBeforeGameStart.$;

            if (!data.lastDate) {
                alert('Last date must be set');
                return;
            }
        }
        else {
            if (!data.gameStart) {
                alert('No date set for game start');
                return;
            }

            data.deadline = this.deadlineToDate(form.deadline.$, form.gameStart.$, form.gameDurationMinutes.$);
        }

        data.gameTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; // Just in case we need a fallback
        const res = await this.getTimezoneByVenueId(form.venueId.$!);

        if (res && res.timeZoneId) {
            data.gameTimezone = res.timeZoneId;
        }
        data.emailNotification = form.emailNotification.$;
        return onSubmit(data, isRecurring);

    }

    getBase64(file: any) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as any);
            reader.onerror = error => reject(error);
        });
    }

    submitLogo = (image: File) => {
        this.getBase64(image).then((base64image: any) => {
            this.setState({
                activityImage: base64image
            });
        });
    }

    render() {
        const { submitButtonText, additionalButtons } = this.props;
        const { isEditMode, activityImage } = this.state;
        const form = this.form.$;
        const isRecurring = form.isRecurring.value;
        const submitButtonValue = submitButtonText || (isEditMode ? 'Save changes' : 'Save activity');

        const imageSrc = activityImage || form.customImageUrl.$ || null;

        return (
            <Form onValidSubmit={this.onSubmit} formState={this.form}>
                <div className='w-full'>
                    <div className='flex'>
                        <BackButton />
                        <div className='text-right w-full'>
                            {additionalButtons}
                        </div>
                    </div>

                    <Separator />

                    <div className='w-full'>
                        <Section title='Create activity'>
                            {/* <SectionItem title='Group'>
                                <Input readOnlyValue='This is the groupname' />
                            </SectionItem> */}
                            <SectionItem title='Host'>
                                <SelectHost fieldState={form.host} />
                            </SectionItem>
                            <SectionItem title='Activity' hideSeparator={true}>
                                <SelectActivity fieldState={form.sportKey} />
                            </SectionItem>

                        </Section>

                        <Section title='Information'>
                            <SectionItem title='Title'>
                                <Input fieldState={form.customTitle} />
                            </SectionItem>
                            <SectionItem title='Skill Level Range'>
                                <div className='flex'>
                                    <SelectSkillLevel fieldState={form.minSkillLevel} className='mr-2' />
                                    <SelectSkillLevel fieldState={form.maxSkillLevel} />
                                </div>
                            </SectionItem>
                            {/* <SectionItem title='Mode'>
                                <SelectMode fieldState={form.gameMode} />
                            </SectionItem> */}
                            <SectionItem title='Notes' hideSeparator={true}>
                                <Input textArea={true} fieldState={form.note} />
                            </SectionItem>
                        </Section>

                        <Section title='Location and period'>
                            <SectionItem title='Venue'>
                                <SelectVenue fieldState={form.venueId} />
                            </SectionItem>
                            {
                                !isEditMode &&
                                <SectionItem title='Recurring activity'>
                                    <Switch fieldState={form.isRecurring} className='justify-end' />
                                </SectionItem>
                            }

                            <SectionItem title='Start'>
                                {
                                    !isRecurring &&
                                    <DateInput
                                        fieldState={form.gameStart}
                                        options={{ enableTime: true, dateFormat: 'm-d-Y h:i K' }}
                                    />
                                }

                                {
                                    isRecurring &&
                                    <DateInput
                                        fieldState={form.gameStartTimeOfDay}
                                        options={{
                                            enableTime: true,
                                            noCalendar: true,
                                            dateFormat: 'h:i K'
                                        }}
                                    />
                                }

                            </SectionItem>

                            <SectionItem title='Duration (minutes)'>
                                <Input type='number' fieldState={form.gameDurationMinutes} />
                            </SectionItem>

                            {
                                isRecurring &&
                                <>
                                    <SectionItem title='Day of week'>
                                        <SelectDayOfWeek fieldState={form.gameDayOfWeek} />
                                    </SectionItem>
                                    <SectionItem title='First date'>
                                        <DateInput fieldState={form.firstDate} />
                                    </SectionItem>
                                    <SectionItem title='Last date' hideSeparator={true}>
                                        <DateInput fieldState={form.lastDate} />
                                    </SectionItem>
                                </>
                            }

                            <SectionItem title='Deadline for sign up' hideSeparator={!isRecurring}>
                                {
                                    !isRecurring &&
                                    <SelectDeadline fieldState={form.deadline} />
                                }

                                {
                                    isRecurring &&
                                    <SelectRecurringDeadline fieldState={form.gameDeadlineOption} />
                                }

                            </SectionItem>

                            {
                                isRecurring &&
                                <SectionItem title='When should activity be visible?' hideSeparator={true}>
                                    <SelectRecurringVisibility fieldState={form.hostHoursBeforeGameStart} />
                                </SectionItem>
                            }
                        </Section>

                        <Section title='Miscellaneous'>
                            <SectionItem title='Number of participants' hideSeparator={isRecurring}>
                                <SelectNumberOfAthletes minPlayersFieldState={form.minPlayers} maxPlayersFieldState={form.maxPlayers} />
                            </SectionItem>
                            <SectionItem title='Participants visible to'>
                                <SelectAthleteVisibility fieldState={form.athletesHidden} />
                            </SectionItem>
                            {
                                !isEditMode &&
                                <SectionItem title='Email Notification'>
                                <Switch fieldState={form.emailNotification} className='justify-end' />
                                </SectionItem>
                            }
                            
                            {
                                !isRecurring &&
                                <SectionItem title='Cover image' hideSeparator={true}>
                                    {
                                        imageSrc &&
                                        <img src={imageSrc} alt="activity" style={{ width: '100%', border: '1px solid #CCCCCC' }} />
                                    }

                                    {
                                        (!isRecurring && !activityImage) &&
                                        <UploadFile allowedFileTypes={['png', 'jpg', 'jpeg']} onSubmit={this.submitLogo}>
                                            <button type='button' style={{ textDecoration: 'underline', marginTop: '10px' }}>Upload image</button>
                                        </UploadFile>
                                    }
                                </SectionItem>
                            }
                        </Section>

                    </div>
                </div>

                <div className="text-center w-full mt-10">
                    <ActionButton
                        primary={true}
                        width='wide'
                        type='submit'
                        text={submitButtonValue} />
                </div>

            </Form>


        )
    }

}

export default ActivityForm;