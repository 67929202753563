import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import Dropdown from 'components/Forms/Dropdown';
import { WaiverStatusEnum } from 'stores';

interface Props {
    fieldState: FieldState<any>,
    className?: string
}

@observer class SelectWaiverStatus extends React.Component<Props> {

    componentDidMount() {

    }

    render() {
        const { fieldState, className } = this.props;

        const opts = [
            {
                value: WaiverStatusEnum.ACTIVE,
                name: "Active"
            },
            {
                value: WaiverStatusEnum.ARCHIVED,
                name: "Archived"
            }
        ]

        if (fieldState.$ && fieldState.$ === WaiverStatusEnum.DRAFT) {
            opts.unshift({
                value: WaiverStatusEnum.DRAFT,
                name: "Draft"
            })
        }

        return (
            <Dropdown
                className={`Dropdown-light ${className || ''}`}
                fieldState={fieldState}
                skipSort={true}
                options={opts}
            />
        )
    }

}

export default SelectWaiverStatus;