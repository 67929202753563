import { autorun } from 'mobx';

abstract class SaveableStore<T> {

    protected abstract setSaveableData(data: T): void;
    protected abstract getSaveableData(): T;

    private saveableName: string;

    constructor(saveableName: string) {
        this.saveableName = saveableName;
        
        // See if we are already logged in, by checking localstorage
        if (localStorage.getItem(this.saveableName)) {
            let storeData = localStorage.getItem(this.saveableName) as string;
            let data: T = JSON.parse(storeData);
            this.setSaveableData(data);
        }

        // Monitor changes and save to localstorage
        autorun((v) => {
            let data = this.getSaveableData();
            localStorage.setItem(this.saveableName, JSON.stringify(data));
        })
    }

}

export default SaveableStore;