import * as React from 'react';
import { observer } from 'mobx-react';
import { Parse } from 'helpers/Parse';
import Popup from 'reactjs-popup';
import Dropdown, { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { fitnessStore, authStore, memberDetailsStore, CustomerGroupTypeEnum } from 'stores';
import _ from 'lodash';
import { FormState, FieldState } from 'formstate';
import Form from 'components/Forms/Form';
import ActionButton from 'components/ActionButton';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import { required } from 'helpers/FormValidation';

interface Props {
    existingGroups: Array<Parse.Object>,
    userId: string,
    open?: boolean,
    onClose?: () => any
}

@observer class AddToGroup extends React.Component<Props> {

    form = new FormState({
        selectedGroup: new FieldState(null).validators(required)
    })

    componentDidMount() {
        fitnessStore.getGroups(authStore.customer.id);
    }

    mapGroups(groups: Array<Parse.Object>) {
        const groupsList: Array<DropdownOptionInterface> = [];
        if (!groups) return groupsList;

        groups.forEach((group) => {
            if (group.get('customerGroupType') === CustomerGroupTypeEnum.MAIN) {
                return;
            }

            groupsList.push({
                name: group.get('name'),
                value: group.id
            })
        });

        return groupsList;
    }

    onSubmit = () => {
        const { userId, onClose } = this.props;
        const groupId = this.form.$.selectedGroup.$;

        memberDetailsStore.addMemberToGroup(userId, groupId!);

        if (onClose) {
            onClose();
        }

    }

    render() {
        const { existingGroups, open, onClose } = this.props;
        const groups = fitnessStore.groups;

        const availableGroups = _.filter(groups, (group) => {
            // Verify that existingGroup is not in groups
            return !!!_.find(existingGroups, { id: group.id });
        });

        const groupList = this.mapGroups(availableGroups);

        return (
            <Popup
                modal
                open={open}
                onClose={onClose}
                closeOnDocumentClick>

                {
                    close => (
                        <Form onValidSubmit={this.onSubmit} formState={this.form}>
                            <Section className='mt-4 mb-4'>
                                <SectionItem titleWidth='140px' title='Group' hideSeparator={true}>
                                    <Dropdown
                                        placeholderText='Select group'
                                        className='Dropdown-light'
                                        fieldState={this.form.$.selectedGroup}
                                        options={groupList} />

                                </SectionItem>

                                <div className='text-right mt-4'>
                                    <ActionButton onClick={close} text='Cancel' width='wrap' className='mr-2' />
                                    <ActionButton type='submit' text='Add to group' width='narrow' primary={true} />
                                </div>
                            </Section>

                        </Form>
                    )
                }

            </Popup>
        )
    }

}

export default AddToGroup;