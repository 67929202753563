import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import RadioGroup from 'components/Forms/RadioGroup';

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectAthleteVisibility extends React.Component<Props> {

    render() {
        const { fieldState } = this.props;

        return (
            <RadioGroup
                fieldState={fieldState}
                items={[
                    {
                        name: 'Host only',
                        value: true,
                        id: 'athletes-visible-all-false'
                    },
                    {
                        name: 'Everyone',
                        value: false,
                        id: 'athletes-visible-all-true'
                    }
                ]}
            />
        )
    }

}

export default SelectAthleteVisibility;