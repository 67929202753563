import * as React from 'react';
import { observer } from 'mobx-react';
import GroupForm, { EditGroupDataInterface } from 'components/Forms/GroupForm';
import { navigationStore, CreateGroupInterface, fitnessStore, groupStore, ActivePrimaryMenuEnum } from 'stores';

@observer class EditGroupPage extends React.Component<any> {

    componentDidMount() {
        navigationStore.setShowSecondaryMenu(false);

        const groupId = this.getGroupId();
        groupStore.getGroup(groupId);
    }

    getGroupId() {
        return this.props.match.params.groupId;
    }

    componentWillUnmount() {
        navigationStore.setShowSecondaryMenu(true);
        groupStore.resetStore();
    }

    onSubmit = async (opts: CreateGroupInterface) => {
        await fitnessStore.editGroup(this.getGroupId(), opts);
        navigationStore.goBack();
    }

    archiveGroup = async () => {
        if (window.confirm('Are you sure you want to archive this group?')) {
            await fitnessStore.archiveGroup(this.getGroupId());
            navigationStore.push('/', () => {
                fitnessStore.resetStore();
                navigationStore.setActivePrimaryMenu(ActivePrimaryMenuEnum.HOME);
            });
        }
    }

    render() {
        const group = groupStore.group;
        if (!group) return null;

        const editData: EditGroupDataInterface = {
            name: group.get('name'),
            customerGroupType: group.get('customerGroupType'),
            type: group.get('type'),
            coverImageUrl: group.get('coverImage') ? group.get('coverImage')._url : null,
            groupImageUrl: group.get('image') ? group.get('image')._url : null
        }

        return (
            <div className='w-full'>
                <GroupForm
                    onSubmit={this.onSubmit}
                    editData={editData}
                />
                <div className='text-center mt-16'>
                    <button className=' mt-4 text-white px-6 py-2 border rounded' onClick={this.archiveGroup}>Archive group</button>
                </div>

            </div>

        )
    }

}

export default EditGroupPage;