import * as React from 'react';
import { observer } from 'mobx-react';
import ActivityForm, { EditActivityDataInterface } from 'components/Forms/ActivityForm';
import { activityDetailsStore, navigationStore, activityStore } from 'stores';
import moment from 'moment';
import { ActivityDeadlineEnum } from 'components/Forms/ActivityForm/SelectDeadline';

@observer class EditActivityPage extends React.Component<any> {

    componentDidMount() {
        const activityId = this.getActivityId();

        activityDetailsStore.getActivity(activityId);
        navigationStore.setShowSecondaryMenu(false);
    }

    shouldDuplicate() {
        return !!this.props.match.params.duplicate;
    }

    componentWillUnmount() {
        navigationStore.setShowSecondaryMenu(true);
    }

    onSubmit = async (data: any) => {

        if (this.shouldDuplicate()) {
            const activity = await activityStore.hostActivity(data) as Parse.Object;
            const group = activity.get('group');
            let endpointGroupType = 'main';

            if (group.get('customerGroupType') === 'ATCustomerGroupTypeClubSport') {
                endpointGroupType = 'clubsport'
            }
            else if (group.get('customerGroupType') === 'ATCustomerGroupTypeFitness') {
                endpointGroupType = 'fitness';
            }

            navigationStore.push(`/${endpointGroupType}/${group.id}/activities`);
        }
        else {
            data.gameId = this.getActivityId();
            await activityDetailsStore.editActivity(data);
            navigationStore.goBack();
        }
    }

    getActivityId() {
        return this.props.match.params.activityId;
    }

    mapParseActivityToObject(activity: Parse.Object) {
        const data: EditActivityDataInterface = {
            isRecurring: false
        };

        data.customImageUrl = activity.get('customImage') ? activity.get('customImage')._url : null;
        data.customTitle = activity.get('customTitle');
        data.groupId = activity.get('group').id;
        data.hostId = activity.get('host').id;
        data.sportKey = activity.get('sportKey');
        data.minSkillLevel = activity.get('minSkillLevel');
        data.maxSkillLevel = activity.get('maxSkillLevel');
        data.gameMode = activity.get('gameMode');
        data.venueId = activity.get('venue') ? activity.get('venue').id : undefined;
        data.gameStart = activity.get('gameStart');
        data.deadline = activity.get('deadline');


        const gameStart = moment(activity.get('gameStart'));
        const deadline = moment(activity.get('deadline'));

        if (gameStart.diff(deadline, 'minutes') === 0) {
            data.deadline = ActivityDeadlineEnum.TIME_OF_EVENT;
        }
        else if (gameStart.diff(deadline, 'hours') === 1) {
            data.deadline = ActivityDeadlineEnum.ONE_HOUR_BEFORE;
        }
        else if (gameStart.diff(deadline, 'days') === 1) {
            data.deadline = ActivityDeadlineEnum.ONE_DAY_BEFORE;
        }
        else if (gameStart.diff(deadline, 'seconds') < 0) {
            data.deadline = ActivityDeadlineEnum.END_TIME;
        }

        console.log(gameStart.diff(deadline, 'seconds'))

        data.note = activity.get('note');
        data.athletesHidden = activity.get('athletesHidden');
        data.minPlayers = activity.get('minPlayers');
        data.maxPlayers = activity.get('maxPlayers');
        data.gameDurationMinutes = activity.get('gameDurationMinutes');

        return data;
    }

    render() {
        const activity = activityDetailsStore.activity;
        if (!activity) return null;

        const data = this.mapParseActivityToObject(activity);


        return (
            <div className='w-full'>
                <ActivityForm onSubmit={this.onSubmit} editData={data} submitButtonText={this.shouldDuplicate() ? 'Create duplicate' : undefined} />
            </div>

        )
    }

}

export default EditActivityPage;