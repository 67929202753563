import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
    children: React.ReactNode,
    onSubmit: Function,
    allowedFileTypes: Array<string>,
}

@observer class UploadFile extends React.Component<Props> {

    MAX_FILE_SIZE = 10485760;
    hiddenUploadFieldRef: React.RefObject<any>;

    constructor(props: any) {
        super(props);

        this.hiddenUploadFieldRef = React.createRef();
    }

    selectFile = () => {
        this.hiddenUploadFieldRef.current.click();
    }

    humanFileSize(size: number) {
        var i = Math.floor(Math.log(size) / Math.log(1024)) as number;
        const x = (size / Math.pow(1024, i)).toFixed(2) as any;
        return x * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    uploadFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        var { onSubmit, allowedFileTypes } = this.props;

        if (e.target.value !== "") {
            const file = e.target.files![0];
            if (file.size > this.MAX_FILE_SIZE) {
                alert(`The file you are trying to upload is too big. Max file size is ${this.humanFileSize(this.MAX_FILE_SIZE)}.`);
            }
            else {
                var ext = file!.name!.split('.')!.pop()!.toLowerCase();

                if (allowedFileTypes.indexOf(ext) !== -1) {
                    onSubmit(file);
                }
                else {
                    alert("Sorry. We are not currently allowing files with the extension of " + ext + ".");
                }
            }
        }
    }

    render() {
        const { children } = this.props;

        const hideStyle = {
            height: '0',
            width: '0',
            position: "absolute",
            top: "-9999px",
            overflow: 'hidden'
        } as React.CSSProperties

        return (
            <span>
                <input onChange={this.uploadFile} style={hideStyle} ref={this.hiddenUploadFieldRef} type="file" />
                <span onClick={this.selectFile}>
                    {children}
                </span>
            </span>
        );
    }

}

export default UploadFile;