import * as React from 'react';
import { observer } from 'mobx-react';
import { fitnessStore } from 'stores';
import SearchCombo from 'components/Forms/SearchCombo';
import _ from 'lodash';
import { activities } from 'helpers/ActivitiesHelper';


interface Props {
    groupId: string,
    archived?: boolean
}

@observer class SearchActivity extends React.Component<Props> {

    getActivities = (opts: any = {}) => {
        const { groupId, archived } = this.props;
        opts.archived = archived || false;

        fitnessStore.getActivities(groupId, opts);
    }

    onClearFilteredActivities = () => {
        this.getActivities();
    }

    onSearchBySportKey(searchValue: string) {
        let sportKey = null;

        Object.keys(activities).forEach((key) => {
            if (activities[key].toLowerCase().indexOf(searchValue.toLowerCase()) !== -1) {
                sportKey = key;
            }
        })

        if (sportKey) {
            this.getActivities({ sportKey: sportKey });
        }
        else {
            fitnessStore.resetActivities();
        }
    }

    onSearchByHost(hostName: string) {
        this.getActivities({ hostName: hostName });
    }

    onSearchByTitle(customTitle: string) {
        this.getActivities({ customTitle: customTitle });
    }

    render() {

        const options = [
            {
                value: 'sport',
                name: 'Sport',
                filterFunc: (searchValue: string) => this.onSearchBySportKey(searchValue)
            },
            {
                value: 'host',
                name: 'Host',
                filterFunc: (searchValue: string) => this.onSearchByHost(searchValue)
            },
            {
                value: 'title',
                name: 'Title',
                filterFunc: (searchValue: string) => this.onSearchByTitle(searchValue)
            }
        ]

        return (
            <SearchCombo
                placeholder='Search activities'
                onClearFiltered={() => this.onClearFilteredActivities()}
                options={options} />
        )
    }

}

export default SearchActivity;