import * as React from 'react';
import { observer } from 'mobx-react';
import Popup from 'reactjs-popup';

import './style.css';
import ActionButton from 'components/ActionButton';

interface Props {
    id: string,
    label: string,
    value?: string,
    onClick: (id: string, value?: string) => void,
    children: React.ReactNode,
    onClose?: () => void,
    style?: React.CSSProperties
}

@observer class InputModal extends React.Component<Props> {

    closeButtonRef: any;

    constructor(props: Props) {
        super(props);

        this.closeButtonRef = React.createRef();
    }

    render() {
        const { label, value, onClick, id, children, onClose, style } = this.props;

        return (
            <Popup
                className='InputModal'
                onClose={onClose}
                trigger={
                    <div className='my-5 mr-2' style={style}>
                        <label htmlFor={id}>{label}</label>
                        <input
                            id={id}
                            className='Input Input-light mt-2 cursor-pointer'
                            value={value}
                            readOnly={true}
                            onClick={(e) => onClick(id, value)}
                        />
                    </div>
                }
                modal
                closeOnDocumentClick>

                {
                    close => (
                        <div className='m-1'>
                            {children}
                            <div className='text-right mt-4'>
                                <ActionButton onClick={close} primary={true} text='Done' width='wrap' ref={this.closeButtonRef} />
                            </div>
                        </div>
                    )
                }

            </Popup>


        )
    }

}

export default InputModal;