import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState, FormState } from 'formstate';
import { authStore } from 'stores';
import _ from 'lodash';
import SectionItem from 'components/Forms/SectionItem';
import Switch from 'components/Forms/Switch';
import InputModal from 'components/StatsPage/Activities/InputModal';
import Section from 'components/Forms/Section';
import { courtStore } from 'stores/CourtStore';

interface Props {
    courtsChanged: Function,
}

interface State {
    values: string
}

@observer class SelectCourts extends React.Component<Props, State> {

    ANY_PLACEHOLDER: string = 'Select subvenue(s)';

    form = new FormState({}) as any;
    state = { values: this.ANY_PLACEHOLDER }

    async componentDidMount() {
        await courtStore.getCourts(authStore.customer.id, true);

        courtStore.courts.forEach((court) => {
            this.form.$[court.id] = new FieldState(false);
        });
    }

    componentWillUnmount() {
        courtStore.resetStore();
    }

    updateSelectedCourts = () => {
        const { courtsChanged } = this.props;
        const values = [];
        const form = this.form.$;

        for (let key in form) {
            if (form[key].value === true) {
                const item = _.find(courtStore.courts, { id: key });
                if (item) {
                    values.push(item.get('name'));
                }
            }
        }

        courtsChanged(this.form);

        this.setState({
            values: values.join(', ') || this.ANY_PLACEHOLDER
        })
    }

    render() {
        const form = this.form.$;
        const { values } = this.state;

        const items = [];
        for (let key in form) {
            const item = form[key];
            const court = _.find(courtStore.courts, { id: key });

            items.push(
                <SectionItem title={court ? court.get('name') : 'No name'} key={key}>
                    <Switch fieldState={item} className='justify-end' />
                </SectionItem>
            )
        }

        return (
            <InputModal
                style={{ flex: 2 }}
                label='Subvenue(s)'
                id='subvenues'
                value={values}
                onClose={this.updateSelectedCourts}
                onClick={() => null}>

                <Section secondaryClassName='overflow-auto' secondaryStyle={{ maxHeight: '80vh' }}>
                    {items}
                </Section>

            </InputModal>
        )
    }
}

export default SelectCourts;