import * as React from 'react';
import { observer } from 'mobx-react';
import { EditorState } from 'draft-js';
import EditorButton from '../EditorButton';

interface Props {
    editorState: EditorState,
    label: string,
    inlineStyle: string,
    onToggle: (inlineStyle: string) => void,
    position?: 'left' | 'right'
}

@observer class EditorBlockStyleButton extends React.Component<Props> {

    render() {
        const { editorState, inlineStyle } = this.props;
        
        const selection = editorState.getSelection();
        const blockType = editorState
          .getCurrentContent()
          .getBlockForKey(selection.getStartKey())
          .getType();

        return (
            <EditorButton
                active={inlineStyle === blockType}
                {...this.props} />
        )
    }

}

export default EditorBlockStyleButton;