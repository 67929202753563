import * as React from 'react';
import { observer } from 'mobx-react';
import { authStore, navigationStore, loadingStore } from 'stores';
import Court from './Court';
import ActionButton from 'components/ActionButton';
import { courtStore } from 'stores/CourtStore';
import _ from 'lodash';

interface State {
    showAll: boolean
}

@observer class CourtsPage extends React.Component<any, State> {

    state = { showAll: false }

    componentDidMount() {
        const customerId = authStore.customer.id;
        courtStore.getCourts(customerId, true);
    }

    showInactive = () => {
        const customerId = authStore.customer.id;
        courtStore.getCourts(customerId, false);

        this.setState({
            showAll: true
        });
    }

    render() {
        const { showAll } = this.state;
        let courts = courtStore.courts || [];
        courts = _.orderBy(courts, c => [c.get('venue').get('name'), c.get('name')]);

        return (
            <div className='w-full mb-20'>
                <div className='text-right mb-6'>
                    <ActionButton width='wide' primary={true} text='Create subvenue' onClick={() => navigationStore.push('/courts/create')} />
                </div>

                <div className='Courts bg-white rounded mt-4 relative'>
                    {
                        courts.map((court, index) => {
                            return (
                                <Court
                                    key={index}
                                    court={court}
                                />
                            )
                        })
                    }

                </div>

                {
                    (!loadingStore.isLoading && !showAll) &&
                    <div className='text-center mb-6 mt-12'>
                        <ActionButton width='narrow' text='Show inactive' onClick={() => this.showInactive()} />
                    </div>
                }

            </div>

        )
    }

}

export default CourtsPage;