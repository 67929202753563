import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import Dropdown, { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { TimeslotsInterface } from 'components/CreateCourtPage/Timeslots';
import moment from 'moment';
import Input from 'components/Forms/Input';

interface Props {
    from: FieldState<string>,
    timeslotDuration: number,
    timeslotBuffer?: number,
    timeslots: TimeslotsInterface,
    date: Date
}

@observer class SelectTimeSpan extends React.Component<Props> {

    static OPENING_HOUR = 8;
    static CLOSING_HOUR = 22;

    getStartTime(dayNo: number) {
        const { timeslots } = this.props;
        const timeSlots = timeslots; // timeslots is miss-spelled in Parse. Just to keep it consistent we also do it here.
        return timeSlots[dayNo] && timeSlots[dayNo].timeSlots[0] && timeSlots[dayNo].timeSlots[0].from || null;
    }

    getEndTime(dayNo: number) {
        const { timeslots } = this.props;
        const timeSlots = timeslots; // timeslots is miss-spelled in Parse. Just to keep it consistent we also do it here.
        const sizeOfSlots = timeSlots[dayNo] && timeSlots[dayNo].timeSlots.length;
        if (sizeOfSlots === 0) return null;

        return timeSlots[dayNo] && timeSlots[dayNo].timeSlots[sizeOfSlots - 1] && timeSlots[dayNo].timeSlots[sizeOfSlots - 1].to || null;
    }

    getTimeslots(dayNo: number) {
        const { timeslots } = this.props;
        const timeSlots = timeslots // timeslots is miss-spelled in Parse. Just to keep it consistent we also do it here.
        return timeSlots[dayNo] && timeSlots[dayNo].timeSlots || [];
    }

    render() {
        const { from, date } = this.props;
        const mDate = moment(date);
        const weekDayNo = mDate.isoWeekday() - 1; // Null indexed in Parse object
        // const timeFromStr = this.getStartTime(weekDayNo);
        // const closingHourStr = this.getEndTime(weekDayNo);
        // const closingHour = moment(closingHourStr, 'HH:mm');
        // let timeFrom = moment(timeFromStr, 'HH:mm');

        if (!this.getStartTime(weekDayNo) ||  !this.getEndTime(weekDayNo)) {
            return <Input readOnlyValue='No timeslots available' />
        }


        const opts: Array<DropdownOptionInterface> = [];
        const slots = this.getTimeslots(weekDayNo);
        slots.forEach((slot: any) => {
            opts.push({
                name: `${moment(slot.from, 'HH:mm').format('h:mm A')} - ${moment(slot.to, 'HH:mm').format('h:mm A')}`,
                value: moment(slot.from, 'HH:mm').format('HH:mm')
            })
        })

        // if (timeFromStr && closingHourStr && timeFromStr !== closingHourStr) {
        //     do {
        //         const fromPretty = timeFrom.format('h:mm A'); // AM PM
        //         const toPretty = timeFrom.clone().add(timeslotDuration, 'minutes').format('h:mm A');

        //         opts.push({
        //             value: timeFrom.format('hh:mm'),
        //             name: `${fromPretty} - ${toPretty}`
        //         })

        //         timeFrom = timeFrom.clone().add(timeslotDuration, 'minutes');

        //         if (timeslotBuffer && timeslotBuffer > 0) {
        //             timeFrom.add(timeslotBuffer, 'minutes');
        //         }

        //     }
        //     while (timeFrom.isBefore(closingHour));
        // }

        return (
            <Dropdown
                className='Dropdown-light'
                skipSort={true}
                fieldState={from}
                options={opts} />
        )
    }

}

export default SelectTimeSpan;