import * as React from 'react';
import { navigationStore } from 'stores';
import { Switch, Router, Route } from 'react-router';
import LoginPage from 'components/LoginPage';
import DashboardPage from 'components/DashboardPage';
import GroupDetailsWrapper from 'components/GroupDetailsWrapper';
import MembersWrapper from 'components/MembersWrapper';
import VenuesPage from 'components/VenuesPage';
import CreateVenuePage from 'components/CreateVenuePage';
import MemberDetailsPage from 'components/MemberDetailsPage';
import ActivityDetailsPage from 'components/ActivityDetailsPage';
import EditActivityPage from 'components/EditActivityPage';
import EditRecurringActivityPage from 'components/EditRecurringActivityPage';
import CreateGroupPage from 'components/CreateGroupPage';
import EditGroupPage from 'components/EditGroupPage';
import HomePage from 'components/HomePage';
import AddGroupMembersPage from 'components/AddGroupMembersPage';
import SettingsPage from 'components/SettingsPage';
import Toast from 'components/Toast';
import StatsPage from 'components/StatsPage';
import CreateWaiverPage from 'components/CreateWaiverPage';
import EditWaiverPage from 'components/EditWaiverPage';
import BookingsPage from 'components/BookingsPage';
import CreatePostPage from 'components/CreatePostPage';
import EditPostPage from 'components/EditPostPage';
import ConversationPage from 'components/ActivityDetailsPage/ConversationPage';
import CourtsPage from 'components/CourtsPage';
import CreateCourtPage from 'components/CreateCourtPage';
import PersonalMessagesPage from 'components/PersonalMessagesPage';
import PersonalMessageDetailsPage from 'components/PersonalMessageDetailsPage';
import CreatePersonalConversationPage from 'components/CreatePersonalConversationPage';
import EmailPage from 'components/ActivityDetailsPage/EmailPage';

class App extends React.Component {

	render() {
		return (
			<Router history={navigationStore.history}>
				<Toast />
				<Switch>
					<Route exact path="/login" component={LoginPage} />
					<DashboardPage>
						<Route exact path='/settings' component={SettingsPage} />
						<Route exact path='/' component={HomePage} />
						<Route exact path='/addgroupmembers/:groupId' component={AddGroupMembersPage} />
						<Route exact path='/editgroup/:groupId' component={EditGroupPage} />
						<Route exact path='/creategroup/:groupType(fitness|clubsport)' component={CreateGroupPage} />
						<Route exact path='/:groupType(main)/:groupId?/:section?' component={GroupDetailsWrapper} />
						<Route exact path='/:groupType(fitness|clubsport)/:groupId/:section' component={GroupDetailsWrapper} />
						<Route path='/members' component={MembersWrapper} />
						<Route exact path='/memberdetails/:userId' component={MemberDetailsPage} />
						<Route exact path='/messages' component={PersonalMessagesPage} />
						<Route exact path='/messages/:id' component={PersonalMessageDetailsPage} />
						<Route exact path='/create-conversation' component={CreatePersonalConversationPage} />
						<Route exact path='/venues' component={VenuesPage} />
						<Route exact path='/courts' component={CourtsPage} />
						<Route exact path='/bookings' component={BookingsPage} />
						<Route exact path='/stats' component={StatsPage} />
						<Route exact path='/venues/create/:id?' component={CreateVenuePage} />
						<Route exact path='/courts/:type(create|edit|copy)/:id?' component={CreateCourtPage} />

						<Route exact path='/activitydetails/:activityId' component={ActivityDetailsPage} />
						<Route exact path='/activitydetails/:activityId/conversation' component={ConversationPage} />
						<Route exact path='/activitydetails/:activityId/email' component={EmailPage} />
						<Route exact path='/editactivity/:activityId/:duplicate(duplicate)?' component={EditActivityPage} />
						<Route exact path='/editactivity/:activityId/recurring' component={EditRecurringActivityPage} />
						<Route exact path='/waivers/create/:groupId' component={CreateWaiverPage} />
						<Route exact path='/waivers/:waiverId' component={EditWaiverPage} />
						<Route exact path='/posts/create/:groupId' component={CreatePostPage} />
						<Route exact path='/posts/:postId' component={EditPostPage} />
					</DashboardPage>
				</Switch>
			</Router>
		)
	}
}

export default App;