import * as React from 'react';
import { observer } from 'mobx-react';

@observer class Feedback extends React.Component {

    render() {
        return (
            <a className='text-inactive hover:text-white no-underline font-thin' href='mailto:info@atletosports.com?subject=Dashboard%20feedback' target='_blank'>What are we missing?</a>
        )
    }

}

export default Feedback;