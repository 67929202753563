import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState, FormState } from 'formstate';
import Moment from 'moment';
import Section from 'components/Forms/Section';
import { required } from 'helpers/FormValidation';
import SelectPeriod from '../Activities/SelectPeriod';
import SelectCourts from './SelectCourts';
import DateInput from 'components/Forms/DateInput';
import ActionButton from 'components/ActionButton';
import { authStore, loadingStore, statsStore } from 'stores';
import Separator from 'components/Separator';
import NumbersBox from '../Activities/NumbersBox';
import NoShows from './NoShows';

interface State {
    noShowVisible: boolean
}

@observer class Bookings extends React.Component<any, State> {

    state = { noShowVisible: false }

    form = new FormState({
        fromDate: new FieldState<Date | null>(Moment().startOf('week').toDate()).validators(required),
        toDate: new FieldState<Date | null>((Moment().toDate())).validators(required),
        courts: new FormState<any>({}),
        fromTime: new FieldState<Date | null>(Moment().startOf('day').toDate()),
        toTime: new FieldState<Date | null>(Moment().endOf('day').toDate())
    })

    courtsChanged = (form: FormState<any>) => {
        this.form.$.courts = form;
    }

    getSelectedFieldStateKeys = (formState: FormState<any>) => {
        const result: Array<string> = [];

        Object.keys(formState.$).forEach((key) => {
            if (formState.$[key].value === true) {
                result.push(key);
            }
        })

        return result;
    }

    onCreateReport = () => {
        this.setState({ noShowVisible: false });
        statsStore.resetStore();

        const form = this.form.$;

        if (Object.keys(form.courts.$).length === 0) {
            alert('One or more subvenues must be selected');
            return;
        }

        const data = {
            customerId: authStore.customer.id,
            fromDate: Moment(form.fromDate.$!).format('YYYY-MM-DD'),
            toDate: Moment(form.toDate.$!).format('YYYY-MM-DD'),
            courts: this.getSelectedFieldStateKeys(form.courts),
            fromTime: Moment(form.fromTime.$!).format('HH:mm'),
            toTime: Moment(form.toTime.$!).format('HH:mm'),
        }

        statsStore.getBookingStats(data);

    }

    toggleNoShowVisible = () => {
        this.setState({ noShowVisible: !this.state.noShowVisible });
    }

    render() {
        const { noShowVisible } = this.state;
        const form = this.form.$;
        const data = statsStore.bookingData;

        return (
            <div>
                <Section className='StatsPage mt-6'>
                    <div className='flex'>
                        <SelectPeriod from={form.fromDate} to={form.toDate} />
                        <SelectCourts courtsChanged={this.courtsChanged} />
                        <div className='my-5 mr-2 flex-1'>
                            <label>From</label>
                            <DateInput style={{ marginTop: '7px' }} fieldState={form.fromTime} options={{ enableTime: true, noCalendar: true, dateFormat: 'h:i K' }} />
                        </div>
                        <div className='my-5 flex-1'>
                            <label>To</label>
                            <DateInput style={{ marginTop: '7px' }} fieldState={form.toTime} options={{ enableTime: true, noCalendar: true, dateFormat: 'h:i K' }} />
                        </div>
                    </div>

                    <div className='flex-auto text-right pb-5'>
                        {
                            data !== null &&
                            <ActionButton text='Print' width='narrow' onClick={() => window.print()} className='hide-on-print mr-2' />
                        }

                        {
                            !loadingStore.isLoading &&
                            <ActionButton
                                text={data !== null ? 'Update stats' : 'Create stats'}
                                width='wide' primary={true}
                                className='hide-on-print'
                                onClick={this.onCreateReport}
                            />
                        }
                    </div>
                </Section>

                {
                    loadingStore.isLoading &&
                    <div className='text-center mt-20'>
                        <h1 className='text-white uppercase mt-8'>
                            Loading... This may take a while...
                        </h1>
                    </div>
                }

                {
                    (!loadingStore.isLoading && data) &&
                    <>
                        <Separator className='my-8' />

                        <div className='w-full justify-between lg:flex'>
                            <NumbersBox title='Bookings' value={data.bookings || 0} />
                            <NumbersBox title='Checked In' value={data.checkedIn || 0} />
                            <NumbersBox
                                title='No Shows'
                                value={data.noShows.length || 0}
                                action={data.noShows.length > 0 ? { title: noShowVisible ? 'Hide details' : 'Show details', onClick: this.toggleNoShowVisible } : undefined}
                            />
                            <NumbersBox title='Capacity' value={data.capacity || 0} />

                        </div>

                        {
                            (noShowVisible && data.noShows.length > 0) &&
                            <NoShows bookings={data.noShows} />
                        }

                    </>
                }
            </div>
        )
    }
}

export default Bookings;