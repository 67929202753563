import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
    text: string,
    isActive: boolean
    lat: number,
    lng: number
}

@observer class GoogleMapsMarker extends React.Component<Props> {

    render() {
        const { text, isActive } = this.props;

        let className = "GoogleMaps-marker";
        if (!isActive) {
            className += " GoogleMaps-disabled";
        }

        return (
            <div className={className}>
                {text}
            </div>
        )
    }
}

export default GoogleMapsMarker;