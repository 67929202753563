import * as React from 'react';
import { observer } from 'mobx-react';
import ActionButton from 'components/ActionButton';
import { navigationStore } from 'stores';

interface Props {
    customUrl?: string
}

@observer class BackButton extends React.Component<Props> {

    onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        const { customUrl } = this.props;
        if (customUrl) {
            navigationStore.push(customUrl);
        }
        else {
            navigationStore.goBack();
        }
    }

    render() {
        return (
            <ActionButton
                style={{ marginTop: '3px' }}
                className='mb-6'
                text='Back'
                onClick={this.onClick}
                width='wrap' />
        )
    }

}

export default BackButton;