import Parse from 'parse';
import { action, observable } from 'mobx';
import LoadPromise from 'helpers/LoadPromise';
import { Pointer } from 'helpers/Parse';
import _ from 'lodash';

export interface ConversationListInterface {
    id: string,
    participants: Array<Parse.Object>,
    lastMessage: Parse.Object | null
}

export class ConversationStore {

    @observable conversations: Array<ConversationListInterface> = [];
    @observable conversation: Parse.Object | null = null;
    @observable messages: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.conversations = [];
        this.conversation = null;
        this.messages = [];
    }

    @action
    resetMessages() {
        this.messages = [];
    }

    @action
    createOrGetPersonalConversation(userIds: Array<string>) {
        return new Promise(async (resolve, reject) => {
            try {
                const conversation = await Parse.Cloud.run('getConversation', {
                    requesterUserID: Parse.User.current()!.id,
                    otherUserIDs: userIds
                })

                resolve(conversation);
            }
            catch(error) {
                reject(error.message);
            }
        })
    }

    @action
    getPersonalConversations() {
        return LoadPromise(async (resolve, reject) => {
            try {
                var conversationQuery = new Parse.Query("Conversation");
                conversationQuery.equalTo("participants", Parse.User.current()!.id);
                conversationQuery.doesNotExist("game");

                conversationQuery.limit(99999);

                const conversations = await conversationQuery.find();
                if (conversations) {

                    const participants: Array<any> = [];
                    conversations.forEach((c) => {
                        c.get('participants').forEach((p: any) => {
                            participants.push(p);
                        })
                    })

                    const userQuery = new Parse.Query("_User");
                    userQuery.containedIn("objectId", participants);
                    const users = await userQuery.find();

                    const arrVal: Array<ConversationListInterface> = [];

                    for (const c of conversations) {
                        let val = {
                            id: c.id,
                            participants: [] as Array<Parse.Object>,
                            lastMessage: null
                        } as ConversationListInterface;

                        c.get('participants').forEach((userId: string) => {
                            const match = _.find(users, { id: userId });
                            if (match && match.id !== Parse.User.current()!.id) {
                                val.participants.push(match);
                            }
                        })

                        const messageQuery = new Parse.Query("Message");
                        messageQuery.equalTo("conversation", Pointer("Conversation", c.id))
                        messageQuery.descending("createdAt")
                        messageQuery.limit(1);

                        const message = await messageQuery.first();
                        if (message) {
                            val.lastMessage = message
                            arrVal.push(val);
                        }

                    }

                    this.conversations = arrVal;
                    resolve(this.conversations);
                }
                reject();

            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getConversation(activityId: string, includeMessages: boolean = false) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const conversationQuery = new Parse.Query('Conversation');
                conversationQuery.equalTo('game', Pointer('Game', activityId));

                const conversation = await conversationQuery.first();
                if (conversation) {
                    this.conversation = conversation;

                    if (includeMessages) {
                        await this.getMessages(conversation.id);
                    }

                    resolve(conversation);
                }
                reject();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getConversationById(conversationId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const conversationQuery = new Parse.Query('Conversation');
                const conversation = await conversationQuery.get(conversationId) as any;
                if (conversation) {
                    const userQuery = new Parse.Query("_User");
                    userQuery.containedIn("objectId", conversation.get("participants").filter((userId: string) => userId !== Parse.User.current()!.id));
                    const users = await userQuery.find();
                    conversation._users = users; // Hidden property to add users
                    
                    this.conversation = conversation;
                    await this.getMessages(conversation.id);
                    resolve(conversation);
                }
                reject();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getMessages(conversationId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const messageQuery = new Parse.Query('Message');
                messageQuery.equalTo('conversation', Pointer('Conversation', conversationId));
                messageQuery.include('sender');
                messageQuery.descending('createdAt');
                messageQuery.limit(9999);

                const messages = await messageQuery.find();
                if (messages) {
                    this.messages = messages;
                    resolve(messages);
                }
                reject();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    sendMessage(text: string) {
        return LoadPromise(async (resolve, reject) => {
            if (!conversationStore.conversation) reject();
            try {
                const Message = Parse.Object.extend('Message');
                const message = new Message();
                message.set('message', text);

                const user = Parse.User.current();
                message.set('sender', user);

                const conversationQuery = new Parse.Query('Conversation');
                const conversation = await conversationQuery.get(this.conversation!.id);
                console.log(conversation.id);

                message.set('conversation', conversation);

                const savedMessage = await message.save();
                this.messages.unshift(savedMessage);
                resolve(savedMessage);
            }
            catch (error) {
                console.log(error)
                reject(error.message);
            }
        })
    }

}

export const conversationStore = new ConversationStore();