import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import RadioGroup from 'components/Forms/RadioGroup';
import Input from 'components/Forms/Input';

interface Props {
    minPlayersFieldState: FieldState<any>,
    maxPlayersFieldState: FieldState<any>
}

interface State {
    unlimited: boolean
}

@observer class SelectNumberOfAthletes extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        let unlimited = true;

        if (props.minPlayersFieldState.value > 0 || props.maxPlayersFieldState.value > 0) {
            unlimited = false;
        }

        this.state = {
            unlimited: unlimited
        }
    }

    onChange = (value: boolean) => {
        if (value === true) {
            const { minPlayersFieldState, maxPlayersFieldState } = this.props;
            minPlayersFieldState.onChange(0);
            maxPlayersFieldState.onChange(0);
        }

        this.setState({
            unlimited: value
        })
    }

    render() {
        const { minPlayersFieldState, maxPlayersFieldState } = this.props;
        const { unlimited } = this.state;

        return (
            <div>
                <RadioGroup
                    onChange={this.onChange}
                    value={unlimited}
                    items={[
                        {
                            name: 'Unlimited',
                            value: true,
                            id: 'unlimited-athletes-true'
                        }, {
                            name: 'Minimum and maximum (including host)',
                            value: false,
                            id: 'unlimited-athletes-false'
                        }
                    ]}
                />
                {
                    !unlimited &&
                    <div>
                        <Input fieldState={minPlayersFieldState} type='number' />
                        <div className='mt-2'>
                            <Input fieldState={maxPlayersFieldState} type='number' />
                        </div>
                    </div>
                }
            </div>

        )
    }

}

export default SelectNumberOfAthletes;