import { loadingStore, toastStore } from 'stores';

async function LoadPromise(callback: (s: any, reject: any) => Promise<unknown>): Promise<unknown> {
    loadingStore.addRequest();
    return new Promise(callback)
        .then(() => {
            loadingStore.removeRequest();
        })
        .catch((err) => {
            loadingStore.removeRequest()
            console.log(err);
            toastStore.addError(err.message);
        })
}

export default LoadPromise;