import ActionButton from 'components/ActionButton';
import { observer } from 'mobx-react';
import * as React from 'react';
import { navigationStore, fitnessStore } from 'stores';

interface Props {
    groupId: string,
    groupType: string
}

@observer class TopActionButtons extends React.Component<Props> {

    onCreateActivity = () => {
        const { groupId, groupType } = this.props;
        navigationStore.push(`/${groupType}/${groupId}/createactivity`);
    }

    onEditGroup = () => {
        const { groupId } = this.props;
        navigationStore.push(`/editgroup/${groupId}`);
    }

    onAddMembers = () => {
        const { groupId } = this.props;
        navigationStore.push(`/addgroupmembers/${groupId}`);
    }

    render() {
        const { groupType, groupId } = this.props;

        if (groupType !== 'main' && !fitnessStore.groupIsActive(groupId)) {
            return <p className='text-right mt-4 text-grey-dark font-light mb-8'>This group is archived.</p>
        }

        return (
            <div className='w-full text-right mb-6'>
                {
                    groupType !== 'main' &&
                    <>
                        <ActionButton width='narrow' text='Edit group' onClick={this.onEditGroup} className='mr-5' />
                        <ActionButton width='narrow' text='Add members' onClick={this.onAddMembers} className='mr-5' primary={true} />
                    </>
                }

                <ActionButton width='wide' text='Create activity' onClick={this.onCreateActivity} primary={true} />
                
            </div>
        )
    }

}

export default TopActionButtons;