import * as React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import './style.css';
import Icon from './icon.svg';

interface Props {
    className?: string,
    placeholder?: string,
    onChange?: Function
}

@observer class SearchInput extends React.Component<Props> {

    constructor(props: Props) {
        super(props);

        this.searchQueryChanged = _.debounce(this.searchQueryChanged, 200);
    }

    searchQueryChanged = (val: any) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(val);
        }
    }

    render() {
        const { className, placeholder } = this.props;

        return (
            <div className={`w-full SearchInput ${className || ''}`}>
                <img src={Icon} alt='search' className='inline-block w-4 h-4 absolute mt-3 ml-3 opacity-75' />
                <input onChange={(e) => this.searchQueryChanged(e.target.value)} className='w-full py-2 pl-10 pr-3 leading-normal' id='search' type='text' placeholder={placeholder} />
            </div>
        );
    }

}

export default SearchInput;