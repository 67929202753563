import * as React from 'react';
import { observer } from 'mobx-react';
import ReactSwitch from 'react-switch';
import { FieldState } from 'formstate';

interface Props {
    fieldState: FieldState<boolean>,
    className?: string
}

@observer class Switch extends React.Component<Props> {

    onChange = (value: boolean) => {
        const { fieldState } = this.props;

        fieldState.onChange(value);
    }

    render() {
        const { fieldState, className } = this.props;

        return (
            <div className={`flex items-center ${className || ''}`} style={{ height: '39px' }}>
                <ReactSwitch
                    checked={fieldState.value || false}
                    onChange={this.onChange}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    height={22}
                    width={38}
                    offColor='#A2A7B0'
                    onColor='#BFD730'
                />
            </div>

        )
    }

}

export default Switch;