import * as React from 'react';
import { observer } from 'mobx-react';
import { Parse } from 'helpers/Parse';
import SectionTitle from 'components/SectionTitle';
import Table from 'components/Table';
import AvatarCell from 'components/Table/AvatarCell';

import AddImg from './Add.svg';
import PlaceholderImg from './Placeholder.svg';
import { CellInfo } from 'react-table';
import { memberDetailsStore, CustomerGroupTypeEnum, navigationStore, customerRoleStore } from 'stores';
import AddToGroup from './AddToGroup';

interface ItemInterface {
    groupId: string,
    imageSrc: string | null,
    title: string,
    groupType?: CustomerGroupTypeEnum,
    memberId?: string
}

enum NewGroupEnum {
    NEW_GROUP = 'NEW_GROUP'
}

interface Props {
    userId: string
}

interface State {
    addToGroupPopUpOpen: boolean
}

@observer class Groups extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            addToGroupPopUpOpen: false
        }
    }

    onRowClicked = (row: ItemInterface) => {
        if (row.groupId === NewGroupEnum.NEW_GROUP) {
            this.setState({ addToGroupPopUpOpen: true });
        }
        else {
            const groupType = row.groupType;
            const endpoint = groupType === CustomerGroupTypeEnum.CLUB_SPORT ? 'clubsport' : 'fitness';
            navigationStore.push(`/${endpoint}/${row.groupId}/activities`);
        }
    }

    deleteMembership = (e: React.MouseEvent, memberId: string) => {
        e.preventDefault();
        e.stopPropagation();

        if (window.confirm('Are you sure you want to remove member from group?')) {
            memberDetailsStore.deleteMembership(memberId);
        }
    }

    onClosePopUp = () => {
        this.setState({ addToGroupPopUpOpen: false })
    }

    canAddToGroup = (userRole: Parse.Object | null) => {
        if (!userRole) return true;
        const limitedAccess = userRole!.get('limitedAccess');
        return !limitedAccess;
    }

    render() {
        const { userId } = this.props;
        const { addToGroupPopUpOpen } = this.state;
        const groupMembers = memberDetailsStore.memberships;
        const data: Array<ItemInterface> = [];
        const userRole = customerRoleStore.loggedInUserRole;

        const existingGroups = groupMembers.map((m) => m.get('group'));

        groupMembers.forEach((member: Parse.Object) => {
            const group = member.get('group');
            if (group.get('customerGroupType') === CustomerGroupTypeEnum.MAIN) {
                return;
            }

            data.push({
                memberId: member.id,
                groupId: group.id,
                imageSrc: group.get('image') ? group.get('image')._url : PlaceholderImg,
                title: group.get('name'),
                groupType: group.get('customerGroupType')
            })
        })

        const columns = [
            {
                Header: '',
                id: 'name',
                accessor: (item: ItemInterface) => {
                    return <AvatarCell
                        imageUrl={item.imageSrc || undefined}
                        title={item.title}
                    />
                }
            },
            
        ]

        if (this.canAddToGroup(userRole)) {
            data.push({
                groupId: NewGroupEnum.NEW_GROUP,
                imageSrc: AddImg,
                title: 'Add to group'
            })

            columns.push({
                Header: '',
                id: 'action',
                width: 100,
                Cell: (cellInfo: CellInfo) => {
                    const item = cellInfo.original as ItemInterface;

                    if (item.groupId === NewGroupEnum.NEW_GROUP) return null;

                    return (
                        <button
                            className='no-underline text-warning font-medium'
                            onClick={(e) => this.deleteMembership(e, item.memberId!)}>
                            Delete
                        </button>
                    )

                }
            } as any)
        }

        return (
            <div>
                <SectionTitle title='Member of' skipMarginBottom={true} />

                <Table
                    data={data}
                    columns={columns}
                    condensed={true}
                    onRowClicked={this.onRowClicked}
                />

                <AddToGroup existingGroups={existingGroups} userId={userId} open={addToGroupPopUpOpen} onClose={this.onClosePopUp} />

            </div>
        )
    }

}

export default Groups;