import * as React from 'react';
import { observer } from 'mobx-react';
import PrimarySectionTitle from '../PrimarySectionTitle';
import SecondaryMenuItem from '../SecondaryMenuItem';
import SecondaryMenuContainer from '../SecondaryMenuContainer';
import { authStore, fitnessStore, navigationStore, GroupDetailsTopMenuEnum, CustomerGroupTypeEnum, loadingStore } from 'stores';
import SearchInput from 'components/Forms/SearchInput';
import Separator from 'components/Separator';

import CreateGroupIcon from './CreateGroupIcon.svg';

interface Props {
    groupType: CustomerGroupTypeEnum
}

interface State {
    onlyActiveGroups: boolean
}

@observer class SecondaryMenuGroups extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            onlyActiveGroups: true
        }
    }

    async componentDidMount() {
        if (authStore.customer) {
            this.getGroups();
        }
    }

    async getGroups() {
        const { groupType } = this.props;
        const { onlyActiveGroups } = this.state;

        await fitnessStore.getGroups(authStore.customer.id, groupType, false, onlyActiveGroups);
    }

    customerGroupTypeEnumToEndpoint(customerGroupType: CustomerGroupTypeEnum) {
        switch (customerGroupType) {
            case CustomerGroupTypeEnum.FITNESS: return 'fitness';
            case CustomerGroupTypeEnum.CLUB_SPORT: return 'clubsport';
            default: return null;
        }
    }

    onSearchChanged = (searchValue: string) => {
        if (!searchValue || searchValue.length < 3) {
            fitnessStore.setFilteredGroups(fitnessStore.groups);
        }
        else {
            const res = fitnessStore.groups.filter((group) => {
                const groupName: string = group.get('name') || '';
                return groupName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
            })

            fitnessStore.setFilteredGroups(res);
        }
    }

    onClick = (id: string) => {
        const { groupType } = this.props;
        fitnessStore.setSelectedGroupId(id);
        fitnessStore.setSelectedTopMenu(GroupDetailsTopMenuEnum.ACTIVITIES);

        const endpoint = this.customerGroupTypeEnumToEndpoint(groupType);

        navigationStore.push(`/${endpoint}/${id}/activities`);
    }

    getArchivedGroups = () => {
        this.setState({ onlyActiveGroups: false}, () => {
            this.getGroups();
        })
    }

    componentWillUnmount() {
        fitnessStore.setSelectedGroupId(null);
    }

    createAddGroupItem(index: number) {
        const { groupType } = this.props;
        const endpoint = this.customerGroupTypeEnumToEndpoint(groupType);

        return <SecondaryMenuItem
            onClick={() => navigationStore.push(`/creategroup/${endpoint}`)}
            key={index}
            imageUrl={CreateGroupIcon}
            title='Create group' />
    }

    render() {
        const { onlyActiveGroups } = this.state;
        const groups = fitnessStore.filteredGroups;
        if (!groups) return null;

        let items = groups.map((group: Parse.Object, index: number) => {
            return <SecondaryMenuItem
                onClick={this.onClick}
                key={index}
                imageUrl={group.get('image') ? group.get('image')._url : undefined}
                title={group.get('name')}
                id={group.id}
                subTitle={group['_memberCount'] + ' members'}
                selected={group.id === fitnessStore.selectedGroupId} />
        })

        items.unshift(this.createAddGroupItem(items.length));

        return (
            <SecondaryMenuContainer>
                <SearchInput onChange={this.onSearchChanged} className='mb-30px' placeholder='Search groups' />
                <PrimarySectionTitle text={onlyActiveGroups ? 'Groups' : 'Groups (All)'} noMargin={true} />
                <Separator />

                {items}

                {
                    (onlyActiveGroups && !loadingStore.isLoading) &&
                    <div className='mt-6' style={{ marginLeft: '92px' }}>
                        <a href="#" onClick={this.getArchivedGroups} className='cursor-pointer text-inactive text-xs'>Show archived</a>
                    </div>
                }

            </SecondaryMenuContainer>
        )
    }

}

export default SecondaryMenuGroups;