import { observable, action } from 'mobx';
import LoadPromise from 'helpers/LoadPromise';
import { Pointer, Parse } from 'helpers/Parse';
import Moment from 'moment';

export class BookingStore {

    @observable courts: Array<Parse.Object> = [];
    @observable bookings: Array<Parse.Object> = [];
    @observable quarantines: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.resetCourts();
        this.resetBookings();
    }

    @action
    resetCourts() {
        this.courts = [];
    }

    @action
    resetBookings() {
        this.bookings = [];
    }

    @action
    resetQuarantines() {
        this.quarantines = [];
    }

    @action
    getCourts(venueId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const courtQuery = new Parse.Query('Court');
                courtQuery.equalTo('venue', Pointer('Venue', venueId));
                courtQuery.equalTo('active', true);

                const res = await courtQuery.find();
                if (res) {
                    this.courts = res;
                    resolve(res);
                }
                else reject();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getUserBookings(userId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const bookingQuery = new Parse.Query('Booking');
                bookingQuery.equalTo('booker', Pointer('_User', userId));
                bookingQuery.include('court');
                bookingQuery.descending('date');
                bookingQuery.limit(99999);

                const res = await bookingQuery.find();
                if (res) {
                    this.bookings = res;
                    resolve(res);
                }
                else reject();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getUserQuarantines(userId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const query = new Parse.Query('BookingQuarantine');
                query.equalTo('user', Pointer('_User', userId));
                query.include('quarantinedBy');
                query.limit(99999);

                const res = await query.find();
                if (res) {
                    this.quarantines = res;
                    resolve(res);
                }
                else reject();
            }
            catch(error) {
                reject(error.message);
            }
        })
    }

    @action
    createQuarantine(userId: string, end: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const Quarantine = Parse.Object.extend('BookingQuarantine');
                const quarantine = new Quarantine();

                const userQuery = new Parse.Query('_User');
                const user = await userQuery.get(userId);

                quarantine.set('user', user);
                quarantine.set('start', Moment().toDate());
                quarantine.set('end', Moment(end).toDate());
                quarantine.set('quarantinedBy', Parse.User.current());

                const savedQuarantine = await quarantine.save();
                this.quarantines.push(savedQuarantine);
                resolve(savedQuarantine);
            }
            catch (err) {
                reject(err);
            }
        })
    }

    @action
    deleteQuarantine(id: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const query = new Parse.Query('BookingQuarantine');
                query.equalTo('objectId', id);

                const res = await query.first();
                if (res) {
                    await res.destroy();

                    this.quarantines = this.quarantines.filter((item: any) => {
                        return item.id !== id;
                    })

                    resolve(res);
                }
            }
            catch(error) {
                reject(error.message);
            }
        })
    }


    @action
    getBookings(courtId: string, date: Date, activeOnly: boolean) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const bookingQuery = new Parse.Query('Booking');
                bookingQuery.equalTo('court', Pointer('Court', courtId));
                bookingQuery.equalTo('date', Moment(date).format('YYYY-MM-DD'))

                bookingQuery.ascending('from');

                bookingQuery.include('booker');

                bookingQuery.limit(99999);

                if (activeOnly) {
                    bookingQuery.notEqualTo('checkedIn', true);
                    bookingQuery.notEqualTo('noShow', true);
                }

                const res = await bookingQuery.find();
                if (res) {
                    this.bookings = res;
                    resolve(res);
                }
                else reject();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    createBooking(courtId: string, bookerId: string, date: Date, from: string, to: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const Booking = Parse.Object.extend('Booking');
                const booking = new Booking();

                const userQuery = new Parse.Query('_User');
                const user = await userQuery.get(bookerId);

                booking.set('court', Pointer('Court', courtId));
                booking.set('booker', user);
                booking.set('date', Moment(date).format('YYYY-MM-DD'));
                booking.set('from', from);
                booking.set('to', to);

                const savedBooking = await booking.save();
                this.bookings.push(savedBooking);
                resolve(savedBooking);
            }
            catch (err) {
                reject(err);
            }
        })
    }

    @action
    deleteBooking(bookingId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                await Parse.Cloud.run('requestDeleteBooking', {
                    bookingID: bookingId
                });

                this.bookings = this.bookings.filter((item: any) => {
                    return item.id !== bookingId;
                })

                resolve();

            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    checkInOut(bookingId: string, checkedIn: boolean) {
        return LoadPromise(async (resolve, reject) => {
            try {
                await Parse.Cloud.run('requestBookingCheckInOut', {
                    bookingID: bookingId,
                    checkedIn: checkedIn
                });

                this.bookings = this.bookings.map((item: Parse.Object) => {
                    if (item.id === bookingId) {
                        item.set('checkedIn', checkedIn);
                    }

                    return item;
                })

                resolve();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }


    @action
    noShowInOut(bookingId: string, noShow: boolean) {
        return LoadPromise(async (resolve, reject) => {
            try {
                await Parse.Cloud.run('requestBookingNoShow', {
                    bookingID: bookingId,
                    noShow: noShow
                });

                this.bookings = this.bookings.map((item: Parse.Object) => {
                    if (item.id === bookingId) {
                        item.set('noShow', noShow);
                    }

                    return item;
                })

                resolve();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }


}

export const bookingStore = new BookingStore();