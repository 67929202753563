import { Parse } from 'helpers/Parse';

export function filterRecurringActivitiesByHost(activities: Array<Parse.Object>, searchValue: string) {
    const filtered = activities.filter((activity) => {
        const data = activity.get('recurringGameData');
        const user = data.get('host');
        if (user) {
            const name: string = user.get('name') || '' as string;
            return name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
        }
        return false;
    })

    return filtered;
}

export function filterRecurringActivitiesByTitle(activities: Array<Parse.Object>, searchValue: string) {
    const filtered = activities.filter((activity) => {
        const data = activity.get('recurringGameData');
        const title = data.get('customTitle');
        if (title) {
            return title.toLowerCase().indexOf(searchValue.toLocaleLowerCase()) !== -1;
        }
        return false;
    })

    return filtered;
}