import * as React from 'react';
import { observer } from 'mobx-react';
import { Parse } from 'helpers/Parse';
import ActionButton from 'components/ActionButton';

import './style.css';
import { venueStore, navigationStore } from 'stores';

interface Props {
    venue: Parse.Object,
    onClick: Function,
    selected?: boolean
}

@observer class Venue extends React.Component<Props> {

    venueClicked() {
        const { onClick, venue } = this.props;
        onClick(venue);
    }

    onClick = () => {
        const { onClick, venue } = this.props;
        onClick(venue);
    }

    toggleActive = (e: React.MouseEvent, active: boolean) => {
        e.stopPropagation();
        const { venue } = this.props;
        console.log(venue.id);
        venueStore.toggleActive(venue.id, active);
    }

    render() {
        const { selected, venue } = this.props;
        const isActive = venue.get('active');

        let className = "Venue";
        if (selected) {
            className += " Venue-selected";
        }

        if (!isActive) {
            className += " Venue-disabled";
        }

        return (
            <div onClick={this.onClick} className={`${className} flex justify-between pl-25px pr-25px pt-25px pb-25px`}>
                <div>
                    <h2 className={`${isActive ? 'text-darkest' : 'text-inactive'} font-medium text-15px`}>
                        {venue.get('name')} {venue.get('group') ? `(${venue.get('group').get('name')})` : ''}
                    </h2>
                </div>
                <div className='text-right'>
                    {
                        !isActive &&
                        <ActionButton width='narrow' style={{background: '#ff6e6e'}} text='Not active' onClick={(e: any) => this.toggleActive(e, true)} />
                    }

                    {
                        isActive &&
                        <ActionButton width='narrow' primary={true} text='Active' onClick={(e: any) => this.toggleActive(e, false)} />
                    }

                    {
                        <ActionButton className='ml-2' width='narrow' text='Edit' onClick={(e: any) => navigationStore.push(`/venues/create/${venue.id}`)} />
                    }

                </div>
            </div>
        )
    }

}

export default Venue;