import * as React from 'react';
import { observer } from 'mobx-react';
import SearchInput from 'components/Forms/SearchInput';
import { FormState, FieldState } from 'formstate';
import Dropdown from 'components/Forms/Dropdown';
import _ from 'lodash';

import './style.css';

interface OptionInterface {
    value: string,
    name: string,
    filterFunc: Function
}

interface Props {
    onClearFiltered: Function,
    options: Array<OptionInterface>,
    placeholder?: string
}

@observer class SearchCombo extends React.Component<Props> {

    form = new FormState({
        selectedField: new FieldState('')
    })

    onChange = (searchValue: any) => {
        const { options, onClearFiltered } = this.props;

        if (!searchValue || searchValue.length < 3) {
            onClearFiltered();
        }
        else {
            const field = this.form.$.selectedField.$;
            const opt = _.find(options, { value: field }) as OptionInterface;

            opt.filterFunc(searchValue);
        }
    }

    render() {
        const { options, placeholder } = this.props;

        const opts = options.map((opt) => {
            return {
                value: opt.value,
                name: opt.name
            }
        })

        return (
            <div className='flex'>
                <div style={{ width: '110px' }} className='mr-2'>
                    <Dropdown
                        skipSort={true}
                        className='SearchCombo-Dropdown py-2'
                        fieldState={this.form.$.selectedField}
                        options={opts} />
                </div>
                <SearchInput onChange={this.onChange} placeholder={placeholder} />
            </div>
        )
    }

}

export default SearchCombo;