import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';
import { CustomerGroupVisibilityEnum } from 'stores';

interface Props {
    fieldState: FieldState<any>,
    className?: string,
    disabled?: boolean
}

@observer class SelectGroupVisibility extends React.Component<Props> {

    render() {
        const { fieldState, className, disabled } = this.props;

        const opts = [
            {
                value: CustomerGroupVisibilityEnum.CLOSED,
                name: 'Closed (invite only)'
            },
            {
                value: CustomerGroupVisibilityEnum.OPEN,
                name: 'Open (everyone can join)'
            },
        ]

        return (
            <Dropdown
                className={`Dropdown-light ${className || ''}`}
                fieldState={fieldState}
                skipSort={true}
                options={opts}
                disabled={disabled}
                />
        )
    }

}

export default SelectGroupVisibility;