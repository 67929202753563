import * as React from 'react';
import { observer } from 'mobx-react';

import './style.css';

interface Props {
    selected?: boolean,
    // group: Parse.Object,
    onClick: Function,
    id?: string,
    imageUrl?: string,
    title?: string,
    subTitle?: string
}

@observer class SecondaryMenuItem extends React.Component<Props> {

    render() {
        const { selected, imageUrl, title, id, subTitle, onClick } = this.props;

        return (
            <div onClick={(e) => onClick(id)} className={`py-12px flex items-center cursor-pointer ${selected ? 'SecondaryMenuItem-active rounded' : 'SecondaryMenuItem-inactive'}`}>

                {
                    imageUrl &&
                    <img src={imageUrl} alt='group' className='mx-16px' />
                }

                {
                    !imageUrl &&
                    <div className={`SecondaryMenuItem-placeholder mx-16px ${selected ? 'SecondaryMenuItem-placeholder-selected' : ''}`}>
                        {title && title.substr(0, 1)}
                    </div>
                }

                <div>
                    {
                        title &&
                        <p className='text-white font-medium'>
                            {/* {group.get('name')} */}
                            {title}
                        </p>
                    }

                    {
                        subTitle &&
                        <p className='text-inactive'>
                            {/* {group['_memberCount']} members */}
                            {subTitle}
                        </p>
                    }

                </div>
            </div>
        )
    }

}

export default SecondaryMenuItem;