import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
    children: React.ReactNode
}

@observer class SecondaryMenuContainer extends React.Component<Props> {

    render() {
        const { children } = this.props;

        return (
            <div style={{ width: '336px' }} >
                {children}
            </div>
        )
    }

}

export default SecondaryMenuContainer;