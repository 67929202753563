import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';

interface Props {
    fieldState: FieldState<any>,
    className?: string
}

@observer class SelectSkillLevel extends React.Component<Props> {

    render() {
        const { fieldState, className } = this.props;

        const opts = [
            {
                value: 0,
                name: "Optional"
            },
            {
                value: 100,
                name: "Beginner"
            },
            {
                value: 200,
                name: "Intermediate"
            },
            {
                value: 300,
                name: "Advanced"
            },
            {
                value: 400,
                name: "Excellent"
            }
        ]

        return (
            <Dropdown
                className={`Dropdown-light ${className || ''}`}
                fieldState={fieldState}
                type='number'
                skipSort={true}
                options={opts}
            />
        )
    }

}

export default SelectSkillLevel;