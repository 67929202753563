import * as React from 'react';
import { observer } from 'mobx-react';

import './style.css';

interface Props {
    title: string | React.ReactNode,
    children: React.ReactNode
    hideSeparator?: boolean,
    titleWidth?: string
}

@observer class SectionItem extends React.Component<Props> {

    render() {
        const { title, children, hideSeparator, titleWidth } = this.props;

        return (
            <div className='relative'>
                <div className='SectionItem flex w-full'>
                    <div className='SectionItem-title text-left' style={{ minWidth: titleWidth || '300px' }}>
                        {title}
                    </div>
                    <div className='w-full my-2'>
                        {children}
                    </div>
                </div>

                {
                    !hideSeparator &&
                    <div className='SectionItem-separator w-full' />
                }

            </div>
        )
    }

}

export default SectionItem;