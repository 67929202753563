import * as React from 'react';
import { observer } from 'mobx-react';
import { authStore, venueStore, navigationStore, loadingStore } from 'stores';
import GoogleMaps from 'components/GoogleMaps';
import Venue from './Venue';
import ActionButton from 'components/ActionButton';

interface State {
    selectedVenue: any,
    showAll: boolean
}

@observer class VenuesPage extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            selectedVenue: {},
            showAll: false
        }
    }

    componentDidMount() {
        const customerId = authStore.customer.id;
        venueStore.getVenues(customerId, true);
    }

    showInactive = () => {
        const customerId = authStore.customer.id;
        venueStore.getVenues(customerId, false);

        this.setState({
            showAll: true
        });
    }

    onVenueClicked = (venue: Parse.Object) => {
        this.setState({
            selectedVenue: venue
        });
    }


    render() {
        const venues = venueStore.venues || [];
        const { selectedVenue, showAll } = this.state;

        return (
            <div className='w-full mb-20'>
                <div className='text-right mb-6'>
                    <ActionButton width='wide' primary={true} text='Create venue' onClick={() => navigationStore.push('/venues/create')} />
                </div>

                <div className='w-full bg-white rounded p-2'>
                    <GoogleMaps selectedVenue={selectedVenue} venues={venues} />
                </div>


                <div className='Venues bg-white rounded mt-4 relative'>
                    {
                        venues.map((venue, index) => {
                            return (
                                <Venue
                                    key={index}
                                    venue={venue}
                                    onClick={this.onVenueClicked}
                                    selected={selectedVenue && selectedVenue.id === venue.id}
                                />
                            )
                        })
                    }
                </div>

                {
                    (!loadingStore.isLoading && !showAll) &&
                    <div className='text-center mb-6 mt-12'>
                        <ActionButton width='narrow' text='Show inactive' onClick={() => this.showInactive()} />
                    </div>
                }

            </div>

        )
    }

}

export default VenuesPage;