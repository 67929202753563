import * as React from 'react';
import { observer } from 'mobx-react';
import BackButton from 'components/BackButton';
import ActionButton from 'components/ActionButton';
import Separator from 'components/Separator';
import Section from '../Section';
import SectionItem from '../SectionItem';
import Input from '../Input';
import { FormState, FieldState } from 'formstate';
// import SelectGroupType from './SelectGroupType';
import UploadFile from '../UploadFile';
import Form from '../Form';
import { authStore, CreateGroupInterface, CustomerGroupTypeEnum, CustomerGroupVisibilityEnum } from 'stores';

import './style.css';
import SelectGroupVisibility from './SelectGroupVisibility';

export interface EditGroupDataInterface {
    name: string,
    groupImageUrl: string | null,
    coverImageUrl: string | null,
    customerGroupType: CustomerGroupTypeEnum,
    type: CustomerGroupVisibilityEnum
}

interface Props {
    onSubmit: (opts: CreateGroupInterface) => void,
    editData?: EditGroupDataInterface,
    GroupId?: string,
    groupType?: CustomerGroupTypeEnum
}

interface State {
    coverImageSrc: string | null,
    coverImageBase64String: string | null,
    groupImageSrc: string | null,
    groupImageBase64String: string | null,
    shouldDeleteCoverImage?: boolean,
    shouldDeleteGroupImage?: boolean,
}

@observer class GroupForm extends React.Component<Props, State> {

    form = new FormState({
        id: new FieldState(null),
        name: new FieldState(''),
        groupType: new FieldState<CustomerGroupTypeEnum>(CustomerGroupTypeEnum.FITNESS),
        groupVisibility: new FieldState<CustomerGroupVisibilityEnum>(CustomerGroupVisibilityEnum.CLOSED)
    })

    constructor(props: Props) {
        super(props);
        const form = this.form.$;

        if (props.groupType) {
            form.groupType.reset(props.groupType);
        }

        let coverImageSrc = null;
        let groupImageSrc = null;

        if (props.editData) {
            const data = props.editData;

            form.name.reset(data.name);
            form.groupType.reset(data.customerGroupType);
            form.groupVisibility.reset(data.type);

            coverImageSrc = props.editData.coverImageUrl;
            groupImageSrc = props.editData.groupImageUrl;
        }

        this.state = {
            coverImageSrc: coverImageSrc,
            groupImageSrc: groupImageSrc,
            coverImageBase64String: null,
            groupImageBase64String: null,
            shouldDeleteCoverImage: false,
            shouldDeleteGroupImage: false
        }
    }

    onSubmit = () => {
        const { coverImageBase64String, groupImageBase64String, shouldDeleteCoverImage, shouldDeleteGroupImage } = this.state;
        const { onSubmit } = this.props;
        const form = this.form.$;

        const opts: CreateGroupInterface = {
            name: form.name.$,
            type: form.groupVisibility.$,
            customerAffiliationId: authStore.customer.id,
            customerGroupType: form.groupType.$!,
            shouldDeleteCoverImage: shouldDeleteCoverImage,
            shouldDeleteGroupImage: shouldDeleteGroupImage
        };

        if (coverImageBase64String) {
            opts.coverImageBase64String = coverImageBase64String
        }

        if (groupImageBase64String) {
            opts.groupImageBase64String = groupImageBase64String;
        }

        onSubmit(opts);
    }

    getBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as any);
            reader.onerror = error => reject(error);
        });
    }

    submitCoverImage = async (image: File) => {
        const img = await this.getBase64(image) as string;

        this.setState({
            coverImageSrc: img,
            coverImageBase64String: img,
            shouldDeleteCoverImage: false
        })

    }

    submitGroupImage = async (image: File) => {
        const img = await this.getBase64(image) as string;

        this.setState({
            groupImageSrc: img,
            groupImageBase64String: img,
            shouldDeleteGroupImage: false
        })

    }

    deleteCoverImage = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            coverImageSrc: null,
            coverImageBase64String: null,
            shouldDeleteCoverImage: true
        })
    }

    deleteGroupImage = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            groupImageSrc: null,
            groupImageBase64String: null,
            shouldDeleteGroupImage: true
        })
    }

    render() {
        const { coverImageSrc, groupImageSrc } = this.state;
        const { editData } = this.props;
        const form = this.form.$;

        return (
            <div className='GroupForm w-full'>
                <Form onValidSubmit={this.onSubmit} formState={this.form}>
                    <div className='flex'>
                        <BackButton />

                        <div className="text-right w-full">
                            <ActionButton
                                primary={true}
                                width='wide'
                                type='submit'
                                text={editData ? 'Save changes' : 'Save group'} />
                        </div>
                    </div>

                    <Separator />

                    <div className='mx-auto mt-5' style={{ maxWidth: '600px' }}>
                        <Section secondaryClassName='pt-6'>
                            <div className="GroupForm-header mb-8">
                                <UploadFile allowedFileTypes={['png', 'jpg', 'jpeg']} onSubmit={this.submitCoverImage} >
                                    <div className="GroupForm-Cover flex items-center rounded cursor-pointer hover:bg-grey-light">

                                        {
                                            coverImageSrc &&
                                            <>
                                                <div className='absolute' style={{ marginTop: '-125px', marginLeft: '10px' }}>
                                                    <div onClick={this.deleteCoverImage} className='p-2 bg-white rounded mt-4 opacity-50 hover:opacity-100 hover:bg-red-lighter'>
                                                        <div className='GroupForm-trashcan' />
                                                    </div>
                                                </div>

                                                <img src={coverImageSrc} alt="Cover" />
                                            </>
                                        }

                                        {
                                            !coverImageSrc &&
                                            <div className='GroupForm-Cover-placeholder' />
                                        }
                                    </div>
                                </UploadFile>

                                <UploadFile allowedFileTypes={['png', 'jpg', 'jpeg']} onSubmit={this.submitGroupImage}>
                                    <div className="GroupForm-logo flex items-center cursor-pointer hover:bg-grey-light">

                                        {
                                            groupImageSrc &&
                                            <>
                                                <div className='absolute' style={{ marginTop: '10px', marginLeft: '-12px' }}>
                                                    <div onClick={this.deleteGroupImage} className='p-2 bg-white rounded mt-4 opacity-50 hover:opacity-100 hover:bg-red-lighter'>
                                                        <div className='GroupForm-trashcan' />
                                                    </div>
                                                </div>

                                                <img src={groupImageSrc} alt="Group" />
                                            </>
                                        }

                                        {
                                            !groupImageSrc &&
                                            <div className="GroupForm-logo-placeholder" />
                                        }
                                    </div>
                                </UploadFile>
                            </div>


                            <SectionItem title='Name of group' titleWidth='150px' hideSeparator={true}>
                                <Input fieldState={form.name} />
                            </SectionItem>

                            <SectionItem title='Visibility' titleWidth='150px' hideSeparator={true}>
                                <SelectGroupVisibility fieldState={form.groupVisibility} />
                            </SectionItem>

                        </Section>
                    </div>
                </Form>
            </div >

        )
    }
}

export default GroupForm;