import * as React from 'react';
import { observer } from 'mobx-react';
import { navigationStore, groupMemberStore, loadingStore, fitnessStore, GroupDetailsTopMenuEnum } from 'stores';
import { CellInfo } from 'react-table';
import AvatarCell from 'components/Table/AvatarCell';
import Table from 'components/Table';
import SelectMemberStatus from 'components/SelectMemberStatus';
import Separator from 'components/Separator';
import SearchGroupMember from 'components/SearchGroupMember';
import _ from 'lodash';
import TablePlaceholder from 'components/TablePlaceholder';

import NoMembersImage from './NoMembers.svg';
import ActionButton from 'components/ActionButton';

interface State {
    selectedIds: Array<string>
}

@observer class GroupMembersPage extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            selectedIds: []
        }
    }

    getGroupIdByParams() {
        return this.props.match.params.groupId;
    }

    componentDidMount() {
        groupMemberStore.resetMembers();
        fitnessStore.setSelectedTopMenu(GroupDetailsTopMenuEnum.MEMBERS);
    }

    componentWillUnmount() {
        groupMemberStore.resetMembers();
    }

    onRowClicked = (groupMember: Parse.Object) => {
        const user = groupMember.get('user');
        navigationStore.push(`/memberdetails/${user.id}`);
    }

    onSelectedChanged = (selectedIds: Array<string>) => {
        this.setState({
            selectedIds: selectedIds
        })
    }

    deleteSelectedMembers = () => {
        const { selectedIds } = this.state;

        if (window.confirm(`Are you sure you want to remove the ${selectedIds.length} selected member(s)? This cannot be undone.`)) {
            const groupId = this.getGroupIdByParams();
            const userIds = selectedIds.map((memberId) => {
                const member = _.find(groupMemberStore.members, { id: memberId });
                if (member) {
                    return member.get('user').id;
                }
                else return null;
            })

            groupMemberStore.deleteGroupMembers(groupId, userIds);
        }
    }

    showAllMembers = () => {
        if (window.confirm('Are you sure you want to load all members? This can be slow.')) {
            groupMemberStore.getMembers(this.getGroupIdByParams());
        }
    }

    render() {
        const { selectedIds } = this.state;
        const data = groupMemberStore.members || [];

        const columns = [
            {
                Header: 'Name',
                Cell: (cellInfo: CellInfo) => {
                    const row = cellInfo.original as Parse.Object;
                    const user = row.get('user');
                    const name = user.get('name') || 'No name';
                    const avatar = user.get('avatar') ? user.get('avatar')._url : null;

                    return <AvatarCell
                        imageUrl={avatar}
                        title={name}
                    />
                },
                id: 'name',
            },
            {
                Header: 'Email',
                id: 'email',
                accessor: (d: any) => d.get('user').get('username')
            },
            {
                Header: 'Status',
                Cell: (cellInfo: CellInfo) => {
                    const row = cellInfo.original as Parse.Object;

                    return <SelectMemberStatus
                        memberStatus={row.get('memberStatus')}
                        memberId={row.id}
                    />

                }
            }
        ]

        return (
            <div className='w-full'>
                <div className='mt-5'>
                    <SearchGroupMember groupId={this.getGroupIdByParams()} />
                    <Separator className='mt-5 mb-5' />
                    <div className='text-right mb-2'>
                        {
                            selectedIds.length === 0 &&
                            <>&nbsp;</>
                        }

                        {
                            selectedIds.length > 0 &&
                            <ActionButton 
                                width='narrow'
                                onClick={this.deleteSelectedMembers}
                                text={`Delete ${selectedIds.length} member(s)`}
                            />
                        }
                    </div>

                    {
                        data.length === 0 &&
                        <div className='w-full'>
                            <TablePlaceholder
                                imageSrc={NoMembersImage}
                            />
                            {
                                !loadingStore.isLoading &&
                                <div className='text-center'>
                                    <button className=' mt-4 text-white p-2 border rounded' onClick={this.showAllMembers}>Show all members</button>
                                </div>
                            }

                        </div>
                    }

                    {
                        data.length > 0 &&
                        <Table
                            onSelectedChanged={this.onSelectedChanged}
                            selectable={true}
                            onRowClicked={this.onRowClicked}
                            condensed={true}
                            data={data}
                            columns={columns} />
                    }

                </div>
            </div>
        )
    }

}

export default GroupMembersPage;