import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';
import { activities } from 'helpers/ActivitiesHelper';

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectActivity extends React.Component<Props> {

    render() {
        const { fieldState } = this.props;

        const opts = [];

        for(const key in activities) {
            opts.push({
                value: key,
                name: activities[key]
            })
        }

        return (
            <Dropdown
                placeholderText='Select activity'
                className='Dropdown-light'
                fieldState={fieldState}
                options={opts}
                />
        )
    }

}

export default SelectActivity;