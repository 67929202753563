import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';

import './style.css';
import ThemeEnum from 'enums/ThemeEnum';

interface Props {
    id?: string,
    fieldState?: FieldState<any>,
    type?: 'text' | 'password' | 'number',
    theme?: ThemeEnum,
    textArea?: boolean,
    readOnlyValue?: string,
    placeholder?: string
}

@observer class Input extends React.Component<Props> {

    onChange(e: React.ChangeEvent<HTMLInputElement>) {
        const { fieldState, type } = this.props;

        let val: string | number = e.target.value;

        // This is custom behavior, to allow HTML elements with input type=text to behave as numbers
        // We want to use input type=text as numbers, since the browser behavior is more consistent.
        if (type === 'number' && !isNaN(val as any)) {
            val = Number(val);
        }

        fieldState!.onChange(val);
    }

    render() {
        const { id, fieldState, type, theme, textArea, readOnlyValue, placeholder } = this.props;

        let themeClass = 'Input-light'; // Default is light

        if (theme === ThemeEnum.DARK) {
            themeClass = 'Input-dark';
        }

        return (
            <>
                {
                    (fieldState && fieldState.error) &&
                    <span className='text-warning text-xs'>* {fieldState.error}</span>
                }

                <div className={`w-full Input ${themeClass} ${fieldState && fieldState.error && 'Input-error'}`}>
                    {
                        (!textArea && readOnlyValue) &&
                        <input
                            className='w-full leading-normal'
                            value={readOnlyValue}
                            disabled={true}
                        />
                    }

                    {
                        (!textArea && !readOnlyValue) &&
                        <input
                            className='w-full leading-normal'
                            id={id} type={type === 'number' ? 'text' : type} // If input is number, appear to be text
                            value={!fieldState || fieldState!.value === null ? '' : fieldState!.value}
                            onChange={(e) => this.onChange(e)}
                            placeholder={placeholder}
                        />
                    }

                    {
                        (textArea && !readOnlyValue) &&
                        <textarea
                            style={{ height: '113px' }}
                            className='w-full leading-normal'
                            id={id}
                            value={!fieldState || fieldState!.value === null ? '' : fieldState!.value}
                            onChange={(e) => fieldState!.onChange(e.target.value)}
                            placeholder={placeholder}
                        />
                    }

                    {
                        (textArea && readOnlyValue) &&
                        <textarea
                            style={{ height: '113px' }}
                            className='w-full leading-normal'
                            value={readOnlyValue}
                            disabled={true}
                        />
                    }
                </div>
            </>


        )
    }

}

export default Input;