import * as React from 'react';
import { observer } from 'mobx-react';
import Table from 'components/Table';
import SectionTitle from 'components/SectionTitle';
import ActionButton from 'components/ActionButton';
import { CsvDataService } from 'helpers/CsvDataService';
import Parse from 'parse';
import Moment from 'moment';
import _ from 'lodash';
import Popup from 'reactjs-popup';
import Form from 'components/Forms/Form';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import DateInput from 'components/Forms/DateInput';
import { FieldState, FormState } from 'formstate';
import { required } from 'helpers/FormValidation';
import { bookingStore, toastStore } from 'stores';
// import { bookingStore } from 'stores';

interface Props {
    bookings: Array<Parse.Object>
}

interface State {
    groupNoShows: boolean
}

const groupBookings = (bookings: Array<Parse.Object>) => {
    let data: Array<any> = [];
    bookings.forEach((booking) => {
        const entity = _.find(data, { id: booking.get('booker').id });
        if (entity) {
            entity.noShows = entity.noShows + 1;
        }
        else {
            data.push({
                id: booking.get('booker').id,
                name: booking.get('booker').get('name'),
                email: booking.get('booker').get('username'),
                noShows: 1
            })
        }
    })

    // // ID not needed after grouping
    // data.forEach((item) => {
    //     delete item['id'];
    // })

    data = _.orderBy(data, 'noShows', 'desc');
    return data;
}

class GroupNoShows extends React.Component<Props> {

    cancelButtonRef: any = React.createRef();

    render() {
        const { bookings } = this.props;
        const data = groupBookings(bookings);
        const columns = [
            {
                Header: 'User',
                id: 'name',
                accessor: (d: any) => {
                    const open = (e: React.MouseEvent) => {
                        e.preventDefault();
                        window.open(`/memberdetails/${d.id}`)
                    }

                    return (
                        <a onClick={open} href=" ">
                            {d.name}
                        </a>
                    )
                }
            },
            {
                Header: 'Email',
                accessor: 'email'
            },
            {
                Header: 'No shows',
                accessor: 'noShows'
            },
            {
                Header: 'Action',
                id: 'action',
                accessor: (d: any) => {

                    const form = new FormState({
                        end: new FieldState(null).validators(required)
                    });

                    const onClose = (closeFunc: Function) => {
                        form.reset();
                        closeFunc();
                    }

                    const onSubmit = async () => {
                        await bookingStore.createQuarantine(d.id, form.$.end.$!);
                        toastStore.addToast({
                            level: 'success',
                            title: 'Success',
                            message: 'Quarantine created'
                        })
                        this.cancelButtonRef.current.props.onClick(); // To close popup
                    }

                    return (
                        <Popup
                            trigger={<ActionButton width='narrow' text='Quarantine' />}
                            modal
                            closeOnDocumentClick>

                            {
                                closeFunc => (
                                    <div>
                                        <h2 className='text-left pl-25px pt-20px font-normal'>
                                            Quarantine
                                        </h2>
                                        <Form onValidSubmit={onSubmit} formState={form}>
                                            <Section className='mt-4 mb-4'>
                                                <SectionItem titleWidth='140px' title='Quarantine until'>
                                                    <DateInput fieldState={form.$.end} />
                                                </SectionItem>

                                                <div className='text-right mt-4'>
                                                    <ActionButton onClick={() => onClose(closeFunc)} text='Cancel' width='wrap' className='mr-2' ref={this.cancelButtonRef} />
                                                    <ActionButton type='submit' text='Save' width='narrow' primary={true} />
                                                </div>
                                            </Section>
                                        </Form>
                                    </div>
                                )
                            }
                        </Popup>
                    )

                }
            }
        ]

        return <Table data={data} columns={columns} condensed={true} />
    }

}

class ListNoShows extends React.Component<Props> {

    render() {
        const { bookings } = this.props;

        const columns = [
            {
                Header: 'When',
                id: 'date',
                accessor: (d: Parse.Object) => d.get('date'),
                width: 180
            },
            {
                Header: 'Timeslot',
                id: 'timeslot',
                accessor: (d: Parse.Object) => {
                    const from = Moment(d.get('from'), 'HH:mm').format('h:mm A');
                    const to = Moment(d.get('to'), 'HH:mm').format('h:mm A');
                    return `${from} - ${to}`
                }
            },
            {
                Header: 'User',
                id: 'name',
                accessor: (d: Parse.Object) => d.get('booker').get('name')
            },
            {
                Header: 'Email',
                id: 'email',
                accessor: (d: Parse.Object) => d.get('booker').get('username')
            },
            {
                Header: 'Court',
                id: 'court',
                accessor: (d: Parse.Object) => d.get('court').get('name')
            },
        ]

        return (
            <Table
                data={bookings}
                condensed={true}
                columns={columns}
            />
        )

    }
}

@observer class NoShows extends React.Component<Props, State> {

    state = { groupNoShows: false }

    createVSV = () => {
        const { bookings } = this.props;
        const { groupNoShows } = this.state;

        let data: Array<any> = [];
        if (groupNoShows) {
            data = groupBookings(bookings);
        }
        else {
            data = bookings.map((booking) => {
                return {
                    date: booking.get('date'),
                    timslot: `${Moment(booking.get('from'), 'HH:mm').format('h:mm A')} - ${Moment(booking.get('to'), 'HH:mm').format('h:mm A')}`,
                    name: booking.get('booker').get('name'),
                    email: booking.get('booker').get('username'),
                    court: booking.get('court').get('name')
                }
            });
        }

        CsvDataService.exportToCsv('noshows.csv', data);
    }

    toggleGroupNoShows = () => {
        this.setState({ groupNoShows: !this.state.groupNoShows });
    }

    render() {
        const { bookings } = this.props;
        const { groupNoShows } = this.state;

        return (
            <div className='w-full pt-8'>
                <SectionTitle title='No Shows' />
                {!groupNoShows && <ListNoShows bookings={bookings} />}
                {groupNoShows && <GroupNoShows bookings={bookings} />}
                <div className='mt-4'>
                    <ActionButton
                        text={groupNoShows ? 'Show dates' : 'Group by user'}
                        width='narrow'
                        className='mr-2 hide-on-print'
                        onClick={this.toggleGroupNoShows}
                    />
                    <ActionButton
                        text='Export CSV'
                        width='narrow'
                        className='hide-on-print'
                        onClick={this.createVSV}
                    />
                </div>

            </div>
        )
    }
}

export default NoShows;