import * as React from 'react';
import { observer } from 'mobx-react';
import Form from 'components/Forms/Form';
import { FormState, FieldState } from 'formstate';
import Input from 'components/Forms/Input';
import { authStore, navigationStore } from 'stores';
import { Parse } from 'helpers/Parse';
import ThemeEnum from 'enums/ThemeEnum';

// import BackgroundImg from './background.jpg';
import LogoImg from './logo.svg';

import './style.css';
import ActionButton from 'components/ActionButton';
import { required } from 'helpers/FormValidation';
import LoadingIndicator from 'components/LoadingIndicator';

@observer class LoginPage extends React.Component {

    form = new FormState({
        email: new FieldState('').validators(required),
        password: new FieldState('').validators(required)
    })

    constructor(props: any) {
        super(props);

        if (Parse.User.current()) {
            navigationStore.replace('/');
        }
    }

    onSubmit = () => {
        const form = this.form.$;
        const email = form.email.$;
        const password = form.password.$;

        authStore.login(email, password);
    }

    render() {
        const form = this.form.$;

        return (
            <div className='LoginPage w-full h-screen flex bg-white'>
                <div className='LoginPage-left-background w-1/2'>
                    <img src={LogoImg} alt='ATLETO - Sport together' className='mt-8 ml-8' />
                </div>
                <div className='mx-auto my-auto'>
                    <div className='LoginPage-login-container '>
                        <h1 className='text-42px uppercase font-hairline'>
                            Sign in to <span className='font-semibold'>ADMIN</span>
                        </h1>
                        <p className='text-secondaryAction text-18px mt-5 mb-8'>
                            Enter your details below. 
                        </p>
                        <Form onValidSubmit={this.onSubmit} formState={this.form}>
                            <Input theme={ThemeEnum.LIGHT} id='email' fieldState={form.email} placeholder='E-mail address' />
                            <div className='mt-2'>
                                <Input theme={ThemeEnum.LIGHT} id='password' type='password' fieldState={form.password} placeholder='Password' />
                            </div>

                            <ActionButton type='submit' text='Sign in' width='narrow' primary={true} className='mt-8' />
                        </Form>
                    </div>
                </div>
                <LoadingIndicator />
            </div>

        )
    }

}

export default LoginPage;