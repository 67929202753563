import { Parse, toParseObject } from 'helpers/Parse';
import { navigationStore } from './NavigationStore';
import { observable, action } from 'mobx';
import SaveableStore from './SaveableStore';
import { fitnessStore } from './FitnessStore';
import { mainGroupStore } from './MainGroupStore';
import LoadPromise from 'helpers/LoadPromise';
import { customerRoleStore } from './CustomerRoleStore';
import { sidebarMenuStore } from './SidebarMenuStore';

interface SaveableInterface {
    customer: any
}

export class AuthStore extends SaveableStore<SaveableInterface> {

    @observable customer: any;
    @observable mainGroupId: any;


    constructor() {
        super('AuthStore');
    }

    @action
    async login(username: string, password: string) {
        return LoadPromise(async(resolve, reject) => {
            try {
                await Parse.User.logIn(username, password);
                await this.getCustomer();
                const user = Parse.User.current() as Parse.Object;
                const customerId = this.customer.id;
                await customerRoleStore.getCustomerRoles(customerId, user.id);
                await mainGroupStore.getMainGroupId(customerId);
    
                navigationStore.push('/');
                resolve();
            }
            catch (err) {
                reject(err);
            }
        })

    }

    @action
    async logout() {
        sidebarMenuStore.setVisible(true);
        this.setSaveableData({ customer: null });
        
        await Parse.User.logOut();

        fitnessStore.resetStore();
        mainGroupStore.resetStore();
        navigationStore.resetStore();
        customerRoleStore.resetStore();
        
        navigationStore.push('/login')
    }

    getCustomer() {
        return LoadPromise(async (resolve, reject) => {

            await Parse.Cloud.run('userAdminCheck')
                .then((customer) => {
                    if (customer.id) {
                        this.customer = customer;
                        resolve(customer);
                    }
                    else {
                        reject('User is not administrator')
                    }
                });
        });
    }

    protected setSaveableData(data: SaveableInterface): void {
        this.customer = toParseObject(data.customer, 'Customer');
    }

    protected getSaveableData(): SaveableInterface {
        let data: SaveableInterface = {
            customer: this.customer
        }
        return data;
    }

}

export const authStore = new AuthStore();