import * as React from 'react';
import { observer } from 'mobx-react';
import SectionTitle from 'components/SectionTitle';
import { activityDetailsStore } from 'stores';
import Table from 'components/Table';
import AvatarCell from 'components/Table/AvatarCell';
import { participantStatus } from 'helpers/ActivitiesHelper';

interface Props {
    activityId: string
}

@observer class Participants extends React.Component<Props> {

    componentDidMount() {
        const { activityId } = this.props;
        activityDetailsStore.getParticipants(activityId);
    }

    render() {
        const participants = activityDetailsStore.participants;

        const columns = [
            {
                Header: '',
                id: 'name',
                accessor: (gamePlayer: any) => {
                    const user = gamePlayer.get('user');
                    const imageUrl = user.get('avatar') ? user.get('avatar')._url : null;

                    return <AvatarCell
                        imageUrl={imageUrl}
                        title={user.get('name')}
                    />
                }
            },
            {
                Header: 'Status',
                id: 'action',
                width: 120,
                accessor: (gamePlayer: any) => {
                    const status = gamePlayer.get('playerStatus');

                    let className = 'text-warning font-medium';

                    switch(status) {
                        case 'ATPlayerStatusDeclined':
                            className += ' text-warning';
                            break;
                        case 'ATPlayerStatusInvited':
                            className += ' text-inactive';
                            break;
                        default:
                            className += ' text-accepted';
                            break;
                    }

                    return (
                        <p className={className}>{participantStatus[status]}</p>
                    )
                },
                sortMethod: (a: any, b: any) => {
                    return a.props.children > b.props.children ? 1 : -1;
                }
            },
            {
                Header: 'Checked in',
                id: 'playerCheckedIn',
                width: 150,
                accessor: (gamePlayer: any) => {
                    const checkedIn = gamePlayer.get('playerCheckedIn') || false;

                    if (checkedIn) {
                        return <p>Yes</p>
                    }
                    else {
                        return <p className='text-inactive'>No</p>
                    }
                },
                sortMethod: (a: any, b: any) => {
                    return a.props.children > b.props.children ? 1 : -1;
                }
            }
        ]

        return (
            <div>
                <SectionTitle title='Participants' skipMarginBottom={true} />

                <Table
                    data={participants}
                    columns={columns}
                    condensed={true}
                />
            </div>
        )
    }

}

export default Participants;