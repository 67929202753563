import * as React from 'react';
import { observer } from 'mobx-react';
import SidebarMenu from 'components/SidebarMenu';

interface Props {
    children: React.ReactNode
}

@observer class Body extends React.Component<Props> {

    render() {
        const { children } = this.props;

        return (
            <div className='max-w-dashboard' style={{ margin: '0 auto' }}>
                <div className='flex lg:mx-60px mt-30px mb-12'>
                    <SidebarMenu />
                    {children}
                </div>
            </div>
        )

    }

}

export default Body;