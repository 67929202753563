import * as React from 'react';
import { observer } from 'mobx-react';
import InputModal from '../InputModal';
import { FieldState, FormState } from 'formstate';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import Switch from 'components/Forms/Switch';
import { authStore, hostStore } from 'stores';
import _ from 'lodash';

interface Props {
    hostsChanged: Function
}

interface State {
    values: string
}

@observer class SelectHosts extends React.Component<Props, State> {

    form = new FormState({}) as any;

    ANY_HOST_PLACEHOLDER: string = 'All';

    constructor(props: any) {
        super(props);

        this.state = {
            values: this.ANY_HOST_PLACEHOLDER
        }

    }

    async componentDidMount() {
        await hostStore.getHosts(authStore.customer.id);

        hostStore.hosts.forEach((host) => {
            this.form.$[host.id] = new FieldState(false);
        });
    }

    updateSelectedHosts = () => {
        const { hostsChanged } = this.props;
        const values = [];
        const form = this.form.$;

        for (let key in form) {
            if (form[key].value === true) {
                const host = _.find(hostStore.hosts, { id: key });
                if (host) {
                    values.push(host.get('name'));
                }
            }
        }

        hostsChanged(this.form);

        this.setState({
            values: values.join(', ') || this.ANY_HOST_PLACEHOLDER
        })
    }

    render() {
        const form = this.form.$;
        if (!form) return null;
        const { values } = this.state;

        const items = [];
        for (let key in form) {
            const item = form[key];
            const host = _.find(hostStore.hosts, { id: key });

            items.push(
                <SectionItem title={host ? host.get('name') : 'No name'} key={key}>
                    <Switch fieldState={item} className='justify-end' />
                </SectionItem>
            )
        }

        return (
            <InputModal
                label='Host(s)'
                id='hosts'
                value={values}
                onClose={this.updateSelectedHosts}
                onClick={() => null}>

                <Section secondaryClassName='overflow-auto' secondaryStyle={{ maxHeight: '80vh' }}>
                    {items}
                </Section>

            </InputModal>
        )
    }

}

export default SelectHosts;