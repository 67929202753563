import * as React from 'react';
import { observer } from 'mobx-react';

import './style.css';

interface Props {
    checked?: boolean,
    onChange: Function,
    id: string
}

@observer class SelectCell extends React.Component<Props> {

    onChange = (data: any) => {
        const { onChange, id, checked } = this.props;
        onChange(id, !checked);
    }

    divClicked = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    render() {
        const { checked } = this.props;

        return (
            <span onClick={this.divClicked} className='SelectCell flex items-center'>
                <input style={{ width: '20px' }} name='selected' type='checkbox' checked={checked} onChange={this.onChange} />
            </span>

        )
    }

}

export default SelectCell;