import { action } from 'mobx';
import { Parse } from 'helpers/Parse';
import LoadPromise from 'helpers/LoadPromise';

export class ActivityStore {

    @action
    hostActivity(opts: Object) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const activity = await Parse.Cloud.run('requestHostGame', opts);
                resolve(activity);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

}

export const activityStore = new ActivityStore();