import * as React from 'react';
import { observer } from 'mobx-react';
import Autocomplete from 'react-autocomplete';
import { FieldState } from 'formstate';
import { groupMemberStore, authStore } from 'stores';
import Parse from 'parse';
import _ from 'lodash';

interface Props {
    fieldState: FieldState<any>
}

interface State {
    value: string
}

@observer class AutocompleteInput extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            value: ''
        }
    }

    onChange = (event: any, value: string) => {
        if (value.length > 2) {
            groupMemberStore.getMembers(authStore.customer.id, true, { nameQuery: value });
        }
        else {
            groupMemberStore.resetMembers();
        }

        this.setState({
            value: value
        })
    }

    onSelect = (value: string, item: Parse.Object, context?: any) => {
        const { fieldState } = this.props;
        const userId = item.get('user').id;

        this.setState({ value: value })
        fieldState.onChange(userId);
        groupMemberStore.resetMembers();
    }

    render() {
        const { value } = this.state;
        let uniqueMembers = _.uniqBy(groupMemberStore.members, (m) => m.get('user').id) || [];
        uniqueMembers = uniqueMembers.slice(0, 15);

        return (
            <Autocomplete
                wrapperStyle={{ width: '100%', borderRadius: '6px', border: '1px solid #dae1e7' }}
                items={uniqueMembers}
                value={value}
                getItemValue={(item: Parse.Object) => item.get('user').get('name')}
                onChange={this.onChange}
                onSelect={this.onSelect}
                menuStyle={{ position: 'inherit', textAlign: 'left', paddingLeft: '15px', borderTop: '1px solid #dae1e7' }}
                renderItem={(item: Parse.Object, isHighlighted: boolean) => (
                    <div className={`cursor-pointer ${isHighlighted ? 'bg-grey-lightest font-medium' : ''}`} key={item.id} style={{ padding: '5px 0' }}>
                        {item.get('user').get('name')} ({item.get('user').get('username')})
                    </div>
                )} />
        )
    }

}

export default AutocompleteInput;