import { CustomerRoleAccessAreaEnum, customerRoleStore } from 'stores';

export function canAccess(accessArea: CustomerRoleAccessAreaEnum) {
    if (!customerRoleStore.loggedInUserRole || !customerRoleStore.loggedInUserRole.get('accessArea')) {
        return true;
    }

    return customerRoleStore.loggedInUserRole.get('accessArea') === accessArea;
}

export function canOnlyAccess(accessArea: CustomerRoleAccessAreaEnum) {
    if (!customerRoleStore.loggedInUserRole || !customerRoleStore.loggedInUserRole.get('accessArea')) {
        return false;
    }

    return customerRoleStore.loggedInUserRole.get('accessArea') === accessArea;
}