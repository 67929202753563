import * as React from 'react';
import { observer } from 'mobx-react';
import { EditorState } from 'draft-js';
import EditorButton from '../EditorButton';

interface Props {
    editorState: EditorState,
    label: string,
    inlineStyle: string,
    onToggle: (inlineStyle: string) => void,
    position?: 'left' | 'right'
}

@observer class EditorInlineStyleButton extends React.Component<Props> {

    render() {
        const { editorState, inlineStyle } = this.props;
        const currentStyle = editorState.getCurrentInlineStyle();

        return (
            <EditorButton
                active={currentStyle.has(inlineStyle)}
                {...this.props} />
        )
    }

}

export default EditorInlineStyleButton;