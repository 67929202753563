import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import _ from 'lodash';

import './style.css';

export interface DropdownOptionInterface {
    value: any,
    name: string
}

interface Props {
    className?: string,
    fieldState: FieldState<any>,
    placeholderText?: string,
    options: Array<DropdownOptionInterface>,
    skipSort?: boolean
    type?: 'text' | 'number',
    disabled?: boolean
    beforeChange?: Function,
    afterChange?: Function,
    style?: Object
}


@observer class Dropdown extends React.Component<Props> {

    static PLACEHOLDER_VALUE = -1;

    onChange(value: any) {
        const { fieldState, type, beforeChange, afterChange } = this.props;

        // Placeholder value can be string as well as number,
        // so we need to be able to compare "-1" with -1.
        // Therefore the two equals (==) is not a bug!
        if (value === Dropdown.PLACEHOLDER_VALUE) {
            if (beforeChange) beforeChange(null);
            fieldState.onChange(null);
        }
        else {
            if (type === 'number') {
                if (beforeChange) beforeChange(Number(value));
                fieldState.onChange(Number(value));
                if (afterChange) afterChange(Number(value));
            }
            else {
                if (beforeChange) beforeChange(value);
                fieldState.onChange(value);
                if (afterChange) afterChange(value);
            }
        }
    }

    getFormattedOptions = () => {
        const { skipSort, options } = this.props;

        let opts = options;
        if (!skipSort) {
            opts = _.sortBy(options, 'name');
        }

        return opts;
    }

    componentDidMount() {
        const { fieldState, placeholderText } = this.props;
        let value = fieldState.value;

        let opts = this.getFormattedOptions();

        if (!value && placeholderText === undefined && opts.length > 0) {
            value = opts[0].value;
            fieldState.onChange(value);
        }
    }

    render() {
        const { disabled, fieldState, options, placeholderText, className, style } = this.props;

        let opts = this.getFormattedOptions();

        let value = fieldState.value;
        if (!value && placeholderText) {
            value = Dropdown.PLACEHOLDER_VALUE;
        }

        // If placeholder, add first
        if (placeholderText && !_.find(options, { value: Dropdown.PLACEHOLDER_VALUE })) {
            opts.unshift({
                value: -1,
                name: placeholderText
            })
        }

        return (
            <div className={`w-full leading-normal ${className} ${fieldState && fieldState.error && 'Dropdown-error'}`} style={style}>
                <select className="w-full text-white bg-transparent" value={value || undefined} onChange={(e) => this.onChange(e.target.value)} disabled={disabled}>
                    {
                        opts.map((option: DropdownOptionInterface, index: number) => {
                            return <option value={option.value} key={index} style={{ height: '42px', background: 'black', color: 'white' }}>{option.name}</option>
                        })
                    }
                </select>
            </div>

        )
    }

}

export default Dropdown;