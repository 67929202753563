import { observable, action } from 'mobx';
import { Pointer } from 'helpers/Parse';
import * as Parse from 'parse';
import { replaceObjectById } from 'helpers/CollectionHelper';
import LoadPromise from 'helpers/LoadPromise';

export interface UpdateVenueInterface {
    id?: string,
    name: string | null,
    info: string | null,
    lat: number,
    lng: number,
    showBookingsLinkInHomeFeed: boolean,
    hasCourt: boolean,
    dailyBookingLimit: number
}

export class VenueStore {

    @observable venues: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.venues = [];
    }

    @action
    getVenues(customerId: string, activeOnly: boolean, hasCourt?: boolean) {
        return new Promise(async (resolve, reject) => {

            try {
                var venueQuery = new Parse.Query('Venue');
                venueQuery.equalTo('customer', Pointer('Customer', customerId));
                venueQuery.include('group');
                venueQuery.addAscending('name');

                if (activeOnly) {
                    venueQuery.equalTo('active', true);
                }

                if (hasCourt !== undefined) {
                    venueQuery.equalTo('hasCourt', hasCourt);
                }

                const venues = await venueQuery.find();

                this.venues = venues;
                resolve(venues);
            }
            catch (error) {
                reject(error.message);
            }

        });
    }

    @action
    getVenue(id: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const venueQuery = new Parse.Query('Venue');

                const venue = await venueQuery.get(id);
                if (venue) {
                    this.venues = [venue];
                    resolve(venue);
                }

            }
            catch (error) {
                reject(error.message);
            }

        })
    }

    @action
    createVenue(customerId: string, groupId: string, opts: UpdateVenueInterface) {
        return new Promise(async (resolve, reject) => {
            try {
                var Venue = Parse.Object.extend('Venue');
                var venue = new Venue();

                venue.set('group', Pointer('Group', groupId));

                var point = new Parse.GeoPoint({ latitude: opts.lat, longitude: opts.lng });
                venue.set('geoLocation', point);
                venue.set('customer', Pointer('Customer', customerId));
                venue.set('name', opts.name);
                venue.set('info', opts.info);
                venue.set('active', true);
                venue.set('showBookingsLinkInHomeFeed', opts.showBookingsLinkInHomeFeed);
                venue.set('hasCourt', opts.hasCourt);
                venue.set('daily_booking_limit', opts.dailyBookingLimit);

                await venue.save();
                this.venues.push(venue);
                resolve(venue);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    updateVenue(opts: UpdateVenueInterface) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const venueQuery = new Parse.Query('Venue');
                const venue = await venueQuery.get(opts.id!);

                venue.set('name', opts.name);
                venue.set('info', opts.info);
                venue.set('showBookingsLinkInHomeFeed', opts.showBookingsLinkInHomeFeed);
                venue.set('hasCourt', opts.hasCourt);
                venue.set('daily_booking_limit', opts.dailyBookingLimit);

                const point = new Parse.GeoPoint(opts.lat, opts.lng);
                venue.set('geoLocation', point);

                await venue.save();

                resolve();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    toggleActive(venueId: string, active: boolean) {
        return new Promise(async (resolve, reject) => {
            try {
                var venueQuery = new Parse.Query('Venue');
                venueQuery.equalTo("objectId", venueId);

                const venue = await venueQuery.first();
                if (venue) {
                    venue.set('active', active);
                    await venue.save();

                    this.venues = replaceObjectById(this.venues, venue);
                    resolve(venue);
                }
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

}

export const venueStore = new VenueStore();