import * as React from 'react';
import { observer } from 'mobx-react';
import { authStore } from 'stores';
import GoogleMapReact, { ClickEventValue } from 'google-map-react';
import GoogleMapsMarker from './GoogleMapsMarker';

import './style.css';

interface Props {
    selectedVenue?: Parse.Object | null,
    venues?: Array<Parse.Object> | null,
    showMarkerOnClick?: boolean,
    onMarkerPlaced?: (lat: number, lng: number) => void,
    lat?: number | null,
    lng?: number | null
}

interface State {
    isMarkerShown: boolean,
    markerPosition: {lat: number, lng: number} | null
}

@observer class GoogleMaps extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isMarkerShown: false,
            markerPosition: null
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        console.log(nextProps)
        if (!!nextProps.lat && !!nextProps.lng) {
            this.setState({
                isMarkerShown: true,
                markerPosition: {
                    lat: nextProps.lat,
                    lng: nextProps.lng
                }
            })
        }
    }

    getMapCenter() {
        const { venues, selectedVenue } = this.props;
        const { isMarkerShown, markerPosition } = this.state;

        var lat = null;
        var lng = null;

        const customer = authStore.customer;
        var customerLocation = customer.get('geoLocation')

        if (selectedVenue && selectedVenue.id) {
            lat = selectedVenue.get('geoLocation')._latitude;
            lng = selectedVenue.get('geoLocation')._longitude;
        }
        else if (customerLocation) {
            lat = customerLocation.latitude;
            lng = customerLocation.longitude;
        }
        else if (venues && venues.length > 0) {
            lat = venues[0].get('geoLocation')._latitude;
            lng = venues[0].get('geoLocation')._longitude;
        }
        else if (isMarkerShown) {
            lat = markerPosition!.lat;
            lng = markerPosition!.lng;
        }
        else  {
            // New York
            lat = 40.71427;
            lng = -74.00597;
        }

        return {
            lat,
            lng
        }
    }

    onMapClicked = (e: ClickEventValue) => {
        const { showMarkerOnClick, onMarkerPlaced } = this.props;
        if (!showMarkerOnClick) return;

        this.setState({
            isMarkerShown: true,
            markerPosition: { lat: e.lat, lng: e.lng }
        })

        if (onMarkerPlaced) {
            onMarkerPlaced(e.lat, e.lng);
        }
    }

    render() {
        const { venues } = this.props;
        const { isMarkerShown, markerPosition } = this.state;

        const mapCenter = this.getMapCenter();

        return (
            <div className="GoogleMaps">
                <GoogleMapReact
                    resetBoundsOnResize={true}
                    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string }}
                    center={mapCenter}
                    onClick={this.onMapClicked}
                    defaultZoom={14}>

                    {
                        venues && venues.map((venue, index) => {
                            return (
                                <GoogleMapsMarker
                                    key={index}
                                    text={venue.get('name')}
                                    isActive={venue.get('active')}
                                    lat={venue.get('geoLocation')._latitude}
                                    lng={venue.get('geoLocation')._longitude} />
                            );
                        })
                    }

                    {
                        (isMarkerShown && markerPosition) &&
                        <GoogleMapsMarker
                            text='X'
                            isActive={true}
                            lat={markerPosition.lat}
                            lng={markerPosition.lng} />
                    }

                </GoogleMapReact>
            </div>
        )
    }


}

export default GoogleMaps;