import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FormState, FieldState } from 'formstate';
import Section from 'components/Forms/Section';
import { venueStore, authStore, bookingStore, customerRoleStore } from 'stores';
import DateInput from 'components/Forms/DateInput';
import ActionButton from 'components/ActionButton';
import CreateBooking from './CreateBooking';
import Moment from 'moment';
import _ from 'lodash';
import BookingsGroup from './BookingsGroup';
import Parse from 'parse';

interface State {
    showActiveBookingsOnly: boolean
}

@observer class BookingsPage extends React.Component<any, State> {

    state = { showActiveBookingsOnly: true }

    form = new FormState({
        venue: new FieldState(''),
        court: new FieldState(''),
        date: new FieldState(new Date())
    })

    componentDidMount() {
        venueStore.getVenues(authStore.customer.id, true);
    }

    componentWillUnmount() {
        bookingStore.resetStore();
    }

    venueChanged = (venueId: string | number) => {
        bookingStore.resetStore();

        if (venueId !== Dropdown.PLACEHOLDER_VALUE) {
            bookingStore.getCourts(venueId as string);
        }

        this.getBookings();
    }

    courtChanged = (courtId: string | number) => {
        if (Number(courtId) === Dropdown.PLACEHOLDER_VALUE) {
            bookingStore.resetBookings();
        }

        this.getBookings();
    }

    dateChanged = (date: any) => {
        this.getBookings();
    }

    getBookings = (forceAll: boolean = false) => {
        const form = this.form.$;
        const selectedDateIsToday = Moment(form.date.value).isSame(Moment(), 'day');

        if (forceAll) {
            this.setState({ showActiveBookingsOnly: false });
        }
        else {
            this.setState({ showActiveBookingsOnly: selectedDateIsToday });
        }

        if (this.allInputsFilledOut()) {
            bookingStore.getBookings(form.court.value, form.date.value, forceAll ? false : selectedDateIsToday);
        }
    }

    allInputsFilledOut = () => {
        const form = this.form.$;
        return (form.court.value && form.venue.value && form.date.value);
    }

    deleteBooking = (bookingId: string) => {
        if (window.confirm('Are you sure you want to delete this booking?')) {
            bookingStore.deleteBooking(bookingId);
        }
    }

    checkInOut = (bookingId: string, checkedIn: boolean) => {
        bookingStore.checkInOut(bookingId, checkedIn);
    }

    noShowInOut = (bookingId: string, noShow: boolean) => {
        bookingStore.noShowInOut(bookingId, noShow);
    }

    canCreateBooking = (userRole: Parse.Object | null) => {
        // No userRoles means that we are full admins. UserRole is only set if subset of verticals are available, or we have limited access.
        if (!userRole) return true;
        const limitedAccess = userRole!.get('limitedAccess');
        if (!limitedAccess) return true;

        const form = this.form.$;
        const courtId = form.court.$;
        const today = Moment();
        const date = Moment(form.date.$);
        const court = _.find(bookingStore.courts, { id: courtId });
        if (!court) return false;

        const bookingAvailableDaysFromStart = court.get('bookingAvailableDaysFromStart') || 0;
        if (!bookingAvailableDaysFromStart || bookingAvailableDaysFromStart < 1) return true;

        return date.isBefore(today.add(bookingAvailableDaysFromStart - 1, 'days'));
    }

    render() {
        const { showActiveBookingsOnly } = this.state;
        const form = this.form.$;
        const venues = venueStore.venues || [];
        const courts = bookingStore.courts || [];
        const bookings = bookingStore.bookings || [];
        const groupedBookings = _.groupBy(bookings, (b) => `${b.get('from')}-${b.get('to')}`);
        const userRole = customerRoleStore.loggedInUserRole;

        return (
            <div className='w-full'>
                <Section>
                    <div className="flex w-full">
                        <div className='flex-1 my-5 mr-5'>
                            <label>Venue</label>
                            <Dropdown
                                afterChange={this.venueChanged}
                                placeholderText='Select venue'
                                className='Dropdown-light mt-2'
                                fieldState={form.venue}
                                options={venues.map((v) => ({
                                    value: v.id,
                                    name: v.get('name')
                                }))} />
                        </div>

                        <div className='flex-1 my-5 mx-5'>
                            <label>Subvenue</label>
                            <Dropdown
                                afterChange={this.courtChanged}
                                placeholderText='Select subvenue'
                                className='Dropdown-light mt-2'
                                fieldState={form.court}
                                options={courts.map((c) => ({
                                    value: c.id,
                                    name: c.get('name')
                                }))} />
                        </div>

                        <div className='flex-1 my-5 ml-5'>
                            <label>Date</label>
                            <div className='mt-2'>
                                <DateInput afterChange={this.dateChanged} fieldState={form.date} />
                            </div>

                        </div>

                    </div>
                </Section>

                {
                    Object.keys(groupedBookings).map((key, index) => {
                        const splittedTime = key.split('-');
                        const from = Moment(splittedTime[0], 'HH:mm').format('h:mm A');
                        const to = Moment(splittedTime[1], 'HH:mm').format('h:mm A');
                        const totalBookings = Object.keys(groupedBookings[key]).length;
                        const checkedIn = _.filter(groupedBookings[key], (b) => b.get('checkedIn') === true).length;
                        const noShows = _.filter(groupedBookings[key], (b) => b.get('noShow') === true).length;

                        return <BookingsGroup
                            key={index}
                            groupTitle={`${from} - ${to} (Total: ${totalBookings} - Checked In: ${checkedIn} - No Shows: ${noShows})`}
                            bookings={groupedBookings[key]} />
                    })
                }

                {
                    this.allInputsFilledOut() &&
                    <div className="w-full mx-auto mt-8 text-center">

                        {
                            this.canCreateBooking(userRole) &&
                            <CreateBooking
                                venueId={form.venue.$}
                                courtId={form.court.$}
                                date={form.date.$}
                            />
                        }

                        {
                            showActiveBookingsOnly &&
                            <div className='text-center mb-6 mt-12'>
                                <ActionButton width='narrow' text='Show inactive' onClick={() => this.getBookings(true)} />
                            </div>
                        }

                    </div>
                }

            </div>
        )
    }

}

export default BookingsPage;