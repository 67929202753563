import * as React from 'react';
import { observer } from 'mobx-react';
import Table from 'components/Table';
// import Moment from 'moment';
import ActionButton from 'components/ActionButton';
import { bookingStore } from 'stores';
import { CellInfo } from 'react-table';
import _ from 'lodash';

interface Props {
    groupTitle: string
    bookings: Array<Parse.Object>
}

interface State {
    expanded: boolean
}

@observer class BookingsGroup extends React.Component<Props, State> {

    state = { expanded: false }

    deleteBooking = (bookingId: string) => {
        if (window.confirm('Are you sure you want to delete this booking?')) {
            bookingStore.deleteBooking(bookingId);
        }
    }

    checkInOut = (bookingId: string, checkedIn: boolean) => {
        bookingStore.checkInOut(bookingId, checkedIn);
    }

    noShowInOut = (bookingId: string, noShow: boolean) => {
        bookingStore.noShowInOut(bookingId, noShow);
    }

    expandCollapse = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    render() {
        const { bookings, groupTitle } = this.props;
        const { expanded } = this.state;

        return (
            <>
                <div
                    onClick={this.expandCollapse}
                    className={`w-full pl-25px pr-25px rounded p-4 mt-8 cursor-pointer ${expanded ? 'bg-grey-darker' : 'bg-white'}`}>
                    {groupTitle}
                </div>

                {
                    expanded &&
                    <div className='w-full mt-5 mb-16'>
                        <Table
                            condensed={true}
                            data={_.orderBy(bookings, (b) => b.get('booker') ? b.get('booker').get('name') : undefined)}
                            columns={[
                                // {
                                //     Header: 'Timeslot',
                                //     id: 'from',
                                //     width: 200,
                                //     accessor: (d: any) => `${Moment(d.get('from'), 'HH:mm').format('h:mm A')} - ${Moment(d.get('to'), 'HH:mm').format('h:mm A')}`
                                // },
                                {
                                    Header: 'User',
                                    id: 'booker',
                                    accessor: (d: any) => {
                                        const user = d.get('booker');
                                        if (user) {
                                            return user.get('name') || '';
                                        }
                                        else return ''
                                    }
                                },
                                {
                                    Header: 'Status',
                                    id: 'action',
                                    width: 390,
                                    Cell: (cellInfo: CellInfo) => {
                                        const booking = cellInfo.original as Parse.Object;
                                        const checkedIn = booking.get('checkedIn');
                                        const noShow = booking.get('noShow');

                                        return (
                                            <div>
                                                {
                                                    (!checkedIn && !noShow) &&
                                                    <ActionButton width='wrap' text='Delete' onClick={() => this.deleteBooking(booking.id)} />
                                                }

                                                {
                                                    !checkedIn &&
                                                    <ActionButton width='wrap' style={{ background: noShow ? '#ff6e6e' : '#707c8c' }} primary={noShow} text='No-show' onClick={() => this.noShowInOut(booking.id, !noShow)} className='ml-2' />
                                                }

                                                {
                                                    !noShow &&
                                                    <ActionButton width='wrap' primary={checkedIn} text={checkedIn ? 'Checked In' : 'Check in'} onClick={() => this.checkInOut(booking.id, !checkedIn)} className='ml-2' />
                                                }


                                            </div>
                                        )
                                    }
                                }
                            ]}
                        />
                    </div>
                }

            </>
        )
    }
}

export default BookingsGroup;