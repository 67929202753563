import { observable, action } from 'mobx';
import LoadPromise from 'helpers/LoadPromise';
import { Pointer, Parse } from 'helpers/Parse';
import _ from 'lodash';
import { replaceObjectById } from 'helpers/CollectionHelper';

export enum WaiverStatusEnum {
    DRAFT = 'ATWaiverStatusDraft',
    ACTIVE = 'ATWaiverStatusActive',
    ARCHIVED = 'ATWaiverStatusArchived'
}

export class WaiverStore {

    @observable waivers: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.waivers = [];
    }

    @action
    getWaivers(groupId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const waiverQuery = new Parse.Query('Waiver');
                waiverQuery.equalTo('group', Pointer('Group', groupId))
                waiverQuery.descending('publishedDate');

                const waivers = await waiverQuery.find();
                this.waivers = waivers;
                resolve(waivers);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getWaiver(id: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const waiverQuery = new Parse.Query('Waiver');
                const waiver = await waiverQuery.get(id);

                if (_.find(this.waivers, { id: waiver.id })) {
                    this.waivers = replaceObjectById(this.waivers, waiver);
                }
                else {
                    this.waivers.push(waiver);
                }

                resolve(waiver);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    createWaiver(groupId: string, title: string, content: string, status: WaiverStatusEnum, publishedDate?: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const Waiver = Parse.Object.extend('Waiver');
                const waiver = new Waiver();

                waiver.set('group', Pointer('Group', groupId));
                waiver.set('title', title);
                waiver.set('content', content);
                waiver.set('status', status);
                if (status === WaiverStatusEnum.ACTIVE && publishedDate) {
                    const date = new Date(publishedDate)
                    waiver.set('publishedDate', date);
                }

                const res = await waiver.save();
                resolve(res);

            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    updateWaiver(id: string, title: string, content: string, status: WaiverStatusEnum, publishedDate?: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const waiverQuery = new Parse.Query('Waiver');

                const waiver = await waiverQuery.get(id);
                waiver.set('title', title);
                waiver.set('content', content);
                waiver.set('status', status);
                if (status === WaiverStatusEnum.ACTIVE && publishedDate) {
                    waiver.set('publishedDate', publishedDate);
                }

                const res = await waiver.save();
                this.waivers = replaceObjectById(this.waivers, res);
                resolve(res);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

}

export const waiverStore = new WaiverStore();