import * as React from 'react';
import { observer } from 'mobx-react';
import { loadingStore } from 'stores';

interface Props {
    imageSrc: string,
    title?: string,
    description?: string
}

@observer class TablePlaceholder extends React.Component<Props> {

    render() {
        const { imageSrc, title, description } = this.props;
        if (loadingStore.isLoading) return (
            <div className='text-center mt-20'>
                <h1 className='text-white uppercase mt-8'>
                    Loading...
                </h1>
            </div>
        )

        return (
            <div className='text-center mt-20'>
                <img src={imageSrc} alt="No" />
                {
                    title &&
                    <h1 className='text-white uppercase mt-8'>
                        {title}
                    </h1>
                }

                {
                    description &&
                    <p className='mt-6 text-base text-white mx-auto' style={{ maxWidth: '550px' }}>
                        {description}
                    </p>
                }

            </div>

        )
    }

}

export default TablePlaceholder;

