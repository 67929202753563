import { observable, action } from 'mobx';
import * as Parse from 'parse';
import { Pointer } from 'helpers/Parse';
import _ from 'lodash';
import LoadPromise from 'helpers/LoadPromise';
import { replaceObjectById } from 'helpers/CollectionHelper';
import { recurringActivityStore } from './RecurringActivityStore';

export enum GroupDetailsTopMenuEnum {
    ACTIVITIES = 'activities',
    RECURRING = 'recurring',
    MEMBERS = 'members',
    WAIVERS = 'waivers',
    MESSAGES = 'messages',
    EMAIL = 'email'
}

export interface CreateGroupInterface {
    name: string,
    type: CustomerGroupVisibilityEnum,
    customerAffiliationId: string,
    customerGroupType: CustomerGroupTypeEnum
    coverImageBase64String?: string,
    groupImageBase64String?: string,
    shouldDeleteCoverImage?: boolean,
    shouldDeleteGroupImage?: boolean,
    affiliationId?: string
}

export enum GroupMemberStatusEnum {
    ADMIN = 'ATGroupMemberStatusAdmin',
    PENDING = 'ATGroupMemberStatusPending',
    MEMBER = 'ATGroupMemberStatusMember',
}

export enum CustomerGroupTypeEnum {
    MAIN = 'ATCustomerGroupTypeMain',
    FITNESS = 'ATCustomerGroupTypeFitness',
    CLUB_SPORT = 'ATCustomerGroupTypeClubSport'
}

export enum CustomerGroupVisibilityEnum {
    OPEN = 'ATGroupTypePublic',
    CLOSED = 'ATGroupTypeClosed'
}

export class FitnessStore {

    @observable groups: Array<Parse.Object> = [];
    @observable filteredGroups: Array<Parse.Object> = [];

    @observable selectedGroupId: string | null = null;
    @observable selectedTopMenu: GroupDetailsTopMenuEnum = GroupDetailsTopMenuEnum.ACTIVITIES;

    @observable activities: Array<Parse.Object> = [];

    @action
    getGroups(customerId: string, groupType?: CustomerGroupTypeEnum, skipMembers: boolean = false, onlyActive: boolean = true) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const groupQuery = new Parse.Query('Group');
                groupQuery.equalTo('customerAffiliation', Pointer('Customer', customerId));

                if (onlyActive) {
                    groupQuery.equalTo('active', true);
                }

                groupQuery.addAscending('name');

                if (groupType) {
                    groupQuery.equalTo('customerGroupType', groupType);
                }

                let groups = await groupQuery.find();

                if (!skipMembers) {
                    for (const group of groups) {
                        const queryMembers = new Parse.Query("GroupMember");
                        queryMembers.equalTo("group", group);
                        queryMembers.limit(10000);
                        queryMembers.containedIn("memberStatus", ["ATGroupMemberStatusAdmin", "ATGroupMemberStatusMember"]);

                        const count = await queryMembers.count();
                        group['_memberCount'] = count;
                    }
                }

                this.groups = groups;
                this.filteredGroups = groups;

                resolve(groups);

            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    groupIsActive(groupId: string) {
        const group = _.find(this.groups, { id: groupId });
        return group ? !!group.get('active') : true;
    }

    @action
    createGroup(opts: CreateGroupInterface) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const group = await Parse.Cloud.run('requestCreateGroup', opts);
                resolve(group);
            }
            catch (error) {
                reject(error.message);
            }
        });
    }

    @action
    editGroup(groupId: string, opts: CreateGroupInterface) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const groupQuery = new Parse.Query('Group');
                groupQuery.equalTo('objectId', groupId);
                const group = await groupQuery.first();

                if (!group) {
                    reject('no group');
                    return;
                }

                if (opts.name) {
                    group.set('name', opts.name)
                }

                if (opts.type) {
                    group.set('type', opts.type);
                }

                if (opts.shouldDeleteCoverImage) {
                    group.unset('coverImage');
                }
                else if (opts.coverImageBase64String) {
                    var coverImageFile = new Parse.File('image.png', { base64: opts.coverImageBase64String })
                    await coverImageFile.save();
                    group.set('coverImage', coverImageFile);
                }

                if (opts.shouldDeleteGroupImage) {
                    group.unset('image');
                }
                else if (opts.groupImageBase64String) {
                    var groupImageFile = new Parse.File('image.png', { base64: opts.groupImageBase64String })
                    await groupImageFile.save();
                    group.set('image', groupImageFile);
                }

                group.save();
                resolve(group);
            }
            catch (error) {
                reject(error.message);
            }
        });
    }

    @action
    archiveGroup(groupId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const groupQuery = new Parse.Query('Group');
                const group = await groupQuery.get(groupId);
    
                if (!group) {
                    reject('no group');
                }
    
                group.set('active', false);

                await Parse.Cloud.run('requestArchiveGroup', {
                    groupID: groupId,
                    active: false
                })
    
                replaceObjectById(this.groups, group);
                replaceObjectById(this.filteredGroups, group);

                await recurringActivityStore.deleteRecurringActivities(groupId);
    
                resolve();
            }
            catch(error) {
                reject(error.message);
            }

        })
    }


    @action
    setFilteredGroups(groups: Array<Parse.Object>) {
        this.filteredGroups = groups;
    }

    @action
    resetFilteredGroups() {
        this.filteredGroups = [];
    }

    @action
    resetActivities() {
        this.activities = [];
    }

    @action
    getActivities(groupId: string, opts: {
        archived?: boolean,
        sportKey?: string,
        customTitle?: string,
        hostName?: string
    } = {} ) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const activityQuery = new Parse.Query('Game');
                activityQuery.include('host');
                activityQuery.include('venue');
                activityQuery.equalTo('group', Pointer('Group', groupId!));
                activityQuery.limit(9999);

                if (!opts.archived) {
                    activityQuery.containedIn('gameStatus', ['ATGameStatusOpen', 'ATGameStatusConfirmed']);
                    activityQuery.ascending('gameStart');
                }
                else {
                    activityQuery.containedIn('gameStatus', ['ATGameStatusHistory', 'ATGameStatusCompleted']);
                    activityQuery.descending('gameStart');

                }

                if (opts.sportKey) {
                    activityQuery.equalTo('sportKey', opts.sportKey);
                }

                if (opts.customTitle) {
                    activityQuery.matches('customTitle', opts.customTitle as any, 'i'); // Hack, for comparing names (lowercase)
                }

                if (opts.hostName) {
                    const hostQuery = new Parse.Query('_User');
                    hostQuery.matches('name', opts.hostName as any, 'i'); // Hack, for comparing names (lowercase)
                    activityQuery.matchesQuery('host', hostQuery);
                }

                const activities = await activityQuery.find();

                this.activities = activities;

                resolve(activities);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    resetGroups() {
        this.groups = [];
    }

    @action
    resetStore() {
        this.resetGroups();
        this.resetFilteredGroups();
        this.selectedGroupId = null;
    }

    @action
    setSelectedGroupId(id: string | null) {
        this.selectedGroupId = id;
    }

    @action
    setSelectedTopMenu(topMenu: GroupDetailsTopMenuEnum) {
        this.selectedTopMenu = topMenu;
    }

}

export const fitnessStore = new FitnessStore();