import * as React from 'react';
import { observer } from 'mobx-react';
import InputModal from '../InputModal';
import { FieldState } from 'formstate';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import Dropdown from 'components/Forms/Dropdown';

export enum VerticalEnum {
    ANY = 'Any',
    FITNESS = 'Fitness',
    CLUB_SPORT = 'Clubsport',
    MAIN = 'Main'
}

interface Props {
    vertical: FieldState<VerticalEnum>,
}

@observer class SelectVertical extends React.Component<Props> {

    render() {
        const { vertical } = this.props;

        return (
            <InputModal
                label='Vertical'
                id='vertical'
                value={vertical.value}
                onClick={() => null}>

                <Section>
                    <SectionItem title='Vertical' titleWidth='100px' hideSeparator={true}>
                        <Dropdown className='Dropdown-light pr-0' style={{ paddingRight: 0 }} options={[
                            {
                                value: VerticalEnum.ANY,
                                name: 'Any'
                            },
                            {
                                value: VerticalEnum.FITNESS,
                                name: 'Fitness'
                            },
                            {
                                value: VerticalEnum.CLUB_SPORT,
                                name: 'Club Sport'
                            },
                            {
                                value: VerticalEnum.MAIN,
                                name: 'Main'
                            }

                        ]}
                            fieldState={vertical} />
                    </SectionItem>
                </Section>

            </InputModal>
        )
    }

}

export default SelectVertical;