import * as React from 'react';
import { observer } from 'mobx-react';

import './style.css';

interface Props {
    title: string,
    value: number,
    percentage?: number,
    action?: {
        title: string,
        onClick: () => void
    }
}

@observer class NumbersBox extends React.Component<Props> {

    render() {
        const { title, value, percentage, action } = this.props;

        return (
            <div className='NumbersBox rounded py-6 px-5 mx-2'>
                <p className='text-inactive'>
                    {title}
                </p>

                <p className='text-42px mt-2 text-darkest'>
                    {value.toLocaleString('en-US')}

                    {
                        (!!percentage && percentage !== 0) &&
                        <span className={`NumbersBox-percentage text-sm font-medium ${percentage > 0 ? 'text-statsGreen' : 'text-warning'}`}>
                            {percentage}
                        </span>
                    }

                    {
                        action &&
                        <span
                            className='block'
                            onClick={action.onClick} 
                            style={{ fontSize: '12px', marginTop: '10px', cursor: 'pointer' }}>
                            {action.title}
                        </span>
                    }

                </p>

            </div>
        )
    }

}

export default NumbersBox;