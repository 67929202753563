import * as React from 'react';
import { observer } from 'mobx-react';
import { waiverAgreementStore } from 'stores';
import SectionTitle from 'components/SectionTitle';
import Table from 'components/Table';
import Moment from 'moment';

interface Props {
    userId: string
}

@observer class WaiverAgreements extends React.Component<Props> {

    componentDidMount() {
        const { userId } = this.props;
        waiverAgreementStore.getAgreementsByUser(userId);
    }

    componentWillUnmount() {
        waiverAgreementStore.resetStore();
    }

    render() {
        const data = waiverAgreementStore.agreements || [];
        if (data.length === 0) return null;

        const columns = [
            {
                Header: 'Name',
                id: 'name',
                accessor: (d: Parse.Object) => d.get('firstname') + ' ' + d.get('lastname')
            },
            {
                Header: 'Student ID',
                id: 'studentID',
                accessor: (d: Parse.Object) => d.get('studentID')
            },
            {
                Header: 'Group',
                id: 'group',
                accessor: (d: Parse.Object) => d.get('waiver').get('group').get('name')
            },
            {
                Header: 'Waiver',
                id: 'waiver',
                accessor: (d: Parse.Object) => d.get('waiver').get('title')
            },
            {
                Header: 'Signed Date',
                id: 'signedDate',
                accessor: (d: Parse.Object) => d.get('signedDate') ? Moment(d.get('signedDate')).local().format('MM-DD-YYYY hh:mm') : null
            },
        ]

        return (
            <div>
                <SectionTitle title='Waivers' skipMarginBottom={true} />
                <div className='mt-4' />
                <Table
                    data={data}
                    columns={columns}
                />
            </div>
        )
    }

}

export default WaiverAgreements;