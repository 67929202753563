import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';
import SectionItem from 'components/Forms/SectionItem';
import DateInput from 'components/Forms/DateInput';

interface Props {
    startDate: FieldState<any>,
    endDate: FieldState<any>
}

@observer class StartDateEndDate extends React.Component<Props> {

    render() {
        const { startDate, endDate } = this.props;

        return (
            <SectionItem
                hideSeparator={true}
                title='First date/Last date (optional)'>

                <div className='flex'>
                    <div className='DateInput-light w-1/2'>
                        <DateInput
                            fieldState={startDate} />
                    </div>

                    <div className='DateInput-light w-1/2 ml-4'>
                        <DateInput
                            fieldState={endDate} />
                    </div>
                </div>

            </SectionItem>
        )
    }
}

export default StartDateEndDate;