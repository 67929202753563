import * as React from 'react';
import { observer } from 'mobx-react';

@observer class TermsConditions extends React.Component {

    render() {
        return (
            <a className='text-inactive hover:text-white no-underline font-thin' href='/terms.pdf' target='_blank'>Terms & Conditions</a>
        )
    }

}

export default TermsConditions;