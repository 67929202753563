import * as React from 'react';
import { observer } from 'mobx-react';
import ActionButton from 'components/ActionButton';
import { groupMemberStore } from 'stores';

interface Props {
    groupMemberId: string
}

@observer class ActionsCell extends React.Component<Props> {

    acceptMembership = () => {
        const { groupMemberId } = this.props;
        groupMemberStore.acceptPendingMembership(groupMemberId);
    }

    declineMembership = () => {
        const { groupMemberId } = this.props;
        groupMemberStore.deleteMembership(groupMemberId);
    }

    render() {
        return (
            <div>
                <ActionButton width='wrap' text='Decline' onClick={this.declineMembership} className='mr-2 bg-warning' />
                <ActionButton width='wrap' text='Accept' onClick={this.acceptMembership} primary={true} />
            </div>
        )

    }

}

export default ActionsCell;