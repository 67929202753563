// @ts-ignore
import { stateToMarkdown } from 'draft-js-export-markdown';
// @ts-ignore
import { stateFromMarkdown } from 'draft-js-import-markdown';

import React from 'react';
import { Editor as DraftEditor, EditorState, RichUtils } from 'draft-js';
import { observer } from 'mobx-react';

import './style.css';
import EditorInlineStyleButton from './EditorInlineStyleButton';
import EditorBlockStyleButton from './EditorBlockStyleButton';
import { FieldState } from 'formstate';

interface Props {
    fieldState: FieldState<any>,
    readOnly?: boolean
}

interface State {
    editorState: EditorState
}

@observer class Editor extends React.Component<Props, State> {

    editorRef: any;

    constructor(props: any) {
        super(props);

        this.editorRef = React.createRef();

        let editorState;
        if (props.fieldState.$) {
            const val = stateFromMarkdown(props.fieldState.$);
            editorState = EditorState.createWithContent(val);
        }
        else {
            editorState = EditorState.createEmpty();
        }

        this.state = {
            editorState: editorState
        }
    }
    onChange = (editorState: EditorState) => {
        const { fieldState } = this.props;

        const content = stateToMarkdown(editorState.getCurrentContent());
        fieldState.onChange(content);

        this.setState({
            editorState: editorState
        })
    }

    _toggleInlineStyle = (inlineStyle: string) => {
        this.onChange(RichUtils.toggleInlineStyle(
            this.state.editorState,
            inlineStyle
        ));
    }

    _toggleBlockType = (blockType: string) => {
        this.onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    render() {
        const { readOnly } = this.props;
        const { editorState } = this.state;

        return (
            <div className='Editor-root'>
                {
                    !readOnly &&
                    <>
                        <EditorInlineStyleButton position='left' label='B' editorState={editorState} inlineStyle='BOLD' onToggle={this._toggleInlineStyle} />
                        <EditorBlockStyleButton position='right' label='H1' editorState={editorState} inlineStyle='header-one' onToggle={this._toggleBlockType} />
                    </>
                }


                <div className={`Editor-editor ${readOnly ? 'Editor-editor-readOnly': ''}`}>
                    <DraftEditor
                        readOnly={readOnly}
                        ref={this.editorRef}
                        editorState={editorState}
                        onChange={this.onChange}
                    />
                </div>
            </div>
        );
    }
}

export default Editor;