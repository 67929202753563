import * as React from 'react';
import { observer } from 'mobx-react';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import _ from 'lodash';

import './style.css';
import { settingsStore, authStore, navigationStore, customerRoleStore } from 'stores';
import Administrators from './Administrators';

@observer class SettingsPage extends React.Component {

    componentDidMount() {
        const userRole = customerRoleStore.loggedInUserRole;
        if (userRole && userRole.get('limitedAccess')) {
            navigationStore.push('/');
            return;
        }
        else {
            navigationStore.setShowSecondaryMenu(false);
            const customerId = authStore.customer.id;
            settingsStore.getSlurps(customerId);
            settingsStore.getAdmins(customerId);
        }
    }

    componentWillUnmount() {
        navigationStore.setShowSecondaryMenu(true);
    }

    canAccessSettings = (userRole: Parse.Object) => {
        if (!userRole) return true;
        return userRole.get('limitedAccess');
    }

    mapEmailSuffixesToList(slurps: Array<Parse.Object>) {
        const uniques = _.uniqBy(slurps, 'attributes.emailSuffix');
        return uniques.map((item: Parse.Object) => item.get('emailSuffix')).join(', ');
    }

    render() {
        const customer = authStore.customer;
        if (!customer) return null;

        const slurps = settingsStore.slurps;
        const admins = settingsStore.admins;

        return (
            <div className='w-full SettingsPage'>
                <Section title='General information'>
                    <SectionItem title='School name'>
                        <p>
                            {customer.get('name')}
                        </p>
                    </SectionItem>
                    <SectionItem title='Email suffixes' hideSeparator={true}>
                        <p>
                            {this.mapEmailSuffixesToList(slurps)}
                        </p>
                    </SectionItem>
                </Section>

                <Administrators admins={admins} />
            </div>
        )
    }

}

export default SettingsPage;