import * as React from 'react';
import { observer } from 'mobx-react';
import Members from 'components/Members';
import { navigationStore, groupMemberStore, authStore } from 'stores';
import ActionButton from 'components/ActionButton';

interface State {
    selectedIds: Array<string>
}

@observer class MembersPage extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            selectedIds: []
        }
    }

    componentDidMount() {
        navigationStore.setShowSecondaryMenu(false);
    }

    componentWillUnmount() {
        navigationStore.setShowSecondaryMenu(true);
    }

    onSelectedChanged = (selectedIds: Array<string>) => {
        this.setState({
            selectedIds: selectedIds
        })
    }

    onRowClicked = (row: any) => {
        const userId = row.id;
        navigationStore.push(`/memberdetails/${userId}`);
    }

    deleteSelectedMembers = () => {
        const { selectedIds } = this.state;

        if (window.confirm(`Are you sure you want to remove the ${selectedIds.length} selected member(s)? This cannot be undone.`)) {
            groupMemberStore.deleteCustomerMembers(authStore.customer.id, selectedIds);
        }
    }

    render() {
        const { selectedIds } = this.state;

        return (
            <Members selectable={true} onSelectedChanged={this.onSelectedChanged} onRowClicked={this.onRowClicked}>
                <div className='text-right mb-2'>
                    {
                        selectedIds.length === 0 &&
                        <>&nbsp;</>
                    }

                    {
                        selectedIds.length > 0 &&
                        <ActionButton 
                            width='narrow' 
                            onClick={this.deleteSelectedMembers}
                            text={`Delete ${selectedIds.length} member(s)`}
                        />
                    }
                </div>
            </Members>
        )
    }

}

export default MembersPage;