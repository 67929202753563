import { observable, action } from 'mobx';
import { Pointer, Parse, GetQueryPointer } from 'helpers/Parse';
import LoadPromise from 'helpers/LoadPromise';
import { activityDetailsStore } from './ActivityDetailsStore';

export class RecurringActivityStore {

    @observable recurringActivities: Array<Parse.Object> = [];
    @observable filteredRecurringActivities: Array<Parse.Object> = [];

    @observable recurringActivityDetails: Parse.Object | null = null;

    @action
    resetStore() {
        this.recurringActivities = [];
        this.filteredRecurringActivities = [];
        this.recurringActivityDetails = null;
    }

    @action
    setFilteredRecurringActivities(activities: Array<Parse.Object>) {
        this.filteredRecurringActivities = activities;
    }

    @action
    getGroupRecurringActivities(groupId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const activityQuery = new Parse.Query('RecurringGame');
                activityQuery.equalTo('active', true);
                activityQuery.include('recurringGameData.host');
                activityQuery.include('recurringGameData.venue');

                activityQuery.greaterThanOrEqualTo('lastDate', new Date());

                const subQuery = new Parse.Query('RecurringGameData');
                subQuery.equalTo('group', Pointer('Group', groupId));

                activityQuery.matchesQuery('recurringGameData', subQuery);
                activityQuery.limit(10000);

                const activities = await activityQuery.find();
                this.recurringActivities = activities;
                this.filteredRecurringActivities = activities;
                resolve(activities);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getRecurringActivityDetails(activityId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                var recurringGameQuery = new Parse.Query('RecurringGame');

                recurringGameQuery.equalTo('objectId', activityId);
                recurringGameQuery.include('recurringGameData');

                const activity = await recurringGameQuery.first();
                if (activity) {
                    this.recurringActivityDetails = activity;
                    resolve(activity);
                }
                else {
                    reject('no activity found');
                }
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    hostRecurringActivity(customerId: string, opts: any) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const activityExists = await this.activityExists(customerId, opts);

                if (activityExists) {
                    reject('This recurring template already exists');
                    return;
                }

                const RecurringGame = Parse.Object.extend('RecurringGame');
                const recurringGame = new RecurringGame();
                const RecurringGameData = Parse.Object.extend('RecurringGameData');
                const recurringGameData = new RecurringGameData();

                const saveableGame = this.mapOptsToActivity(recurringGame, recurringGameData, opts);
                saveableGame.set('customer', Pointer('Customer', customerId));

                const res = await saveableGame.save();
                resolve(res);

            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    editRecurringActivity(id: string, opts: any) {
        return LoadPromise(async (resolve, reject) => {
            try {
                var recurringGameQuery = new Parse.Query('RecurringGame');
                recurringGameQuery.include('recurringGameData');
                recurringGameQuery.equalTo("objectId", id);

                const game = await recurringGameQuery.first();
                if (game) {
                    var updatedGame = this.mapOptsToActivity(game, game.get('recurringGameData'), opts);
                    updatedGame.save();
                    resolve(updatedGame);
                }
                else reject('recurring activity does not exist');

            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    deleteRecurringActivity = (id: string) => {
        return LoadPromise(async (resolve, reject) => {
            try {
                const recurringGameQuery = new Parse.Query('RecurringGame');
                const entity = await recurringGameQuery.get(id);

                await entity.destroy();

                const gameQuery = new Parse.Query('Game');
                gameQuery.equalTo('recurringGame', Pointer('RecurringGame', id));
                gameQuery.containedIn('gameStatus', ['ATGameStatusOpen', 'ATGameStatusConfirmed']);
                const games = await gameQuery.find() as Array<Parse.Object>;
                for (let game of games) {
                    await activityDetailsStore.cancelActivity(game.id);
                }

                resolve();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    deleteRecurringActivities = (groupId: string) => {
        return LoadPromise(async (resolve, reject) => {
            try {
                const recurringGameDataQuery = new Parse.Query('RecurringGameData');
                recurringGameDataQuery.equalTo('group', Pointer('Group', groupId));
                const recurringGameDataResult = await recurringGameDataQuery.find();

                const recurringGameQuery = new Parse.Query('RecurringGame');
                recurringGameQuery.containedIn('recurringGameData', recurringGameDataResult);

                const recurringGames = await recurringGameQuery.find();

                for (const recurringGame of recurringGames) {
                    await this.deleteRecurringActivity(recurringGame.id);
                }

                resolve();

            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    activityExists(customerId: string, opts: any) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const queryRecurringGame = new Parse.Query('RecurringGame');
                queryRecurringGame.equalTo("gameDayOfWeek", opts.gameDayOfWeek);
                queryRecurringGame.equalTo("gameStartTimeOfDay", opts.gameStartTimeOfDay);

                const customer = GetQueryPointer('Customer', customerId);
                queryRecurringGame.equalTo("customer", customer);

                const innerQuery = new Parse.Query("RecurringGameData");
                innerQuery.include('group');
                innerQuery.equalTo("customTitle", opts.customTitle);
                innerQuery.equalTo("sportKey", opts.sportKey);

                const group = GetQueryPointer('Group', opts.group);
                innerQuery.equalTo("group", group);
                queryRecurringGame.matchesQuery("recurringGameData", innerQuery);

                const result = await queryRecurringGame.first();
                resolve(!!result);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    mapOptsToActivity(recurringGame: Parse.Object, recurringGameData: Parse.Object, opts: any) {
        recurringGameData.set('minPlayers', opts.minPlayers);
        recurringGameData.set('minSkillLevel', opts.minSkillLevel);
        recurringGameData.set('customTitle', opts.customTitle);
        recurringGameData.set('sportKey', opts.sportKey);
        recurringGameData.set('maxPlayers', opts.maxPlayers);
        recurringGameData.set('unlimitedPlayers', opts.unlimitedPlayers);
        recurringGameData.set('note', opts.note);
        recurringGameData.set('gameMode', opts.gameMode);
        recurringGameData.set('maxSkillLevel', opts.maxSkillLevel);
        recurringGameData.set('athletesHidden', opts.athletesHidden);
        recurringGameData.set('host', Parse.User.createWithoutData(opts.host));

        recurringGameData.set('group', Pointer('Group', opts.group));
        recurringGameData.set('venue', Pointer('Venue', opts.venue));
        recurringGameData.set('gameTimezone', opts.gameTimezone);
        recurringGameData.set('gameArea', opts.gameArea);
        recurringGameData.set('geoLocation', opts.geoLocation);

        recurringGameData.set('inviteUsers', opts.inviteUsers || false);
        recurringGameData.set('autoAccept', opts.autoAccept || true);
        recurringGameData.set('privateGame', opts.privateGame || true);

        recurringGame.set('concurrentActiveGames', opts.concurrentActiveGames);
        recurringGame.set('firstDate', opts.firstDate);
        recurringGame.set('lastDate', opts.lastDate);
        recurringGame.set('gameStartTimeOfDay', opts.gameStartTimeOfDay);
        recurringGame.set('gameDayOfWeek', opts.gameDayOfWeek);
        recurringGame.set('intervalOption', opts.intervalOption);
        recurringGame.set('gameDeadlineOption', opts.gameDeadlineOption);
        recurringGame.set('recurringGameData', recurringGameData);
        recurringGame.set('gameDurationMinutes', opts.gameDurationMinutes);

        recurringGame.set('active', opts.active || true);
        recurringGame.set('hostHoursBeforeGameStart', opts.hostHoursBeforeGameStart);
        recurringGame.set('emailNotification', opts.emailNotification);

        return recurringGame;

    }

}

export const recurringActivityStore = new RecurringActivityStore();