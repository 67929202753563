import * as React from 'react';
import { observer } from 'mobx-react';

import './style.css';
import { authStore } from 'stores';

@observer class MainLogo extends React.Component {

    render() {
        const customer = authStore.customer;
        if (!customer) return null;

        const logo = customer.get('logo');

        return (
            <div className='w-full mb-30px'>
                {
                    !logo &&
                    <div className='MainLogo' />
                }

                {
                    logo &&
                    <img className='MainLogo block' alt='logo' src={logo.url()} />
                }

            </div>
        )
    }

}

export default MainLogo;