import * as React from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';

import './style.css';

interface DataItemInterface {
    label: string,
    value: number,
    percentage?: number | null
}

interface Props {
    title: string,
    data: Array<DataItemInterface>
}

@observer class RowBox extends React.Component<Props> {

    render() {
        const { title, data } = this.props;

        const totalValueSum = _.sumBy(data, 'value') || 0;



        return (
            <div className='RowBox rounded w-full'>
                <h2 className='pl-6 pt-6 pr-6 pb-4 text-lg font-medium'>{title}</h2>

                <div className='RowBox-splitter' />

                {
                    _.orderBy(data, 'value', 'desc').map((dataItem, index) => {
                        const percentageOfTotalSum = (dataItem.value / totalValueSum) * 100;

                        return (
                            <div key={index}>
                                <div className='RowBox-rowItem ml-6 mr-6 my-4 flex justify-between items-center'>
                                    <p className='font-medium text-darkest' style={{ width: '200px' }}>{dataItem.label}</p>
                                    <div className='RowBox-rowItem-percentage-container mx-2 rounded'>
                                        <div className='RowBox-rowItem-percentage-fill rounded' style={{ width: `${percentageOfTotalSum}%` }} />
                                    </div>
                                    <p className='font-medium ml-4 text-darkest' style={{ width: '80px' }}>{dataItem.value.toLocaleString('en-US')}</p>
                                    {
                                        <p className={`font-normal text-xs ml-4 text-right ${dataItem.percentage && dataItem.percentage > 0 ? 'text-statsGreen' : 'text-warning'}`} style={{ width: '50px' }}>{dataItem.percentage ? dataItem.percentage + '%' : null}</p>
                                    }

                                </div>

                                {
                                    index < data.length - 1 &&
                                    <div className='RowBox-splitter ml-6' />
                                }
                            </div>
                        )
                    })
                }

            </div>
        )
    }

}

export default RowBox;