import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';
import { memberDetailsStore } from 'stores';

interface Props {
    fieldState: FieldState<any>,
    className?: string,
    memberId: string
}

@observer class SelectInstructor extends React.Component<Props> {

    afterChange = (value: string) => {
        const { memberId } = this.props;
        memberDetailsStore.updateInstructorStatus(memberId, this.toBoolean(value));
    }

    toBoolean = (value: string | number | boolean): boolean => {
        return [true, 'true', 'True', 'TRUE', '1', 1].includes(value);
    }
    
    render() {
        const { fieldState, className } = this.props;

        const opts = [
            {
                value: false,
                name: "No"
            },
            {
                value: true,
                name: "Yes"
            }
        ]

        return (
            <Dropdown
                className={`Dropdown-light ${className || ''}`}
                fieldState={fieldState}
                skipSort={true}
                options={opts}
                afterChange={this.afterChange}
            />
        )
    }

}

export default SelectInstructor;