import Parse from 'parse';
import { action, observable } from 'mobx';
import LoadPromise from 'helpers/LoadPromise';
// import { Pointer } from 'helpers/Parse';
import _ from 'lodash';
import { toastStore } from './ToastStore';

export interface ConversationListInterface {
    id: string,
    subject: string,
    body: string,
    recipients: Array<Parse.Object>,
    game: Parse.Object | null,
    group: Parse.Object | null,
    notificationType: string,
    recipientCount: number,
}

export class EmailNotificationStore {

    @observable emails: Array<ConversationListInterface> = [];
    @observable email: Parse.Object | null = null;
    @observable emailNotifications: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.emails = [];
        this.email = null;
    }

    @action
    resetMessages() {
        // this.messages = [];
    }

    /* @action
    getEmailNotification(id: string, isActivity: boolean = true) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const emailNotificationQuery = new Parse.Query('EmailNotification');
                if(isActivity) {
                    emailNotificationQuery.equalTo('game', Pointer('Game', id));
                }
                else {
                    emailNotificationQuery.equalTo('group', Pointer('Group', id));
                }
                emailNotificationQuery.descending('createdAt');
                emailNotificationQuery.limit(9999);

                const emailNotifiations = await emailNotificationQuery.find();
                if (emailNotifiations) {
                    this.emailNotifications = emailNotifiations;
                    resolve(emailNotifiations);
                }
                reject();
            }
            catch (error) {
                reject((error as Error).message);
            }
        })
    } */


    @action
    sendEmail(subject: string, body: string, notificationType: string, id: string, callback: () => void) {
        /* notificationType = [
            "ATCustomEmailActivityParticipants",
            "ATCustomEmailActivityGroup",
            "ATCustomEmailGroup",
            "ATAutoEmailActivityGroup",
          ] */
        return LoadPromise(async (resolve, reject) => {
            try {
                const successMessage = await Parse.Cloud.run('sendEmailNotification', {
                    subject,
                    body,
                    notificationType,
                    activityId: notificationType !== 'ATCustomEmailGroup' ? id : '',
                    groupId: notificationType === 'ATCustomEmailGroup' ? id : ''
                })
                resolve(successMessage);
                toastStore.addToast({
                    level: 'success',
                    title: 'Success',
                    message: successMessage
                })
                callback();
            }
            catch (error) {
                console.log(error)
                reject((error as Error).message);
            }
        })
    }

}

export const emailNotificationStore = new EmailNotificationStore();