import * as React from 'react';
import { observer } from 'mobx-react';
import { authStore, memberDetailsStore, navigationStore, mainGroupStore } from 'stores';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import Input from 'components/Forms/Input';
import Sports from './Sports';
import Activities from './Activities';
import Groups from './Groups';

import './style.css';
import WaiverAgreements from './WaiverAgreements';
import NoShows from './NoShows';
import SelectInstructor from './SelectInstructor';
import { FieldState } from 'formstate';
import TopMenu from 'components/TopMenu';
import Bookings from './Bookings';

enum TopMenuEnum {
    DETAILS,
    ACTIVITIES,
    BOOKINGS,
    WAIVERS
}

interface State {
    activeTopMenu: TopMenuEnum
}

@observer class MemberDetailsPage extends React.Component<any, State> {

    state = { activeTopMenu: TopMenuEnum.DETAILS }

    componentDidMount() {
        const userId = this.getUserIdFromParams();
        const customerId = authStore.customer.id;

        memberDetailsStore.getMemberDetails(userId, customerId);
        navigationStore.setShowSecondaryMenu(false);
    }

    getUserIdFromParams() {
        return this.props.match.params.userId;
    }

    componentWillUnmount() {
        memberDetailsStore.resetStore();
        navigationStore.setShowSecondaryMenu(true);
    }

    affiliationTypeToText(affiliation: string) {
        switch (affiliation) {
            case 'ATUniversityAffiliationTypeFreshman':
                return 'Freshman';
            case 'ATUniversityAffiliationTypeSophomore':
                return 'Sophomore';
            case 'ATUniversityAffiliationTypeJunior':
                return 'Junior';
            case 'ATUniversityAffiliationTypeSenior':
                return 'Senior';
            case 'ATUniversityAffiliationTypeGraduateStudent':
                return 'Graduate Student';
            case 'ATUniversityAffiliationTypeFacultyOrStaff':
                return 'Faculty or Staff';
            case 'ATUniversityAffiliationTypeOther':
                return 'Other';
            default:
                return '-';
        }
    }

    render() {
        const { activeTopMenu } = this.state;

        const menuItems = [
            {
                text: 'Details',
                onClick: () => this.setState({ activeTopMenu: TopMenuEnum.DETAILS }),
                isActive: activeTopMenu === TopMenuEnum.DETAILS
            },
            {
                text: 'Activities',
                onClick: () => this.setState({ activeTopMenu: TopMenuEnum.ACTIVITIES }),
                isActive: activeTopMenu === TopMenuEnum.ACTIVITIES
            },
            {
                text: 'Bookings',
                onClick: () => this.setState({ activeTopMenu: TopMenuEnum.BOOKINGS }),
                isActive: activeTopMenu === TopMenuEnum.BOOKINGS
            },
            {
                text: 'Waivers',
                onClick: () => this.setState({ activeTopMenu: TopMenuEnum.WAIVERS }),
                isActive: activeTopMenu === TopMenuEnum.WAIVERS
            },
        ]

        const member = memberDetailsStore.memberDetails as any;
        if (!member) return null;

        let mainMembership: any = null;
        memberDetailsStore.memberships.forEach((membership) => {
            if (membership.get('group').id === mainGroupStore.mainGroupId) {
                mainMembership = membership;
            }
        })

        return (
            <div className='w-full MemberDetailsPage'>
                <TopMenu items={menuItems} />

                <div className='flex mt-6 items-start'>
                    {
                        (activeTopMenu === TopMenuEnum.DETAILS && member.get('avatar')) &&
                        <div className='MemberDetailsPage-avatar bg-white p-2 mr-16px rounded'>
                            <img src={member.get('avatar')._url} alt='Profile' />
                        </div>
                    }

                    <div className='w-full'>

                        {
                            activeTopMenu === TopMenuEnum.DETAILS &&
                            <>
                                <Section>
                                    <SectionItem title='Name'>
                                        <Input readOnlyValue={member.get('name')} />
                                    </SectionItem>

                                    <SectionItem title='Email'>
                                        <Input readOnlyValue={member.get('username')} />
                                    </SectionItem>

                                    <SectionItem title='Current Status'>
                                        <Input readOnlyValue={this.affiliationTypeToText(member.get('universityAffiliationType'))} />
                                    </SectionItem>

                                    {
                                        mainMembership &&
                                        <SectionItem title='Instructor' hideSeparator={true}>
                                            <SelectInstructor memberId={mainMembership.id} fieldState={new FieldState(mainMembership.get('instructor'))} />
                                        </SectionItem>
                                    }

                                </Section>

                                <Groups userId={this.getUserIdFromParams()} />
                                <Sports userSports={member.userSports} />

                            </>
                        }

                        {
                            activeTopMenu === TopMenuEnum.ACTIVITIES &&
                            <>
                                <Activities gamePlayers={member.gamePlayers} />
                                <NoShows userId={this.getUserIdFromParams()} />
                            </>
                        }

                        {
                            activeTopMenu === TopMenuEnum.BOOKINGS &&
                            <Bookings userId={this.getUserIdFromParams()} />
                        }

                        {
                            activeTopMenu === TopMenuEnum.WAIVERS &&
                            <WaiverAgreements userId={this.getUserIdFromParams()} />
                        }

                    </div>

                </div>
            </div>


        )
    }

}

export default MemberDetailsPage;