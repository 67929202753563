import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';

interface Props {
    fieldState: FieldState<any>,
    className?: string
}

@observer class SelectRecurringDeadline extends React.Component<Props> {

    componentDidMount() {
        const { fieldState } = this.props;

        if (!fieldState.value) {
            fieldState.onChange('ATGameDeadlineOptionAtTimeOfActivity');
        }
    }

    render() {
        const { fieldState, className } = this.props;

        const opts = [
            {
                value: "ATGameDeadlineOptionAtTimeOfActivity",
                name: "Time of event"
            },
            {
                value: "ATGameDeadlineOptionOneHourBefore",
                name: "1 hour before"
            },
            {
                value: "ATGameDeadlineOptionOneDayBefore",
                name: "1 day before"
            },
            {
                value: "ATGameDeadlineOptionEndTime",
                name: "Time of game ending"
            }
        ]

        return (
            <Dropdown
                className={`Dropdown-light ${className || ''}`}
                fieldState={fieldState}
                skipSort={true}
                options={opts}
            />
        )
    }

}

export default SelectRecurringDeadline;