import { observable, action } from 'mobx';
import * as Parse from 'parse';
import LoadPromise from 'helpers/LoadPromise';
import { Pointer } from 'helpers/Parse';
import Moment from 'moment';

export class BookingClosedStore {

    @observable closingDays: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.closingDays = [];
    }

    @action
    getClosingDays(venueId?: string, courtId?: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const query = new Parse.Query('BookingClosed');
                query.descending('date');

                if (venueId) {
                    query.equalTo('venue', Pointer('Court', venueId));
                }

                if (courtId) {
                    query.equalTo('court', Pointer('Court', courtId));
                }

                const res = await query.find();
                this.closingDays = res;
                resolve(res);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    createClosingDay(date: Date, venueId?: string, courtId?: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const BookingClosed = Parse.Object.extend('BookingClosed');
                const bookingClosed = new BookingClosed();

                bookingClosed.set('date', Moment(date).format('YYYY-MM-DD'));

                if (venueId) {
                    bookingClosed.set('venue', Pointer('Venue', venueId));
                }

                if (courtId) {
                    bookingClosed.set('court', Pointer('Court', courtId));
                }

                const savedObj = await bookingClosed.save();
                this.closingDays.push(savedObj);
                resolve(savedObj);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    deleteClosingDay(id: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const query = new Parse.Query('BookingClosed');
                query.equalTo('objectId', id);

                const closingDay = await query.first();
                if (closingDay) {
                    await closingDay.destroy();

                    this.closingDays = this.closingDays.filter((item: any) => {
                        return item.id !== id;
                    })

                    resolve(closingDay);
                }
            }
            catch(error) {
                reject(error.message);
            }
        })
    }

}

export const bookingClosedStore = new BookingClosedStore();