import { observable, action } from 'mobx';
import { createBrowserHistory } from 'history';

export enum ActivePrimaryMenuEnum {
    HOME,
    STATS,
    MAIN,
    FITNESS,
    INTRAMURAL,
    CLUB_SPORT,
    MEMBERS,
    VENUES,
    BOOKINGS,
    COURTS,
    MESSAGES
}

export class NavigationStore {

    @observable activePrimaryMenu: ActivePrimaryMenuEnum | null = ActivePrimaryMenuEnum.HOME;
    @observable showSecondaryMenu: boolean = true;
    history = createBrowserHistory();

    constructor() {
        this.setActivePrimaryMenuByCurrentBrowserLocation();
    }

    @action
    resetStore() {
        this.activePrimaryMenu = ActivePrimaryMenuEnum.HOME;
        this.showSecondaryMenu = false;
    }

    @action
    push(location: string, callback: Function | undefined = undefined) {
        this.history.push(location);

        if (callback) {
            callback();
        }
    }

    @action
    replace(location: string) {
        this.history.replace(location);
    }

    @action
    go(n: number) {
        this.history.go(n);
    }

    @action
    goBack() {
        this.history.goBack();
    }

    @action
    goForward() {
        this.history.goForward();
    }

    @action
    setActivePrimaryMenu(activeMenu: ActivePrimaryMenuEnum) {
        this.activePrimaryMenu = activeMenu;
    }

    @action
    setShowSecondaryMenu(value: boolean) {
        this.showSecondaryMenu = value;
    }

    setActivePrimaryMenuByCurrentBrowserLocation() {
        if (window.location.pathname === '/' || window.location.pathname === '')  this.setActivePrimaryMenu(ActivePrimaryMenuEnum.HOME);
        else if (window.location.pathname.includes('/main')) this.setActivePrimaryMenu(ActivePrimaryMenuEnum.MAIN);
        else if (window.location.pathname.includes('/stats')) this.setActivePrimaryMenu(ActivePrimaryMenuEnum.STATS);
        else if (window.location.pathname.includes('/fitness')) this.setActivePrimaryMenu(ActivePrimaryMenuEnum.FITNESS);
        else if (window.location.pathname.includes('/intramural')) this.setActivePrimaryMenu(ActivePrimaryMenuEnum.INTRAMURAL);
        else if (window.location.pathname.includes('/clubsport')) this.setActivePrimaryMenu(ActivePrimaryMenuEnum.CLUB_SPORT);
        else if (window.location.pathname.includes('/members')) this.setActivePrimaryMenu(ActivePrimaryMenuEnum.MEMBERS);
        else if (window.location.pathname.includes('/venues')) this.setActivePrimaryMenu(ActivePrimaryMenuEnum.VENUES);
        else if (window.location.pathname.includes('/bookings')) this.setActivePrimaryMenu(ActivePrimaryMenuEnum.BOOKINGS);
        else if (window.location.pathname.includes('/courts')) this.setActivePrimaryMenu(ActivePrimaryMenuEnum.COURTS);
        else if (window.location.pathname.includes('/messages')) this.setActivePrimaryMenu(ActivePrimaryMenuEnum.MESSAGES);
    }

}

export const navigationStore = new NavigationStore();