import * as React from 'react';
import { observer } from 'mobx-react';
import PlaceholderIcon from './PlaceholderIcon.svg';

import './style.css';
import { ActivePrimaryMenuEnum, navigationStore } from 'stores';

interface Props {
    text: string,
    id: ActivePrimaryMenuEnum,
    iconSrc?: string
}

@observer class MenuItem extends React.Component<Props> {

    onClick = (event: React.MouseEvent) => {
        event.preventDefault();
        const { id } = this.props;

        switch (id) {
            case ActivePrimaryMenuEnum.HOME:
                navigationStore.push('/', this.scrollToTop);
                break;

            case ActivePrimaryMenuEnum.MAIN:
                navigationStore.push('/main', this.scrollToTop);
                break;

            case ActivePrimaryMenuEnum.STATS:
                navigationStore.push('/stats', this.scrollToTop);
                break;

            case ActivePrimaryMenuEnum.FITNESS:
                navigationStore.push('/fitness', this.scrollToTop);
                break;

            case ActivePrimaryMenuEnum.INTRAMURAL:
                navigationStore.push('/intramural', this.scrollToTop);
                break;

            case ActivePrimaryMenuEnum.CLUB_SPORT:
                navigationStore.push('/clubsport', this.scrollToTop);
                break;

            case ActivePrimaryMenuEnum.MEMBERS:
                navigationStore.push('/members', this.scrollToTop);
                break;

            case ActivePrimaryMenuEnum.MESSAGES:
                navigationStore.push('/messages', this.scrollToTop);
                break;

            case ActivePrimaryMenuEnum.VENUES:
                navigationStore.push('/venues', this.scrollToTop);
                break;

            case ActivePrimaryMenuEnum.COURTS:
                navigationStore.push('/courts', this.scrollToTop);
                break;

            case ActivePrimaryMenuEnum.BOOKINGS:
                navigationStore.push('/bookings', this.scrollToTop);
                break;

            default:
                navigationStore.push('/', this.scrollToTop);
                break;
        }

        navigationStore.setActivePrimaryMenu(id);
    }

    scrollToTop = () => {
        window.scrollTo(0, 0);
    }

    render() {
        const { text, id, iconSrc } = this.props;
        const active = navigationStore.activePrimaryMenu === id;

        return (
            <div onClick={this.onClick} className={`MenuItem py-12px flex items-center text-inactive cursor-pointer ${active ? 'MenuItem-active' : ''}`}>
                <img src={iconSrc || PlaceholderIcon} alt='icon' className={active ? 'ml-14px mr-18px' : 'mx-18px opacity-25'} />
                <p className='inline font-light'>
                    {text}
                </p>
            </div>
        )
    }

}

export default MenuItem;