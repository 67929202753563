import { observable, action } from 'mobx';
import * as Parse from 'parse';
import { Pointer } from 'helpers/Parse';
import LoadPromise from 'helpers/LoadPromise';
import _ from 'lodash';

export class HostStore {

    @observable hosts: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.hosts = [];
    }

    @action
    getHosts(customerId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const groupQuery = new Parse.Query('Group');

                groupQuery.equalTo('customerAffiliation', Pointer('Customer', customerId));

                const groupMemberQuery = new Parse.Query('GroupMember');
                groupMemberQuery.matchesQuery('group', groupQuery);
                groupMemberQuery.equalTo('instructor', true);

                groupMemberQuery.include('user');
                groupMemberQuery.include('group');

                groupMemberQuery.limit(10000);

                const members = await groupMemberQuery.find();
                const hosts = members.map((m) => m.get('user'));
                const uniqueHosts = _.uniqBy(hosts, h => h.id);
                this.hosts = _.orderBy(uniqueHosts, (h) => h.get('name'));
                const hostNames = {};
                this.hosts.forEach((user, idx) => {
                    if(!hostNames[user.get('name')]) {
                        hostNames[user.get('name')] = []
                    }
                    hostNames[user.get('name')].push(user)
                })
                for(const key in hostNames){
                    if(hostNames[key].length > 0){
                        hostNames[key].forEach((user: any) => {
                            console.log(`${user.get('username')} - ${user.get('name')}`)
                        })
                    }
                }
                resolve(uniqueHosts);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

}

export const hostStore = new HostStore();