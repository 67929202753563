import * as React from 'react';
import { observer } from 'mobx-react';
import { groupMemberStore } from 'stores';
import SearchCombo from 'components/Forms/SearchCombo';
// import { filterByName } from 'components/SearchGroupMember/SearchGroupMemberHelper';

interface Props {
    groupId?: string,
    isCustomer?: boolean
}

@observer class SearchCustomerMembers extends React.Component<Props> {

    onSearchMembers = (queryType: 'nameQuery' | 'emailQuery', searchValue: string) => {
        const { groupId, isCustomer } = this.props;

        groupMemberStore.getMembers(groupId!, isCustomer, { [queryType]: searchValue });
    }

    render() {

        // const allMembers = groupMemberStore.members;

        const options = [
            {
                value: 'name',
                name: 'Name',
                filterFunc: (searchValue: string) => this.onSearchMembers('nameQuery', searchValue)
            },
            {
                value: 'email',
                name: 'Email',
                filterFunc: (searchValue: string) => this.onSearchMembers('emailQuery', searchValue)
            },
        ]

        return (
            <SearchCombo
                placeholder='Search members'
                onClearFiltered={() => groupMemberStore.resetMembers()}
                options={options} />
        )
    }

}

export default SearchCustomerMembers;