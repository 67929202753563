import * as React from 'react';
import { observer } from 'mobx-react';
import GroupForm from 'components/Forms/GroupForm';
import { navigationStore, CreateGroupInterface, fitnessStore, CustomerGroupTypeEnum, authStore } from 'stores';

@observer class CreateGroupPage extends React.Component<any> {

    componentDidMount() {
        navigationStore.setShowSecondaryMenu(false);
    }

    componentWillUnmount() {
        navigationStore.setShowSecondaryMenu(true);
    }

    getGroupType() {
        return this.props.match.params.groupType;
    }

    onSubmit = async (opts: CreateGroupInterface) => {
        const customer = authStore.customer;
        const affiliation = customer.get('affiliationEducation');
        
        if (affiliation) {
            opts.affiliationId = affiliation.id;
        }

        await fitnessStore.createGroup(opts);
        navigationStore.goBack();
    }

    render() {
        let groupType = CustomerGroupTypeEnum.FITNESS;
        if (this.getGroupType() === 'clubsport') {
            groupType = CustomerGroupTypeEnum.CLUB_SPORT;
        }

        return (
            <GroupForm onSubmit={this.onSubmit} groupType={groupType} />
        )
    }

}

export default CreateGroupPage;