import * as React from 'react';
import { recurringActivityStore, navigationStore, fitnessStore, GroupDetailsTopMenuEnum } from 'stores';
import AvatarCell from 'components/Table/AvatarCell';
import { getImage } from 'helpers/SportImageHelper';
import { readableActivity } from 'helpers/ActivitiesHelper';
import Moment from 'moment';
import { DateColumn } from 'components/Table/DateColumn';
import Table from 'components/Table';
import SearchRecurringActivity from 'components/SearchRecurringActivity';
import Separator from 'components/Separator';
import { CellInfo } from 'react-table';
import { observer } from 'mobx-react';
import TablePlaceholder from 'components/TablePlaceholder';

import NoActivitiesImage from './NoActivities.svg';

@observer class RecurringActivitiesPage extends React.Component<any> {

    componentDidMount() {
        const groupId = this.props.match.params.groupId;
        recurringActivityStore.getGroupRecurringActivities(groupId);
        fitnessStore.setSelectedTopMenu(GroupDetailsTopMenuEnum.RECURRING);
    }

    componentWillReceiveProps(nextProps: any) {
        const nextGroupId = nextProps.match.params.groupId;
        if (nextGroupId) {
            recurringActivityStore.getGroupRecurringActivities(nextGroupId);
        }
    }

    componentWillUnmount() {
        recurringActivityStore.resetStore();
    }

    onTableRowClicked = (activity: Parse.Object) => {
        navigationStore.push(`/editactivity/${activity.id}/recurring`);
    }

    render() {
        if (recurringActivityStore.recurringActivities.length === 0) {
            return <TablePlaceholder
                imageSrc={NoActivitiesImage}
                title='no recurring templates'
                description='Create your first recurring template by clicking Create Activity in the top right corner, and mark the activity as recurring.'
            />;
        }

        const columns = [
            {
                Header: 'Sport',
                Cell: (cellInfo: CellInfo) => {
                    const row = cellInfo.original as Parse.Object;
                    const data = row.get('recurringGameData');
                    const sportKey = data.get('sportKey');

                    return <AvatarCell
                        imageUrl={getImage(sportKey)}
                        title={data.get('customTitle')}
                        subTitle={readableActivity(sportKey)}
                    />
                },
            },
            {
                Header: 'Day',
                id: 'day',
                minWidth: 50,
                accessor: (d: Parse.Object) => {
                    switch (d.get('gameDayOfWeek')) {
                        case 1: return 'Monday';
                        case 2: return 'Tuesday';
                        case 3: return 'Wednesday';
                        case 4: return 'Thursday';
                        case 5: return 'Friday';
                        case 6: return 'Saturday';
                        case 7: return 'Sunday';
                        default: return undefined;
                    }
                }
            },
            {
                Header: 'When',
                id: 'when',
                width: 130,
                accessor: (d: any) => Moment(d.get('gameStartTimeOfDay'), 'hh:mm').local().format('hh:mm A')
            },

            DateColumn('firstDate', 'First', (d: any) => d.get('firstDate')),
            DateColumn('lastDate', 'Last', (d: any) => d.get('lastDate')),
        ]

        return (
            <div className='w-full'>
                <div className="mt-5">
                    <SearchRecurringActivity />
                    <Separator className='mt-5 mb-5' />
                    <Table
                        onRowClicked={this.onTableRowClicked}
                        data={recurringActivityStore.filteredRecurringActivities}
                        columns={columns}
                    />
                </div>
            </div>
        )
    }

}

export default RecurringActivitiesPage;