import * as React from 'react';
import { observer } from 'mobx-react';
import MainLogo from './MainLogo';
import PrimarySectionTitle from './PrimarySectionTitle';
import MenuItem from './MenuItem';
import { ActivePrimaryMenuEnum, navigationStore, CustomerGroupTypeEnum, CustomerRoleAccessAreaEnum } from 'stores';
import SecondaryMenuGroups from './SecondaryMenuGroups';

import HomeIcon from './HomeIcon.svg';
import MainIcon from './MainIcon.svg';
import FitnessIcon from './FitnessIcon.svg';
// import IntramuralIcon from './IntramuralIcon.svg';
import ClubsportIcon from './ClubsportIcon.svg';
import MembersIcon from './MembersIcon.svg';
import VenuesIcon from './VenuesIcon.svg';
import StatsIcon from './StatsIcon.svg';
import BookingsIcon from './BookingsIcon.svg';
import MessagesIcon from './MessagesIcon.svg'
import TermsConditions from 'components/TermsConditions';
import Feedback from 'components/Feedback';
import UserGuide from 'components/UserGuide';
import { sidebarMenuStore } from 'stores/SidebarMenuStore';
import { canOnlyAccess, canAccess } from 'helpers/VerticalHelper';
// import { Parse } from 'helpers/Parse';



@observer class SidebarMenu extends React.Component {

    componentDidMount() {
        if (canOnlyAccess(CustomerRoleAccessAreaEnum.BOOKINGS)) {
            sidebarMenuStore.setVisible(false);
            navigationStore.push('/bookings');
        }
    }

    render() {
        const { activePrimaryMenu, showSecondaryMenu } = navigationStore;
        const visible = sidebarMenuStore.visible;

        return (
            <>
                <div className={`mr-16px hide-on-print ${!visible ? 'hidden' : ''}`}>
                    <div style={{ width: '220px' }}>
                        <MainLogo />
                        <PrimarySectionTitle text='Quick Access' />
                        {/* <MenuItem text='Overview' /> */}
                        <MenuItem id={ActivePrimaryMenuEnum.HOME} text='Home' iconSrc={HomeIcon} />

                        {
                            canAccess(CustomerRoleAccessAreaEnum.MAIN) &&
                            <MenuItem id={ActivePrimaryMenuEnum.MAIN} text='Activities' iconSrc={MainIcon} />
                        }

                        {
                            canAccess(CustomerRoleAccessAreaEnum.FITNESS) &&
                            <MenuItem id={ActivePrimaryMenuEnum.FITNESS} text='Fitness' iconSrc={FitnessIcon} />
                        }

                        {
                            canAccess(CustomerRoleAccessAreaEnum.CLUB_SPORT) &&
                            <MenuItem id={ActivePrimaryMenuEnum.CLUB_SPORT} text='Club Sport' iconSrc={ClubsportIcon} />
                        }

                        <PrimarySectionTitle text='Management' />
                        <MenuItem id={ActivePrimaryMenuEnum.STATS} text='Stats' iconSrc={StatsIcon} />
                        <MenuItem id={ActivePrimaryMenuEnum.MEMBERS} text='Members' iconSrc={MembersIcon} />
                        <MenuItem id={ActivePrimaryMenuEnum.MESSAGES} text='Messages' iconSrc={MessagesIcon} />
                        <MenuItem id={ActivePrimaryMenuEnum.VENUES} text='Venues' iconSrc={VenuesIcon} />
                        <MenuItem id={ActivePrimaryMenuEnum.COURTS} text='Subvenues' iconSrc={VenuesIcon} />
                        <MenuItem id={ActivePrimaryMenuEnum.BOOKINGS} text='Bookings' iconSrc={BookingsIcon} />
                    </div>
                    <div className="mt-16">
                        <UserGuide text='User guide (Dashboard)' linkTo='/userguide-dashboard-1.pdf' />

                        <div className="mt-2">
                        <UserGuide text='User guide (App)' linkTo='/userguide-app-1.pdf' />
                        </div>

                        <div className="mt-2">
                            <Feedback />
                        </div>

                        <div className='mt-2'>
                            <TermsConditions />
                        </div>

                    </div>
                </div>
                <div className={`mr-16px ${!visible ? 'hidden' : ''}`}>
                    {
                        (activePrimaryMenu === ActivePrimaryMenuEnum.FITNESS && showSecondaryMenu) &&
                        <SecondaryMenuGroups groupType={CustomerGroupTypeEnum.FITNESS} />
                    }

                    {
                        (activePrimaryMenu === ActivePrimaryMenuEnum.CLUB_SPORT && showSecondaryMenu) &&
                        <SecondaryMenuGroups groupType={CustomerGroupTypeEnum.CLUB_SPORT} />
                    }
                </div>
            </>
        )
    }

}

export default SidebarMenu;