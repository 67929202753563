import * as React from 'react';
import { observer } from 'mobx-react';
import RowBox from '../RowBox';
import { getPercentageChange } from 'helpers/NumbersHelper';


interface Props {
    participants: Object | null
}

@observer class ParticipantsStats extends React.Component<Props> {

    getLabel(item: string) {
        switch (item) {
            case 'freshman': return 'Freshman';
            case 'sophomore': return 'Sophomore';
            case 'junior': return 'Junior';
            case 'senior': return 'Senior';
            case 'graduateStudent': return 'Graduate Student';
            case 'facultyOrStaff': return 'Faculty or Staff';
            case 'other': return 'Other';
            default: return 'Unknown';
        }
    }

    render() {
        const { participants } = this.props;
        if (!participants) return null;

        const data: any = [];

        Object.keys(participants).forEach((type) => {
            const item = participants[type];

            if (item.current > 0) {
                data.push({
                    value: item.current,
                    label: this.getLabel(type),
                    percentage: getPercentageChange(item.last, item.current)
                })
            }

        })

        return (
            <RowBox title='Participant details' data={data} />
        )
    }

}

export default ParticipantsStats;