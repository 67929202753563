import * as React from 'react';
import { observer } from 'mobx-react';
import InputModal from '../InputModal';
import { FieldState, FormState } from 'formstate';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import Switch from 'components/Forms/Switch';
import { fitnessStore, authStore } from 'stores';
import _ from 'lodash';

interface Props {
    groupsChanged: Function
}

interface State {
    values: string
}

@observer class SelectGroups extends React.Component<Props, State> {

    form = new FormState({}) as any;

    ANY_GROUP_PLACEHOLDER: string = 'All';

    constructor(props: any) {
        super(props);

        this.state = {
            values: this.ANY_GROUP_PLACEHOLDER
        }

    }

    async componentDidMount() {
        await fitnessStore.getGroups(authStore.customer.id, undefined, true);

        fitnessStore.groups.forEach((group) => {
            this.form.$[group.id] = new FieldState(false);
        });
    }

    componentWillUnmount() {
        fitnessStore.resetFilteredGroups();
        fitnessStore.resetGroups();
    }

    updateSelectedGroups = () => {
        const { groupsChanged } = this.props;
        const values = [];
        const form = this.form.$;

        for (let key in form) {
            if (form[key].value === true) {
                const group = _.find(fitnessStore.groups, { id: key });
                if (group) {
                    values.push(group.get('name'));
                }
            }
        }

        groupsChanged(this.form);

        this.setState({
            values: values.join(', ') || this.ANY_GROUP_PLACEHOLDER
        })
    }

    render() {
        const form = this.form.$;
        if (!form) return null;
        const { values } = this.state;

        const items = [];
        for (let key in form) {
            const item = form[key];
            const group = _.find(fitnessStore.groups, { id: key });

            items.push(
                <SectionItem title={group ? group.get('name') : 'No name'} key={key}>
                    <Switch fieldState={item} className='justify-end' />
                </SectionItem>
            )
        }

        return (
            <InputModal
                label='Group(s)'
                id='groups'
                value={values}
                onClose={this.updateSelectedGroups}
                onClick={() => null}>

                <Section secondaryClassName='overflow-auto' secondaryStyle={{ maxHeight: '80vh' }}>
                    {items}
                </Section>

            </InputModal>
        )
    }

}

export default SelectGroups;