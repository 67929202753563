import { action, observable } from 'mobx';
import { Parse, Pointer } from 'helpers/Parse';
import LoadPromise from 'helpers/LoadPromise';

export class GroupStore {

    @observable groups: Array<Parse.Object> = [];
    @observable group: Parse.Object | null = null;

    @action
    resetStore() {
        this.group = null;
        this.groups = [];
    }

    @action
    getGroups(customerId: string) {
        return new Promise(async (resolve, reject) => {

            try {
                var groupQuery = new Parse.Query('Group');
                groupQuery.equalTo('customerAffiliation', Pointer('Customer', customerId));
                groupQuery.addAscending('name');

                const groups = await groupQuery.find();

                this.groups = groups;
                resolve(groups);

            }
            catch (error) {
                reject(error.message);
            }
        });
    }

    @action
    getGroup(groupId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                var groupQuery = new Parse.Query('Group');

                groupQuery.equalTo("objectId", groupId);
                const group = await groupQuery.first();
                if (group) {
                    this.group = group;
                    resolve(group);
                }
                else {
                    reject('no group found');
                }

            }
            catch (error) {
                reject(error.message);
            }
        })
    }

}

export const groupStore = new GroupStore();