import { observable, action } from 'mobx';
import { Parse, Pointer, toParseObject } from 'helpers/Parse';
import LoadPromise from 'helpers/LoadPromise';
import SaveableStore from './SaveableStore';
import _ from 'lodash';

interface SaveableInterface {
    loggedInUserRole: any
}

export enum CustomerRoleAccessAreaEnum {
    FITNESS = 'ATAccessFitness',
    CLUB_SPORT = 'ATAccessClubSport',
    MAIN = 'ATAccessMain',
    BOOKINGS = 'ATAccessBookings'
}

export class CustomerRoleStore extends SaveableStore<SaveableInterface> {

    @observable loggedInUserRole: any = null;
    @observable roles: any = [];

    constructor() {
        super('CustomerRoleStore');
    }

    @action
    resetStore() {
        this.loggedInUserRole = null;
        this.roles = [];
    }

    @action
    getCustomerRoles(customerId: string, userId?: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const customerRoleQuery = new Parse.Query('CustomerRoles');
                customerRoleQuery.equalTo('customer', Pointer('Customer', customerId));

                if (userId) {
                    customerRoleQuery.equalTo('user', Pointer('_User', userId));

                }

                const res = await customerRoleQuery.find();
                if (res) {
                    const loggedInUser = Parse.User.current() as Parse.Object;
                    const me = _.find(res, (cr) => cr.get('user').id === loggedInUser.id);
                    if (me) {
                        this.loggedInUserRole = me;
                    }

                    this.roles = res;

                    resolve(this.roles);
                }
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    deleteCustomerRole(customerId: string, userId: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const customerRoleQuery = new Parse.Query('CustomerRoles');
                customerRoleQuery.equalTo('customer', Pointer('Customer', customerId));
                customerRoleQuery.equalTo('user', Pointer('_User', userId));

                const entity = await customerRoleQuery.first();
                if (entity) {
                    await entity.destroy();
                }

                resolve();
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    createCustomerRole(customerId: string, userId: string, accessArea?: string, limitedAccess?: boolean) {
        return new Promise(async (resolve, reject) => {
            try {
                const CustomerRole = Parse.Object.extend('CustomerRoles');
                const customerRole = new CustomerRole();
                customerRole.set('customer', Pointer('Customer', customerId));
                customerRole.set('user', Pointer('_User', userId));

                if (accessArea) {
                    customerRole.set('accessArea', accessArea);
                }

                if (limitedAccess) {
                    customerRole.set('limitedAccess', limitedAccess);
                }

                const res = await customerRole.save();

                if (res) {
                    res.get('user').id = userId;
                    this.roles = [...this.roles, res];
                    resolve(res);
                }
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    protected setSaveableData(data: SaveableInterface) {
        this.loggedInUserRole = toParseObject(data.loggedInUserRole, 'CustomerRoles');
    }

    protected getSaveableData(): SaveableInterface {
        return {
            loggedInUserRole: this.loggedInUserRole
        }
    }

}

export const customerRoleStore = new CustomerRoleStore();