import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown, { DropdownOptionInterface } from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';
import { authStore, groupStore, mainGroupStore } from 'stores';
import { Parse } from 'helpers/Parse';
import _ from 'lodash';

interface Props {
    fieldState: FieldState<any>
}

@observer class SelectGroup extends React.Component<Props> {

    componentDidMount() {
        groupStore.getGroups(authStore.customer.id);
    }

    componentWillUnmount() {
        groupStore.resetStore();
    }

    mapGroups(items: Array<Parse.Object>) {
        const { fieldState } = this.props;
        const mainGroupId = mainGroupStore.mainGroupId;
        
        var groups: Array<DropdownOptionInterface> = [];

        if (items) {
            items.forEach(function(element: Parse.Object) {
                if (element.id === fieldState.value || element.get('active') === true) {
                    let groupName = element.get('name');

                    if (element.id === mainGroupId) {
                        groupName = '-- Main group --';
                    }

                    groups.push({
                        name: groupName,
                        value: element.id
                    });
                }
            });
        }

        return _.orderBy(groups, 'name');
    }

    render() {
        const { fieldState } = this.props;
        const opts = this.mapGroups(groupStore.groups);
    
        return (
            <Dropdown
                placeholderText='Select group'
                className='Dropdown-light'
                fieldState={fieldState}
                options={opts}
            />
        )
    }

}

export default SelectGroup;