import * as React from 'react';

import './style.css';

interface Props {
    className?: string
}

class Separator extends React.Component<Props> {

    render() {
        const { className } = this.props;

        return (
            <div className={`Separator ${className || ''}`} />
        )
    }

}

export default Separator;