import { observable, action } from 'mobx';
import Parse from 'parse';
import { Pointer } from 'helpers/Parse';
import { replaceObjectById } from 'helpers/CollectionHelper';
import LoadPromise from 'helpers/LoadPromise';
import Moment from 'moment';

export interface UpdateCourtInterface {
    id: string | null,
    name: string | null,
    info: string | null,
    reminderShouldSend: boolean,
    reminderMinsBefore: number | null,
    timeslotLimit: number | null,
    timeslotDuration: number | null,
    timeslots: string | null,
    bookingAvailableDaysFromStart: number | null,
    bookingStartDate: any,
    bookingEndDate: any,
    timeslotBuffer: number | null,
    sportKey: string | null
}

export class CourtStore {

    @observable courts: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.courts = [];
    }

    @action
    getCourts(customerId: string, activeOnly: boolean) {
        return LoadPromise(async (resolve, reject) => {
            try {
                var venueQuery = new Parse.Query('Venue');
                venueQuery.equalTo('customer', Pointer('Customer', customerId));
                venueQuery.equalTo('active', true);
                
                const venues = await venueQuery.find();

                var courtQuery = new Parse.Query('Court');
                courtQuery.containedIn('venue', venues);
                courtQuery.include('venue');
                courtQuery.limit(10000);

                if (activeOnly) {
                    courtQuery.equalTo('active', true);
                }

                const courts = await courtQuery.find();
                this.courts = courts;
                
                resolve(courts);
            }
            catch(error) {
                reject(error.message);
            }
        })
    }

    @action
    getCourt(id: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const courtQuery = new Parse.Query('Court');

                const court = await courtQuery.get(id);
                if (court) {
                    this.courts = [court];
                    resolve(court);
                }
            }
            catch (error) {
                reject(error.message);
            }

        })
    }

    mapCourt(court: Parse.Object, opts: UpdateCourtInterface) {
        court.set('name', opts.name);
        court.set('info', opts.info);
        court.set('reminderMinsBefore', opts.reminderMinsBefore);
        court.set('reminderShouldSend', opts.reminderShouldSend);
        court.set('timeslotLimit', opts.timeslotLimit);
        court.set('timeslotDuration', opts.timeslotDuration);
        court.set('timeslots', opts.timeslots);
        court.set('bookingAvailableDaysFromStart', opts.bookingAvailableDaysFromStart);
        court.set('sportKey', opts.sportKey);

        if (opts.timeslotBuffer) {
            court.set('timeslotBuffer', opts.timeslotBuffer);
        }
        else {
            court.set('timeslotBuffer', 0);
        }
        
        if (opts.bookingStartDate) {
            court.set('bookingStartDate', Moment(opts.bookingStartDate).format('YYYY-MM-DD'));
        }
        else {
            court.set('bookingStartDate', null);
        }

        if (opts.bookingEndDate) {
            court.set('bookingEndDate', Moment(opts.bookingEndDate).format('YYYY-MM-DD'));
        }
        else {
            court.set('bookingEndDate', null);
        }

    }

    @action
    createCourt(venueId: string, opts: UpdateCourtInterface) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const Court = Parse.Object.extend('Court');
                const court = new Court();

                court.set('venue', Pointer('Venue', venueId));
                court.set('active', true);
                this.mapCourt(court, opts);

                await court.save();
                resolve(court);
            }
            catch(error) {
                reject(error.message);
            }
        })
    }

    @action
    updateCourt(opts: UpdateCourtInterface) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const courtQuery = new Parse.Query('Court');
                const court = await courtQuery.get(opts.id!);

                this.mapCourt(court, opts);

                await court.save();
                resolve(court);
            }
            catch(error) {
                reject(error.message);
            }
        })
    }

    @action
    toggleActive(courtId: string, active: boolean) {
        return new Promise(async (resolve, reject) => {
            try {
                var courtQuery = new Parse.Query('Court');
                courtQuery.equalTo("objectId", courtId);

                const court = await courtQuery.first();
                if (court) {
                    court.set('active', active);
                    await court.save();

                    this.courts = replaceObjectById(this.courts, court);
                    resolve(court);
                }
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

}

export const courtStore = new CourtStore();