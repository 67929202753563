import { observable, action } from "mobx";

interface ToastInterface {
    message: string,
    title: string,
    level: string,
    position?: string,
    autoDismiss?: number
}

export class ToastStore {

    @observable toasts: Array<ToastInterface> = [];

    @action
    addError(message: string) {
        this.addToast({
            title: 'Error',
            level: 'error',
            message: message
        });
    }

    @action
    addToast(toast: ToastInterface) {
        if (!toast.position) {
            toast.position = 'tc';
        }

        this.toasts.push(toast);
    }

    @action
    resetToasts() {
        this.toasts = [];
    }
}

export const toastStore = new ToastStore();