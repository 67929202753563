import * as React from 'react';
import { observer } from 'mobx-react';

import './style.css';

interface Props {
    imageUrl?: string,
    title: string,
    afterTitle?: string,
    subTitle?: string
}

@observer class AvatarCell extends React.Component<Props> {

    render() {
        const { imageUrl, title, subTitle, afterTitle } = this.props;

        return (
            <span className='AvatarCell flex items-center'>
                {
                    imageUrl &&
                    <img className='AvatarCell-image' src={imageUrl} alt='Avatar' width='42' height='42' />
                }

                <div>
                    <p className='AvatarCell-title text-darkest font-medium whitespace-normal'>
        <span className='mr-2'>{title}</span> {afterTitle && <span className='text-accepted text-xs'>({afterTitle})</span>}
                    </p>

                    {
                        subTitle &&
                        <p className='AvatarCell-subTitle text-inactive'>
                            {subTitle}
                        </p>
                    }
                </div>



            </span>
        );
    }

}

export default AvatarCell;