import * as React from 'react';
import { observer } from 'mobx-react';
import ActivityForm, { EditActivityDataInterface } from 'components/Forms/ActivityForm';
import { navigationStore, recurringActivityStore } from 'stores';
import Moment from 'moment';
import ActionButton from 'components/ActionButton';

@observer class EditRecurringActivityPage extends React.Component<any> {

    componentDidMount() {
        const activityId = this.getActivityId();

        recurringActivityStore.getRecurringActivityDetails(activityId);
        navigationStore.setShowSecondaryMenu(false);
    }

    componentWillUnmount() {
        navigationStore.setShowSecondaryMenu(true);
    }

    onSubmit = async (data: any) => {
        await recurringActivityStore.editRecurringActivity(this.getActivityId(), data);
        navigationStore.goBack();
    }

    getActivityId() {
        return this.props.match.params.activityId;
    }

    cancelRecurringActivity = async () => {
        if (window.confirm('Are you sure you want to delete this template? All activities associated will be cancelled')) {
            await recurringActivityStore.deleteRecurringActivity(this.getActivityId());
            navigationStore.goBack();
        }
    }

    mapParseRecurringGameToObject(activity: Parse.Object) {
        const data: EditActivityDataInterface = {
            isRecurring: true
        };

        const gameData = activity.get('recurringGameData');

        data.customTitle = gameData.get('customTitle');
        data.groupId = gameData.get('group').id;
        data.hostId = gameData.get('host').id;
        data.sportKey = gameData.get('sportKey');
        data.minSkillLevel = gameData.get('minSkillLevel');
        data.maxSkillLevel = gameData.get('maxSkillLevel');
        data.gameMode = gameData.get('gameMode');
        data.venueId = gameData.get('venue') ? gameData.get('venue').id : undefined;
        data.note = gameData.get('note');
        data.athletesHidden = gameData.get('athletesHidden');
        data.minPlayers = gameData.get('minPlayers');
        data.maxPlayers = gameData.get('maxPlayers');
        data.gameStartTimeOfDay = Moment(activity.get('gameStartTimeOfDay'), 'HH:mm').toDate();
        data.gameDeadlineOption = activity.get('gameDeadlineOption');
        data.intervalOption = activity.get('intervalOption');
        data.firstDate = activity.get('firstDate');
        data.lastDate = activity.get('lastDate');
        data.gameDayOfWeek = activity.get('gameDayOfWeek');
        data.gameDurationMinutes = activity.get('gameDurationMinutes');
        data.hostHoursBeforeGameStart = activity.get('hostHoursBeforeGameStart');

        return data;
    }

    render() {
        const activity = recurringActivityStore.recurringActivityDetails;
        if (!activity) return null;

        const data = this.mapParseRecurringGameToObject(activity);

        return (
            <div className='w-full'>
                <ActivityForm onSubmit={this.onSubmit} editData={data} additionalButtons={<ActionButton width='narrow' type='button' text='Delete' onClick={this.cancelRecurringActivity} />} />
            </div>

        )
    }

}

export default EditRecurringActivityPage;