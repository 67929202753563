import { observable, action } from 'mobx';
import * as Parse from 'parse';
import { Pointer } from 'helpers/Parse';
import { GroupMemberStatusEnum } from './FitnessStore';
import { groupMemberStore } from './GroupMemberStore';
import { mainGroupStore } from './MainGroupStore';

export class MemberDetailsStore {

    @observable memberDetails: Parse.Object | null = null;
    @observable memberships: Array<Parse.Object> = [];
    @observable noShowActivities: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.memberDetails = null;
        this.memberships = [];
        this.noShowActivities = [];
    }

    @action
    getNoShowActivities(userId: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const knucklesQuery = new Parse.Query('Knuckles');
                knucklesQuery.equalTo('user', Pointer('_User', userId));
                knucklesQuery.descending('createdAt');
                knucklesQuery.equalTo('noShow', true);
                knucklesQuery.limit(99999);

                knucklesQuery.include('game');

                const knuckles = await knucklesQuery.find();

                const activities = knuckles.map((k) => {
                    return k.get('game');
                })

                this.noShowActivities = activities;
            }
            catch(error) {
                reject(error.message);
            }
        })
    }

    @action
    deleteMembership(memberId: string) {
        return new Promise(async (resolve, reject) => {
            try {

                var groupMemberQuery = new Parse.Query('GroupMember');
                groupMemberQuery.equalTo('objectId', memberId);

                const member = await groupMemberQuery.first();
                if (member) {
                    await member.destroy();

                    this.memberships = this.memberships.filter((item: any) => {
                        return item.id !== memberId;
                    })

                    resolve(member);
                }
                reject();
            }
            catch (error) {
                reject(error.message);
            }
        });
    }

    @action
    addMemberToGroup(userId: string, groupId: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const membership = await Parse.Cloud.run('requestAddGroupMember', {
                    groupId: groupId,
                    userId: userId
                })

                this.memberships.push(membership);

                // If logged in user making this request, is not Admin in the group the member is being added to
                // the default status is Pending. We want to force a new membership, so therefore we change pending memberships
                // to members right away.
                const status = membership.get('memberStatus');
                if (status === GroupMemberStatusEnum.PENDING) {
                    groupMemberStore.setGroupMemberStatus(membership.id, GroupMemberStatusEnum.MEMBER);
                }

                resolve(membership);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    updateInstructorStatus(memberId: string, status: boolean) {
        return new Promise(async (resolve, reject) => {
            if (!mainGroupStore.mainGroupId) {
                reject();
                return;
            }

            const query = new Parse.Query('GroupMember');
            query.equalTo('objectId', memberId);
            const membership = await query.first();

            if (membership) {
                membership.set('instructor', status);
                membership.save();
                resolve(membership);
            }

            reject();
        })
    }

    @action
    getMemberDetails(userId: string, customerId: string) {
        return new Promise(async (resolve, reject) => {
            try {
                //
                // We want a custom mapping, that is based on a user object with
                // userSports and groups added as arrays
                //

                // first we get the user
                var userQuery = new Parse.Query('_User');

                const user = await userQuery.get(userId) as any;

                // Then we get userSports
                var userSportQuery = new Parse.Query('UserSport');
                userSportQuery.equalTo('user', Pointer('_User', userId));

                const userSports = await userSportQuery.find();
                user.userSports = userSports;

                // Finally we need to retrieve the groups through GroupMember table
                var groupMemberQuery = new Parse.Query('GroupMember');
                groupMemberQuery.equalTo('user', Pointer('_User', userId));
                groupMemberQuery.exists('group');
                groupMemberQuery.include('group');

                const members = await groupMemberQuery.find();

                // Skip groups and activities that are not attached to current customer
                var groupIds = [];
                var groupMembers = [];

                for (const member of members) {
                    var group = member.get('group');

                    var affiliation = group.get('customerAffiliation');
                    if (affiliation && affiliation.id === customerId) {
                        groupMembers.push(member);
                        groupIds.push(group.id);
                    }
                }

                this.memberships = groupMembers;

                // Get game history
                var gamePlayerQuery = new Parse.Query('GamePlayer');
                gamePlayerQuery.equalTo('playerStatus', 'ATPlayerStatusAccepted');
                gamePlayerQuery.include('game');
                // gamePlayerQuery.include('group');
                gamePlayerQuery.equalTo('user', Pointer('_User', userId));
                gamePlayerQuery.limit(10000);

                const gamePlayers = await gamePlayerQuery.find();
                const filteredGamePlayers = [];

                for (const gamePlayer of gamePlayers) {
                    if (gamePlayer.get('game') && gamePlayer.get('game').get('group')) {
                        if (groupIds.includes(gamePlayer.get('game').get('group').id)) {
                            filteredGamePlayers.push(gamePlayer);
                        }
                    }
                }

                user.gamePlayers = filteredGamePlayers;

                this.memberDetails = user;
                resolve(user);
            }
            catch (error) {
                reject(error.message);
            }
        });
    }
}

export const memberDetailsStore = new MemberDetailsStore();