import * as React from 'react';
import { observer } from 'mobx-react';
import { recurringActivityStore } from 'stores';
import SearchCombo from 'components/Forms/SearchCombo';
import { filterRecurringActivitiesByHost, filterRecurringActivitiesByTitle } from './SearchRecurringActivityHelper';

@observer class SearchRecurringActivity extends React.Component {

    render() {

        const allActivities = recurringActivityStore.recurringActivities;

        const options = [
            {
                value: 'host',
                name: 'Host',
                filterFunc: (searchValue: string) => recurringActivityStore.setFilteredRecurringActivities(filterRecurringActivitiesByHost(allActivities, searchValue))
            },
            {
                value: 'title',
                name: 'Title',
                filterFunc: (searchValue: string) => recurringActivityStore.setFilteredRecurringActivities(filterRecurringActivitiesByTitle(allActivities, searchValue))
            }
        ]

        return (
            <SearchCombo
                placeholder='Search activities'
                onClearFiltered={() => recurringActivityStore.setFilteredRecurringActivities(allActivities)}
                options={options} />
        )
    }

}

export default SearchRecurringActivity;