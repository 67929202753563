import * as React from 'react';
import { observer } from 'mobx-react';
import { CustomerRoleAccessAreaEnum, navigationStore } from 'stores';
import { Parse } from 'helpers/Parse';
import Header from './Header';
import Body from './Body';
import LoadingIndicator from 'components/LoadingIndicator';
import { canOnlyAccess } from 'helpers/VerticalHelper';

@observer class DashboardPage extends React.Component {

    constructor(props: any) {
        super(props);

        if (!Parse.User.current()) {
            navigationStore.replace('/login');
        }

        if (canOnlyAccess(CustomerRoleAccessAreaEnum.BOOKINGS)) {
            navigationStore.replace('bookings');
        }
    }

    render() {
        const { children } = this.props;

        return (
            <div>
                <Header />
                <Body>
                    {children}
                </Body>
                <LoadingIndicator />
            </div>
        )
    }

}

export default DashboardPage;