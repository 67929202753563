import { Validator } from 'formstate';

export const required = (val: any) => !val && 'Required';

export const isNumber = (val: any) =>  {

    if (isNaN(val)) {
        return 'Only numbers'
    }

    return null;
}

export const isZeroOrAbove = (val: any) =>  {

    if (isNaN(val)) {
        return 'Only numbers'
    }

    if (val < 0) {
        return 'Not below zero';
    }

    return null;
}

export const isPositiveInteger = (val: any) =>  {

    if (isNaN(val)) {
        return 'Only numbers'
    }

    if (val < 1) {
        return 'Must be at least 1';
    }

    return null;
}




export const isEmail: Validator<any> = (value: string) => {
    // Empty values are not invalid emails
    if (!value) return null;
    if (required(value)) return null;
    value = value.trim();
    // Src : http://emailregex.com/
    //eslint-disable-next-line
    if (!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.exec(value)) {
        return 'Invalid email'
    }
    return null;
}