import * as React from 'react';
import { observer } from 'mobx-react';
import Flatpickr from 'react-flatpickr';
import { FieldState } from 'formstate';
import Moment from 'moment';

import './style.css';
import { BaseOptions } from 'flatpickr/dist/types/options';

export enum DateInputThemeEnum {
    DARK,
    LIGHT
}

interface Props {
    fieldState: FieldState<any>,
    beforeChange?: Function,
    theme?: DateInputThemeEnum,
    afterChange?: Function
    placeholder?: string,
    options?: Partial<BaseOptions>,
    style?: React.CSSProperties
}

@observer class DateInput extends React.Component<Props> {

    onChange = (value: any) => {
        const { beforeChange, fieldState, afterChange } = this.props;

        const val = value[0];

        if (beforeChange) {
            beforeChange(val);
        }

        fieldState.onChange(val);

        if (afterChange) {
            afterChange(val);
        }

    }

    render() {
        const { fieldState, theme, placeholder, options, style } = this.props;
        const editValue = fieldState.value ? Moment(fieldState.value).local().toISOString() : undefined;
        const className = theme === DateInputThemeEnum.DARK ? 'DateInput-dark' : 'DateInput-light';

        return (
            <div className={`${className} w-full`} style={style}>
                <Flatpickr
                    options={options}
                    placeholder={placeholder}
                    value={editValue}
                    onChange={this.onChange}
                />
            </div>
        )
    }

}

export default DateInput;