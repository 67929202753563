import { observable, action } from 'mobx';
import { Parse, Pointer } from 'helpers/Parse';
import { CustomerGroupTypeEnum } from './FitnessStore';
import SaveableStore from './SaveableStore';

interface SaveableInterface {
    mainGroupId: string | null,
    mainGroupCount: number
}

export class MainGroupStore extends SaveableStore<SaveableInterface> {

    @observable mainGroupId: string | null = null;
    @observable mainGroupCount: number = 0;

    constructor() {
        super('MainGroupStore');
    }

    @action
    resetStore() {
        this.mainGroupId = null;
        this.mainGroupCount = 0;
    }

    @action
    getMainGroupId(customerId: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            try {
                const groupQuery = new Parse.Query('Group');
                groupQuery.equalTo('customerAffiliation', Pointer('Customer', customerId));
                groupQuery.equalTo('customerGroupType', CustomerGroupTypeEnum.MAIN);

                const res = await groupQuery.first();
                if (res) {
                    this.mainGroupId = res.id;

                    const queryMembers = new Parse.Query("GroupMember");
                    queryMembers.equalTo("group", res);
                    queryMembers.containedIn("memberStatus", ["ATGroupMemberStatusAdmin", "ATGroupMemberStatusMember"]);

                    const count = await queryMembers.count();
                    this.mainGroupCount = count;

                    resolve(res.id);
                }
                else {
                    reject('no main group');
                }
            }
            catch(error) {
                reject(error.message);
            }
        })
    }

    protected setSaveableData(data: SaveableInterface): void {
        this.mainGroupId = data.mainGroupId;
        this.mainGroupCount = data.mainGroupCount;
    }

    protected getSaveableData(): SaveableInterface {
        return {
            mainGroupId: this.mainGroupId,
            mainGroupCount: this.mainGroupCount
        }
    }

}

export const mainGroupStore = new MainGroupStore();