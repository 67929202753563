import * as React from 'react';
import { observer } from 'mobx-react';
import Moment from 'moment';

import { fitnessStore, navigationStore, GroupDetailsTopMenuEnum } from 'stores';
import Table from 'components/Table';
import AvatarCell from 'components/Table/AvatarCell';
import { CellInfo } from 'react-table';
import { getImage } from 'helpers/SportImageHelper';
import { readableActivity } from 'helpers/ActivitiesHelper';
import Separator from 'components/Separator';
import SearchActivity from 'components/SearchActivity';
import ReactSwitch from 'react-switch';
import TablePlaceholder from 'components/TablePlaceholder';
import NoActivitiesImage from './NoActivities.svg';

interface State {
    archived?: boolean
}

@observer class ActivitiesPage extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            archived: false
        }
    }

    getActivities = () => {
        const { archived } = this.state;
        fitnessStore.getActivities(this.getGroupIdByParams(), { archived: archived });
    }

    componentDidMount() {
        this.getActivities();
        fitnessStore.setSelectedTopMenu(GroupDetailsTopMenuEnum.ACTIVITIES);
    }

    componentWillReceiveProps(nextProps: any) {
        const nextGroupId = nextProps.match.params.groupId;
        if (nextGroupId) {
            this.setState({ archived: false });
            fitnessStore.getActivities(nextGroupId);
        }
    }

    onTableRowClicked = (activity: Parse.Object) => {
        navigationStore.push(`/activitydetails/${activity.id}`);
    }

    getGroupIdByParams = () => {
        return this.props.match.params.groupId;
    }

    onArchivedToggled = () => {
        this.setState({ archived: !this.state.archived }, this.getActivities);
    }

    render() {
        const { archived } = this.state;

        const columns = [
            {
                Header: 'Sport',
                Cell: (cellInfo: CellInfo) => {
                    const row = cellInfo.original as Parse.Object;
                    const sportKey = row.get('sportKey');

                    const isRecurring = row.get('recurringGame');

                    return <AvatarCell
                        imageUrl={getImage(sportKey)}
                        title={row.get('customTitle')}
                        afterTitle={isRecurring ? 'Recurring' : undefined}
                        subTitle={readableActivity(sportKey)}
                    />
                },
            },
            {
                Header: 'Date',
                id: 'gameStart',
                width: 210,
                accessor: (d: Parse.Object) => {
                    return Moment(d.get('gameStart'))
                        .local()
                        .format('MM-DD-YYYY hh:mm A');
                },
                sortMethod: (a: any, b: any, desc: any) => {
                    if (Moment(a, "MM-DD-YYYY hh:mm A").isBefore(Moment(b, "MM-DD-YYYY hh:mm A"))) {
                        return -1;
                    }
                    else {
                        return 1;
                    }
                }
            },
            {
                Header: 'Host',
                id: 'host',
                minWidth: 50,
                accessor: (d: Parse.Object) => d.get('host').get('name')
            },
            {
                Header: 'Venue',
                minWidth: 50,
                id: 'venue',
                accessor: (d: Parse.Object) => d.get('venue') ? d.get('venue').get('name') : 'No'
            }
        ]

        return (
            <div className='w-full'>
                <div className='mt-5'>
                    <SearchActivity groupId={this.getGroupIdByParams()} archived={archived} />
                    <Separator className='mt-5 mb-5' />

                    <div className='w-full text-right mb-4'>
                        <ReactSwitch
                            checked={archived || false}
                            onChange={this.onArchivedToggled}
                            checkedIcon={false}
                            uncheckedIcon={false}
                            height={22}
                            width={38}
                            id='archived'
                            offColor='#A2A7B0'
                            onColor='#BFD730'
                        /> <label htmlFor='archived' className=' cursor-pointer relative text-inactive' style={{ top: '-5px', paddingLeft: '5px' }}>Archived</label>
                    </div>

                    {
                        fitnessStore.activities.length === 0 &&
                        <TablePlaceholder
                            imageSrc={NoActivitiesImage}
                            title='no activities'
                            description='No upcoming activities. Create your next activity by clicking Create Activity in the top right corner.'
                        />
                    }

                    {
                        fitnessStore.activities.length > 0 &&
                        <Table
                            onRowClicked={this.onTableRowClicked}
                            data={fitnessStore.activities}
                            columns={columns} />
                    }

                </div>
            </div>
        )
    }

}

export default ActivitiesPage;