import * as React from 'react';
import { observer } from 'mobx-react';
import BackButton from 'components/BackButton';
// import ActionButton from 'components/ActionButton';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import Input from 'components/Forms/Input';
import { groupPostDetailsStore } from 'stores';
import SectionTitle from 'components/SectionTitle';
import { FormState, FieldState } from 'formstate';
import ActionButton from 'components/ActionButton';
import { Parse } from 'helpers/Parse';
import Moment from 'moment';

@observer class EditPostPage extends React.Component<any> {

    form = new FormState({
        comment: new FieldState('')
    })

    getPostId() {
        return this.props.match.params.postId;
    }

    componentDidMount() {
        const postId = this.getPostId();
        groupPostDetailsStore.getPost(postId);
        groupPostDetailsStore.getComments(postId);
    }

    componentWillUnmount() {
        groupPostDetailsStore.resetStore();
    }

    onAddComment = async () => {
        const comment = this.form.$.comment.$;

        if (!comment) {
            alert('Comment cannot be empty.');
            return;
        }

        await groupPostDetailsStore.addComment(Parse.User.current()!.id, this.getPostId(), comment);
        window.location.reload();
        // navigationStore.goBack();
    }

    render() {
        const post = groupPostDetailsStore.post;
        if (!post) return null;

        const comments = groupPostDetailsStore.comments;

        return (
            <div className='w-full'>
                <div className='text-right mb-8'>
                    <div className='flex'>
                        <BackButton />

                        {/* <div className="text-right w-full">
                            <ActionButton primary={true} width='wide' text='Save changes' onClick={this.onSaveChanges} />
                        </div> */}
                    </div>
                </div>

                <Section>
                    <SectionItem title='Original message' hideSeparator={true}>
                        <Input readOnlyValue={post.get('text')} textArea={true} />
                    </SectionItem>
                </Section>

                <SectionTitle title='Replies' />

                <Section className='mt-4'>

                    {
                        comments.map((comment) => {
                            const when = Moment(comment.get('createdAt')).local().format('MM-DD-YYYY hh:mm A');

                            return (
                                <SectionItem title={comment.get('createdBy').get('name')}>
                                    <Input readOnlyValue={comment.get('text')} textArea={true} />
                                    <p className='mt-2 text-xs text-inactive text-right'>{when}</p>
                                </SectionItem>
                            )
                        })
                    }

                    <div className={comments.length > 0 ? 'mt-8' : ''} />

                    <SectionItem title='You' hideSeparator={true}>
                        <div className='flex w-full'>
                            <Input fieldState={this.form.$.comment} placeholder='Write reply...' />
                            <ActionButton className='ml-2' type='button' text='Send' width='wrap' primary={true} onClick={this.onAddComment} />
                        </div>

                    </SectionItem>
                </Section>

            </div>
        )
    }
}

export default EditPostPage;