import * as React from 'react';
import { observer } from 'mobx-react';
import { Parse } from 'helpers/Parse';
import { getImage } from 'helpers/SportImageHelper';
import { activities } from 'helpers/ActivitiesHelper';
import Table from 'components/Table';
import AvatarCell from 'components/Table/AvatarCell';
import _ from 'lodash';
import SectionTitle from 'components/SectionTitle';

interface ItemInterface {
    imageSrc: string,
    text: string
}

interface Props {
    userSports: Array<Parse.Object>
}

@observer class Sports extends React.Component<Props> {

    mapUserSports(userSports: Array<Parse.Object>) {
        var list: Array<ItemInterface> = [];

        userSports.forEach((sport, i) => {
            const sportKey = sport.get('sportKey');
            const sportImage = getImage(sportKey)
            var readableSport = activities[sportKey];

            list.push({
                imageSrc: sportImage,
                text: readableSport
            });
        });

        return _.orderBy(list, 'text');

    }

    render() {
        const { userSports } = this.props;
        const data = this.mapUserSports(userSports);
        if (data.length === 0) return null;

        const columns = [
            {
                Header: '',
                id: 'sport',
                accessor: (item: ItemInterface) => {
                    return <AvatarCell imageUrl={item.imageSrc} title={item.text} />
                }
            }
        ]

        return (
            <div>
                <SectionTitle title='Sports' skipMarginBottom={true} />

                <Table
                    data={data}
                    columns={columns}
                    condensed={true} />

            </div>
        )
    }

}

export default Sports;