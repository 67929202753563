import Moment from 'moment';

export function DateColumn(id: string, title: string, dateFunc: any) {

    return {
        Header: title,
        id: id,
        minWidth: 50,
        accessor: (d: any) => {
            return Moment(dateFunc(d))
                .local()
                .format('MM-DD-YYYY');
        },
        sortMethod: (a: any, b: any, desc: any) => {
            if (Moment(a, "MM-DD-YYYY").isBefore(Moment(b, "MM-DD-YYYY"))) {
                return -1;
            }
            else {
                return 1;
            }
        }
    }
}