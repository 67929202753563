import * as React from 'react';
import { observer } from 'mobx-react';
import Separator from 'components/Separator';
import Table from 'components/Table';
import { groupMemberStore, navigationStore, authStore, loadingStore } from 'stores';
import _ from 'lodash';
import AvatarCell from 'components/Table/AvatarCell';
import { CellInfo } from 'react-table';
import SearchCustomerMembers from 'components/SearchCustomerMembers';

import NoMembersImage from './NoMembers.svg';
import TablePlaceholder from 'components/TablePlaceholder';

interface Props {
    groupId?: string,
    selectable?: boolean,
    onSelectedChanged?: Function,
    children?: React.ReactNode,
    onRowClicked?: Function
}

@observer class Members extends React.Component<Props> {

    componentDidMount() {
        groupMemberStore.resetMembers();
    }

    mapGroupMembersToUsers(groupMembers: Array<Parse.Object>) {
        const { groupId } = this.props;
        var list: Array<Parse.Object> = [];
        let alreadyMemberUserIds: Array<string> = [];

        groupMembers.forEach((member) => {
            var user = member.get('user');
            user.groups = [];
            const memberStatus = member.get('memberStatus');

            if (groupId) {
                var alreadyMember = member.get('group').id.indexOf(groupId) !== -1;
                if (alreadyMember) {
                    alreadyMemberUserIds.push(user.id);
                    return; // no need to proceed
                }
            }

            if (memberStatus === 'ATGroupMemberStatusMember'
                || memberStatus === 'ATGroupMemberStatusAdmin'
            ) {
                var result = _.find(list, { id: member.get('user').id }) as any;
                const groupName = member.get('group').get('name');
                if (result) {
                    result.groups.push(groupName)
                }
                else {
                    user.groups.push(groupName);
                    list.push(user);
                }
            }

        });

        const filteredUsers = list.filter((l) => !alreadyMemberUserIds.includes(l.id));
        const orderedUsers = _.orderBy(filteredUsers, (u) => u.get('name'));

        return orderedUsers;
    }

    showAllMembers = () => {
        if (window.confirm('Are you sure you want to load all members at once? Consider using the search option instead.')) {
            groupMemberStore.getMembers(authStore.customer.id, true);
        }
    }

    onRowClicked = (row: any) => {
        const userId = row.id;
        navigationStore.push(`/memberdetails/${userId}`);
    }

    render() {
        const { selectable, onSelectedChanged, children, onRowClicked } = this.props;
        const data = this.mapGroupMembersToUsers(groupMemberStore.members);

        const columns = [
            {
                Header: 'Name',
                id: 'name',
                Cell: (cellInfo: CellInfo) => {
                    const user = cellInfo.original as Parse.Object;
                    const name = user.get('name') || 'No name';
                    const avatar = user.get('avatar') ? user.get('avatar')._url : null;

                    return <AvatarCell
                        imageUrl={avatar}
                        title={name}
                    />
                }
            },
            {
                Header: 'Email',
                id: 'email',
                accessor: (d: any) => d.get('username')
            },
            {
                Header: 'Groups',
                id: 'groups',
                accessor: (d: any) => _.sortBy(d.groups).join(", ")
            }
        ]

        return (
            <div className='w-full'>
                <div className='mt-5'>
                    <SearchCustomerMembers groupId={authStore.customer.id} isCustomer={true} />
                    <Separator className='mt-5 mb-5' />
                    {children}

                    {
                        data.length === 0 &&
                        <div className='w-full'>
                            <TablePlaceholder
                                imageSrc={NoMembersImage}
                            />
                            {
                                !loadingStore.isLoading &&
                                <div className='text-center'>
                                    <button className=' mt-4 text-white p-2 border rounded' onClick={this.showAllMembers}>Show all members</button>
                                </div>
                            }

                        </div>
                    }

                    <Table
                        onSelectedChanged={onSelectedChanged}
                        selectable={selectable}
                        onRowClicked={!selectable ? this.onRowClicked : onRowClicked}
                        condensed={true}
                        data={data}
                        hideOnEmpty={true}
                        columns={columns} />

                </div>
            </div>
        )
    }

}

export default Members;