import * as React from 'react';
import { observer } from 'mobx-react';
import ReactTable, { SortingRule } from 'react-table';
import _ from 'lodash';

import 'react-table/react-table.css';
import './style.css';
import SelectCell from './SelectCell';

interface ActionButtonInterface {
    label: string,
    onClick: () => void
}

interface Props {
    data: Array<any>,
    columns: Array<any>,
    condensed?: boolean,
    onRowClicked?: Function
    selectable?: boolean,
    onSelectedChanged?: Function,
    actionButton?: ActionButtonInterface,
    hideOnEmpty?: boolean,
    sorted?: Array<SortingRule>
}

interface State {
    selectedIds: Array<string>,
    selectAll: boolean
}

@observer class Table extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            selectedIds: [],
            selectAll: false
        }
    }

    selectionChanged(id: string, isSelected: boolean) {
        const { onSelectedChanged } = this.props;
        const { selectedIds } = this.state;
        console.log(selectedIds)
        var values = _.clone(selectedIds);

        if (isSelected) {
            if (!values.includes(id)) {
                values.push(id);
            }
        }
        else {
            if (values.includes(id)) {
                values = _.remove(values, (i) => {
                    return i !== id
                });
            }
        }

        this.setState({
            selectedIds: values
        });

        if (onSelectedChanged) {
            onSelectedChanged(values);
        }

    }

    selectAll = () => {
        const { selectAll } = this.state;
        const { onSelectedChanged } = this.props;

        let ids: Array<string> = [];

        if (selectAll) {
            this.setState({
                selectedIds: ids,
                selectAll: false
            })
        }
        else {
            ids = this.props.data.map((d) => d.id);
            this.setState({
                selectedIds: ids,
                selectAll: true
            })
        }

        if (onSelectedChanged) {
            onSelectedChanged(ids);
        }
    }

    render() {
        const { data, columns, condensed, onRowClicked, selectable, actionButton, hideOnEmpty, sorted } = this.props;
        const { selectedIds } = this.state;

        if (data.length === 0 && hideOnEmpty) {
            return null;
        }

        let className = '';

        if (onRowClicked || selectable) {
            className += ' ReactTable-clickable';
        }

        if (condensed) {
            className += ' ReactTable-condensed';
        }

        const selectCol = [];
        if (selectable) {
            selectCol.push({
                Header: null,
                // Header: <div style={{ marginTop: '-14px', marginBottom: '-6px', marginLeft: '18px' }}>
                //     <SelectCell id='all' checked={selectAll} onChange={this.selectAll} />
                // </div>,
                id: 'select',
                accessor: (d: any) => {
                    const checked = selectedIds.includes(d.id);

                    return <SelectCell
                        id={d.id}
                        checked={checked}
                        onChange={(id: string, selected: boolean) => this.selectionChanged(id, selected)}
                    />
                },
                width: 40,
                sortable: false,
                resizable: false
            })
        }

        return (
            <div className='Table'>
                <ReactTable
                    sorted={sorted}
                    className={className}
                    data={data}
                    columns={[...selectCol, ...columns]}
                    showPagination={false}
                    minRows={0}
                    defaultPageSize={99999}
                    NoDataComponent={() => null}
                    getTdProps={(state: any, rowInfo: any, column: any, instance: any) => {
                        return {
                            onClick: (e: React.MouseEvent, handleOriginal: any) => {
                                if (onRowClicked) {
                                    onRowClicked(rowInfo.original);
                                }

                                else if (selectable) {
                                    const obj = rowInfo.original;
                                    const checked = selectedIds.includes(obj.id);
                                    this.selectionChanged(obj.id, !checked);
                                }
                            }
                        };
                    }}
                />

                {
                    actionButton &&
                    <div onClick={actionButton.onClick} className='w-full text-white rounded pt-4 pb-4 mt-5 mb-2 cursor-pointer letter-wide TopButton'>
                        {actionButton.label}
                </div>
                }

            </div>

        )
    }

}

export default Table;