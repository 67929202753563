import * as React from 'react';
import { observer } from 'mobx-react';
import Popup from 'reactjs-popup';
import ActionButton from 'components/ActionButton';
import Form from 'components/Forms/Form';
import SectionItem from 'components/Forms/SectionItem';
import { FormState, FieldState } from 'formstate';
import Section from 'components/Forms/Section';
import _ from 'lodash';
import Moment from 'moment';
import { venueStore, bookingStore } from 'stores';
import SelectTimeSpan from './SelectTimeSpan';
import AutocompleteInput from 'components/Forms/Autocomplete';

interface Props {
    venueId: string,
    courtId: string,
    date: Date
}

@observer class CreateBooking extends React.Component<Props> {

    static DEFAULT_TIMESLOT_DURATION_MINUTES = 20;

    cancelButtonRef: any;

    form = new FormState({
        user: new FieldState<null | string>(null),
        timeFrom: new FieldState<string>('')
    })

    constructor(props: Props) {
        super(props);

        this.cancelButtonRef = React.createRef();
    }

    onSubmit = async () => {
        const { courtId, date } = this.props;
        const form = this.form.$;
        const user = form.user.$;
        const rawTimeFrom = form.timeFrom.$;

        if (!courtId || !date || !user || !rawTimeFrom) {
            alert('One or more required fields are missing.');
            return;
        }

        const court = _.find(bookingStore.courts, { id: courtId });
        const timeslotDuration = court ? court.get('timeslotDuration') : CreateBooking.DEFAULT_TIMESLOT_DURATION_MINUTES;

        const timeFrom = Moment(rawTimeFrom, 'HH:mm');
        const from = timeFrom.clone().format('HH:mm');
        const to = timeFrom.clone().add(timeslotDuration, 'minutes').format('HH:mm');

        await bookingStore.createBooking(courtId, user! as string, date, from, to);
        this.cancelButtonRef.current.props.onClick(); // To close popup
    }

    onClose = (closeFunc: Function) => {
        this.form.reset();
        closeFunc();
    }

    render() {
        const { venueId, courtId, date } = this.props;
        const court = _.find(bookingStore.courts, { id: courtId });
        if (!court) return null;

        const form = this.form.$;
        const venue = _.find(venueStore.venues, { id: venueId });
        const venueName = venue ? venue.get('name') : '';
        const courtName = court.get('name');
        const readableDate = date ? Moment(date).format('YYYY-MM-DD') : '';
        const presentationString = `${readableDate} : ${courtName} (${venueName})`;
        const timeslotDuration = court.get('timeslotDuration');
        const timeslotBuffer = court.get('timeslotBuffer');

        return (
            <div>
                <Popup
                    trigger={<ActionButton width='wide' text='Create booking' primary={true} />}
                    modal
                    closeOnDocumentClick>

                    {
                        closeFunc => (
                            <div>
                                <h2 className='text-left pl-25px pt-20px font-normal'>
                                    {presentationString}
                                </h2>
                                <Form onValidSubmit={this.onSubmit} formState={this.form}>
                                    <Section className='mt-4 mb-4'>
                                        <SectionItem titleWidth='140px' title='User'>
                                            <AutocompleteInput fieldState={form.user} />
                                        </SectionItem>
                                        <SectionItem titleWidth='140px' title='Timeslot'>
                                            <SelectTimeSpan 
                                                date={date}
                                                timeslots={JSON.parse(court.get('timeslots'))}
                                                timeslotDuration={timeslotDuration}
                                                timeslotBuffer={timeslotBuffer}
                                                from={form.timeFrom}
                                            />
                                        </SectionItem>

                                        <div className='text-right mt-4'>
                                            <ActionButton onClick={() => this.onClose(closeFunc)} text='Cancel' width='wrap' className='mr-2' ref={this.cancelButtonRef} />
                                            <ActionButton type='submit' text='Save' width='narrow' primary={true} />
                                        </div>
                                    </Section>
                                </Form>
                            </div>
                        )
                    }

                </Popup>
            </div>
        )
    }

}

export default CreateBooking;