import * as React from 'react';
import { observer } from 'mobx-react';
import BackButton from 'components/BackButton';
import Separator from 'components/Separator';
import ActionButton from 'components/ActionButton';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import Input from 'components/Forms/Input';
import { activityDetailsStore, navigationStore } from 'stores';
import { activities, skillLevels } from 'helpers/ActivitiesHelper';
import Moment from 'moment';
import Participants from './Participants';

@observer class ActivityDetailsPage extends React.Component<any> {

    componentDidMount() {
        const activityId = this.getActivityIdFromParams();

        activityDetailsStore.getActivity(activityId);
        activityDetailsStore.getParticipants(activityId);

        navigationStore.setShowSecondaryMenu(false);
    }

    getActivityIdFromParams() {
        return this.props.match.params.activityId;
    }

    componentWillUnmount() {
        navigationStore.setShowSecondaryMenu(true);
    }

    onEditActivity = () => {
        const activityId = this.getActivityIdFromParams();
        navigationStore.push(`/editactivity/${activityId}`);
    }

    onDuplicateActivity = () => {
        const activityId = this.getActivityIdFromParams();
        navigationStore.push(`/editactivity/${activityId}/duplicate`);
    }

    onCancelActivity = async () => {
        if (window.confirm("Are you sure you want to cancel this game?")) {
            const activityId = this.getActivityIdFromParams();
            await activityDetailsStore.cancelActivity(activityId);

            navigationStore.goBack();
        }
    }

    onGotoEmail = () => {
        const activityId = this.getActivityIdFromParams();
        navigationStore.push(`/activitydetails/${activityId}/email`);
    }

    onGotoMessages = () => {
        const activityId = this.getActivityIdFromParams();
        navigationStore.push(`/activitydetails/${activityId}/conversation`);
    }

    render() {
        const activity = activityDetailsStore.activity;
        if (!activity) return null;

        const activityIsHistory = activity.get('gameStatus') === 'ATGameStatusHistory';

        return (
            <div className='w-full'>
                <div className='flex'>
                    <div>
                        <BackButton />
                    </div>
                    <div className='text-right w-full'>

                        {
                            !activityIsHistory &&
                            <ActionButton
                                className='ml-6'
                                width='narrow'
                                text='Cancel activity'
                                onClick={this.onCancelActivity} />
                        }

                        <ActionButton
                            className='ml-6'
                            width='narrow'
                            text='Email'
                            onClick={this.onGotoEmail} />

                        <ActionButton
                            className='ml-6'
                            width='narrow'
                            text='Messages'
                            onClick={this.onGotoMessages} />

                        <ActionButton
                            className='ml-6'
                            primary={true}
                            width='narrow'
                            onClick={this.onDuplicateActivity}
                            text='Duplicate' />

                        {
                            !activityIsHistory &&
                            <ActionButton
                                className='ml-6'
                                primary={true}
                                width='wide'
                                onClick={this.onEditActivity}
                                text='Edit activity' />
                        }

                    </div>
                </div>

                <Separator />

                <Section className='mt-10'>
                    <SectionItem title='Activity'>
                        <Input readOnlyValue={activities[activity.get('sportKey')]} />
                    </SectionItem>
                    <SectionItem title='Skill Level'>
                        <Input readOnlyValue={`${skillLevels[activity.get('minSkillLevel')] || 'Optional'} - ${skillLevels[activity.get('maxSkillLevel')] || 'Optional'}`} />
                    </SectionItem>
                    {/* <SectionItem title='Mode'>
                        <Input readOnlyValue={modes[activity.get('gameMode')]} />
                    </SectionItem> */}
                    <SectionItem title='Group'>
                        <Input readOnlyValue={activity.get('group').get('name')} />
                    </SectionItem>
                    <SectionItem title='Host'>
                        <Input readOnlyValue={activity.get('host').get('name')} />
                    </SectionItem>

                    {
                        activity.get('venue') &&
                        <SectionItem title='Location'>
                            <Input readOnlyValue={activity.get('venue').get('name')} />
                        </SectionItem>
                    }

                    <SectionItem title='Start'>
                        <Input readOnlyValue={Moment(activity.get('gameStart')).local().format('MM-DD-YYYY hh:mm A')} />
                    </SectionItem>

                    {
                        activity.get('gameDurationMinutes') &&
                        <SectionItem title='Duration (minutes)'>
                            <Input readOnlyValue={activity.get('gameDurationMinutes')} />
                        </SectionItem>
                    }

                    {
                        activity.get('deadline') &&
                        <SectionItem title='Deadline'>
                            <Input readOnlyValue={Moment(activity.get('deadline')).local().format('MM-DD-YYYY hh:mm A')} />
                        </SectionItem>
                    }

                    <SectionItem title='Note' hideSeparator={true}>
                        <Input readOnlyValue={activity.get('note')} textArea={true} />
                    </SectionItem>
                </Section>

                <Participants activityId={activity.id} />
            </div>
        )
    }

}

export default ActivityDetailsPage;