import * as React from 'react';
import { observer } from 'mobx-react';

import './style.css';
import SectionTitle from 'components/SectionTitle';

interface Props {
    title?: string,
    children: React.ReactNode,
    className?: string,
    secondaryClassName?: string,
    secondaryStyle?: Object
}

@observer class Section extends React.Component<Props> {

    render() {
        const { title, children, className, secondaryClassName, secondaryStyle } = this.props;

        return (
            <div className={`Section w-full ${className || ''}`}>
                {
                    title &&
                    <SectionTitle title={title} />
                }
                <div className={`w-full bg-white pl-25px pr-25px rounded ${secondaryClassName || ''}`} style={secondaryStyle}>
                    {children}
                </div>
            </div>
        )
    }

}

export default Section;