import React from 'react';
import { observer } from 'mobx-react';
import { navigationStore } from 'stores';
import { ConversationListInterface, conversationStore } from 'stores/ConversationStore';
import Moment from 'moment';
import Table from 'components/Table';
import ActionButton from 'components/ActionButton';

@observer class PersonalMessagesPage extends React.Component {

    componentDidMount() {
        navigationStore.setShowSecondaryMenu(false);

        conversationStore.getPersonalConversations();
    }

    componentWillUnmount() {
        navigationStore.setShowSecondaryMenu(true);
        conversationStore.resetStore();
    }

    render() {
        const conversations = conversationStore.conversations || [];

        const columns = [
            {
                Header: 'Date',
                id: 'date',
                accessor: (d: ConversationListInterface) => {
                    return d.lastMessage ? Moment(d.lastMessage!.get('createdAt'))
                        .local()
                        .format('MM-DD-YYYY hh:mm A') : '';
                },
            },
            {
                Header: 'Last message',
                id: 'lastMessage',
                accessor: (d: ConversationListInterface) => {
                    return d.lastMessage ? d.lastMessage!.get('message') : '';
                },
            },
            {
                Header: 'Participants',
                id: 'participants',
                accessor: (d: ConversationListInterface) => {
                    return d.participants.map((p) => p.get('name')).join(', ');
                },
            }
        ]

        return (
            <div className='w-full mb-20'>
                <div className='text-right mb-6'>
                    <ActionButton width='wide' primary={true} text='New conversation' onClick={() => navigationStore.push('/create-conversation')} />
                </div>
                <Table
                    sorted={[{id: 'date', desc: true}]}
                    onRowClicked={(rowData: ConversationListInterface) => navigationStore.push(`/messages/${rowData.id}`)}
                    columns={columns}
                    data={conversations}
                    condensed={true}
                />
            </div>
        )
    }
}

export default PersonalMessagesPage;