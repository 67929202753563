import { action, observable } from 'mobx';

export class SidebarMenuStore {

    @observable visible: boolean = true;

    @action
    setVisible(visible: boolean) {
        this.visible = visible;
    }
}

export const sidebarMenuStore = new SidebarMenuStore();