import { observable, action } from 'mobx';
import * as Parse from 'parse';
import { Pointer } from 'helpers/Parse';
import { replaceObjectById } from 'helpers/CollectionHelper';
import LoadPromise from 'helpers/LoadPromise';
import { GroupMemberStatusEnum } from './FitnessStore';
import _ from 'lodash';

interface GetMembersParamsInterface {
    nameQuery?: string,
    emailQuery?: string,
    memberStatus?: Array<GroupMemberStatusEnum>
}

export class GroupMemberStore {

    @observable members: Array<Parse.Object> = [];

    @action
    getMembers(groupId: string, isCustomer?: boolean, params?: GetMembersParamsInterface, list: Array<Parse.Object> = []) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const groupQuery = new Parse.Query('Group');

                if (isCustomer) {
                    groupQuery.equalTo('customerAffiliation', Pointer('Customer', groupId));
                }
                else {
                    groupQuery.equalTo('objectId', groupId);
                }

                const groupMemberQuery = new Parse.Query('GroupMember');
                groupMemberQuery.matchesQuery('group', groupQuery);

                if (params) {

                    if (params.nameQuery) {
                        const userQuery = new Parse.Query('User');
                        userQuery.matches('name', params.nameQuery as any, 'i'); // Hack, for comparing names (lowercase)
                        
                        groupMemberQuery.matchesQuery('user', userQuery);
                    }

                    if (params.emailQuery) {
                        const userQuery = new Parse.Query('User');
                        userQuery.matches('username', params.emailQuery as any, 'i'); // Hack, for comparing names (lowercase)
                        
                        groupMemberQuery.matchesQuery('user', userQuery);
                    }

                    if (params.memberStatus) {
                        groupMemberQuery.containedIn('memberStatus', params.memberStatus);
                    }

                }

                groupMemberQuery.include('user');
                groupMemberQuery.include('group');

                groupMemberQuery.limit(1000);
                groupMemberQuery.skip(list.length)
                let members = await groupMemberQuery.find();
                let rawList = JSON.parse(JSON.stringify(members));
                rawList = rawList.concat(list);
                members = members.filter((m) => m.get('user') !== undefined);

                const orderedMembers = _.orderBy(members, (m) => m.get('user').get('name'));
                this.members = this.members.concat(orderedMembers);

                if(members.length < 1000) {
                    resolve(orderedMembers);
                } else {
                    this.getMembers(groupId, isCustomer, params, rawList);
                }

            }
            catch (error) {
                console.log(error)
                reject(error.message);
            }
        });
    }

    @action
    acceptPendingMembership(memberId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const groupMemberQuery = new Parse.Query('GroupMember');
                groupMemberQuery.equalTo('objectId', memberId);

                const member = await groupMemberQuery.first();

                if (member) {
                    member.set('memberStatus', 'ATGroupMemberStatusMember');
                    await member.save();

                    const members = replaceObjectById(this.members, member);
                    this.members = members;
                    resolve(member)
                }
                reject('member not found');
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    deleteMembership(memberId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const groupMemberQuery = new Parse.Query('GroupMember');
                groupMemberQuery.equalTo('objectId', memberId);

                const member = await groupMemberQuery.first();

                if (member) {
                    await member.destroy();
                    const members = this.members.filter((existingMember) => {
                        return existingMember.id !== memberId;
                    })

                    this.members = members;

                    resolve(member);
                }
                reject('member not found');
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    deleteGroupMembers(groupId: string, userIds: Array<string>) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const groupQuery = new Parse.Query('Group');
                groupQuery.equalTo('objectId', groupId);

                const userQuery = new Parse.Query('User');
                userQuery.containedIn('objectId', userIds);

                const groupMemberQuery = new Parse.Query('GroupMember');
                groupMemberQuery.matchesQuery('group', groupQuery);
                groupMemberQuery.matchesQuery('user', userQuery);

                const groupMembers = await groupMemberQuery.find();
                await Parse.Object.destroyAll(groupMembers);

                const members = this.members.filter((existingMember) => {
                    return !userIds.includes(existingMember.get('user').id)
                });

                this.members = members;

                resolve(members);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    deleteCustomerMembers(customerId: string, userIds: Array<string>) {
        return LoadPromise(async (resolve, reject) => {
            try {
                await Parse.Cloud.run('requestEjectFromCustomerAffiliation', {
                    customerID: customerId,
                    userIDs: userIds
                });

                const members = this.members.filter((existingMember) => {
                    return !userIds.includes(existingMember.get('user').id)
                });

                this.members = members;

                resolve(members);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    setGroupMemberStatus(memberId: string, status: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                await Parse.Cloud.run('requestGroupMemberStatusChange', {
                    groupMemberID: memberId,
                    groupMemberStatus: status
                })

                const member = _.find(this.members, { id: memberId });

                if (member) {
                    member.set('memberStatus', status);
                    this.members = this.members.map((existingMember) => {
                        return existingMember.id === member.id ? member : existingMember
                    })
                }

                resolve(memberId);

            }
            catch (error) {
                reject(error.message);
            }

        })
    }

    @action
    addUsersToGroup(groupId: string, userIds: Array<string>) {
        return LoadPromise(async (resolve, reject) => {
            try {
                await Parse.Cloud.run('requestAddGroupMembers', {
                    groupId: groupId,
                    userIds: userIds
                });

                resolve();
            }
            catch (error) {
                reject(error.message);
            }
        });
    }

    @action
    resetMembers() {
        this.members = [];
    }

}

export const groupMemberStore = new GroupMemberStore();