import * as React from 'react';
import { observer } from 'mobx-react';
import { groupPostStore, fitnessStore, navigationStore, GroupDetailsTopMenuEnum } from 'stores';
import Table from 'components/Table';
import Moment from 'moment';
import Parse from 'parse';

@observer class GroupMessagesPage extends React.Component<any> {

    getGroupId = () => {
        return this.props.match.params.groupId;
    }

    componentDidMount() {
        groupPostStore.getPosts(this.getGroupId());
        fitnessStore.setSelectedTopMenu(GroupDetailsTopMenuEnum.MESSAGES);
    }

    componentWillUnmount() {
        groupPostStore.resetStore();
    }

    gotoCreatePost = () => {
        const groupId = this.getGroupId();
        navigationStore.push(`/posts/create/${groupId}`);
    }

    onRowClicked = (row: Parse.Object) => {
        navigationStore.push(`/posts/${row.id}`);
    }

    render() {
        const posts = groupPostStore.posts || [];
        const groupIsActive = fitnessStore.groupIsActive(this.getGroupId());

        const columns = [
            {
                Header: 'Date',
                id: 'date',
                accessor: (d: Parse.Object) => {
                    return Moment(d.get('createdAt'))
                        .local()
                        .format('MM-DD-YYYY hh:mm A');
                },
            },
            {
                Header: 'Author',
                id: 'author',
                accessor: (d: Parse.Object) => d.get('createdBy').get('name')
            },
            {
                Header: 'Message',
                id: 'message',
                accessor: (d: any) => d.get('text')
            }
        ]

        return (
            <div className='w-full mt-5'>
                <Table
                    actionButton={groupIsActive ? {
                        label: 'New post',
                        onClick: this.gotoCreatePost
                    } : undefined}
                    onRowClicked={this.onRowClicked}
                    condensed={true}
                    data={posts}
                    columns={columns} />
            </div>
        )
    }
}

export default GroupMessagesPage;