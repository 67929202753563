import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
    title: string,
    skipMarginBottom?: boolean
}

@observer class SectionTitle extends React.Component<Props> {

    render() {
        const { title, skipMarginBottom } = this.props;

        return (
            <h2 className={`text-18px font-medium text-white ${!skipMarginBottom ? 'mb-16px' : ''} mt-30px`}>
                {title}
            </h2>
        )
    }

}

export default SectionTitle;