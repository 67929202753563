import * as React from 'react';
import { observer } from 'mobx-react';
import { loadingStore } from 'stores';
import { reaction } from 'mobx';

import './style.css';

interface State {
    show: boolean
}

@observer class LoadingIndicator extends React.Component<any, State> {

    timer: any;

    constructor(props: any) {
        super(props);

        this.state = {
            show: false
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    componentDidMount() {
        reaction(() => loadingStore.isLoading, () => {
            if (loadingStore.isLoading) {
                this.timer = setTimeout(() => {
                    this.setState({ show: true })
                }, 200)
            }
            else {
                clearTimeout(this.timer);
                this.setState({ show: false });
            }

        });
    }

    render() {
        const { show } = this.state;
        if (!show) return null;

        return (
            <>
                <div className='LoadingOverlay' />
                <div className='LoadingIndicator'>
                    <div className="sk-cube-grid">
                        <div className="sk-cube sk-cube1"></div>
                        <div className="sk-cube sk-cube2"></div>
                        <div className="sk-cube sk-cube3"></div>
                        <div className="sk-cube sk-cube4"></div>
                        <div className="sk-cube sk-cube5"></div>
                        <div className="sk-cube sk-cube6"></div>
                        <div className="sk-cube sk-cube7"></div>
                        <div className="sk-cube sk-cube8"></div>
                        <div className="sk-cube sk-cube9"></div>
                    </div>
                </div>
            </>
        )
    }

}

export default LoadingIndicator;