import * as React from 'react';
import { observer } from 'mobx-react';
import TopMenu from 'components/TopMenu';
import { Route } from 'react-router';
import { navigationStore } from 'stores';
import MembersPage from 'components/MembersPage';
import PendingMembersPage from 'components/PendingMembersPage';

enum TopMenuEnum {
    ACTIVE,
    PENDING
}

interface State {
    activeTopMenu: TopMenuEnum
}

@observer class MembersWrapper extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            activeTopMenu: TopMenuEnum.ACTIVE
        }
    }

    componentDidMount() {
        if (window.location.pathname.includes('/pending') && this.state.activeTopMenu !== TopMenuEnum.PENDING) {
            this.setState({
                activeTopMenu: TopMenuEnum.PENDING
            })
        }
    }

    goto = (topMenu: TopMenuEnum) => {
        this.setState({
            activeTopMenu: topMenu
        });

        let endpoint = '/members';

        if (topMenu === TopMenuEnum.PENDING) {
            endpoint += '/pending';
        }

        navigationStore.push(endpoint);
    }
    
    render() {
        const { activeTopMenu } = this.state;

        const menuItems = [
            {
                text: 'Members',
                onClick: () => this.goto(TopMenuEnum.ACTIVE),
                isActive: activeTopMenu === TopMenuEnum.ACTIVE
            },
            {
                text: 'Pending',
                onClick: () => this.goto(TopMenuEnum.PENDING),
                isActive: activeTopMenu === TopMenuEnum.PENDING
            }
        ]

        return (
            <div className='w-full'>
                <TopMenu items={menuItems} />
                <Route exact path="/members" component={MembersPage} />
                <Route exact path="/members/pending" component={PendingMembersPage} />
            </div>
        )
    }

}

export default MembersWrapper;