import * as React from 'react';
import { observer } from 'mobx-react';
import { groupMemberStore } from 'stores';
import SearchCombo from 'components/Forms/SearchCombo';

interface Props {
    groupId?: string
}

@observer class SearchGroupMember extends React.Component<Props> {

    onSearchMembers = (queryType: 'nameQuery' | 'emailQuery', searchValue: string) => {
        const { groupId } = this.props;

        groupMemberStore.getMembers(groupId!, false, { [queryType]: searchValue });
    }

    render() {
        const options = [
            {
                value: 'name',
                name: 'Name',
                filterFunc: (searchValue: string) => this.onSearchMembers('nameQuery', searchValue)
            },
            {
                value: 'email',
                name: 'Email',
                filterFunc: (searchValue: string) => this.onSearchMembers('emailQuery', searchValue)
            },
        ]

        return (
            <SearchCombo
                placeholder='Search members'
                onClearFiltered={() => groupMemberStore.resetMembers()}
                options={options} />
        )
    }

}

export default SearchGroupMember;