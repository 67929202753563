import * as React from 'react';
import { observer } from 'mobx-react';
import { fitnessStore, GroupDetailsTopMenuEnum, waiverStore, WaiverStatusEnum, navigationStore } from 'stores';
import Table from 'components/Table';
import Moment from 'moment';
import { CellInfo } from 'react-table';

@observer class WaiversPage extends React.Component<any> {

    componentDidMount() {
        waiverStore.getWaivers(this.getGroupId());
        fitnessStore.setSelectedTopMenu(GroupDetailsTopMenuEnum.WAIVERS);
    }

    componentWillUnmount() {
        waiverStore.resetStore();
    }

    gotoCreateWaiver = () => {
        const groupId = this.getGroupId();
        navigationStore.push(`/waivers/create/${groupId}`);
    }

    getGroupId = () => {
        return this.props.match.params.groupId;
    }

    onRowClicked = (row: Parse.Object) => {
        navigationStore.push(`/waivers/${row.id}`);
    }

    render() {
        const waivers = waiverStore.waivers || [];

        const columns = [
            {
                Header: 'Name',
                id: 'title',
                accessor: (d: Parse.Object) => <span className='font-medium'>{d.get('title')}</span>
            },
            {
                Header: 'Date',
                id: 'publishedDate',
                accessor: (d: Parse.Object) => {
                    const date = d.get('publishedDate');
                    if (!date) return '';

                    return Moment(date).local().format('MM-DD-YYYY');
                }
            },
            {
                Header: 'Status',
                id: 'status',
                Cell: (cellInfo: CellInfo) => {
                    const row = cellInfo.original as Parse.Object;

                    switch (row.get('status')) {
                        case WaiverStatusEnum.ACTIVE: return <span className='text-accepted font-medium'>Active</span>;
                        case WaiverStatusEnum.ARCHIVED: return <span className='text-warning'>Archived</span>;
                        case WaiverStatusEnum.DRAFT: return <span className='text-inactive'>Draft</span>;
                        default: return 'Draft';
                    }
                }
            }
        ]
        
        const groupIsActive = fitnessStore.groupIsActive(this.getGroupId());

        return (
            <div className='w-full mt-5'>
                <Table
                    actionButton={groupIsActive ? {
                        label: 'Create new waiver',
                        onClick: this.gotoCreateWaiver
                    } : undefined}
                    onRowClicked={this.onRowClicked}
                    condensed={true}
                    data={waivers}
                    columns={columns} />
            </div>
        )
    }

}

export default WaiversPage;