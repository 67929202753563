import { observable, action } from 'mobx';
import { Parse, Pointer } from 'helpers/Parse'; import LoadPromise from 'helpers/LoadPromise';
;

export class GroupPostDetailsStore {

    @observable post: Parse.Object | null = null;
    @observable comments: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.post = null;
        this.comments = [];
    }

    @action
    getPost(postId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const groupPostQuery = new Parse.Query('GroupPost');

                groupPostQuery.include('createdBy');

                const post = await groupPostQuery.get(postId);
                this.post = post;

                resolve(post);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getComments(postId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const groupPostCommentQuery = new Parse.Query('GroupPostComment');
                groupPostCommentQuery.equalTo('groupPost', Pointer('GroupPost', postId));

                groupPostCommentQuery.include('createdBy');

                groupPostCommentQuery.limit(9999);
                groupPostCommentQuery.ascending('createdAt');

                const comments = await groupPostCommentQuery.find();
                this.comments = comments;

                resolve(comments);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    addComment(userId: string, postId: string, comment: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const GroupPostComment = Parse.Object.extend('GroupPostComment');
                const groupPostComment = new GroupPostComment();

                groupPostComment.set('createdBy', Pointer('_User', userId));
                groupPostComment.set('groupPost', Pointer('GroupPost', postId));
                groupPostComment.set('text', comment);

                const res = await groupPostComment.save();
                // this.comments.push(res);
                resolve(res);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

}

export const groupPostDetailsStore = new GroupPostDetailsStore();