import * as React from 'react';
import { observer } from 'mobx-react';
import { FieldState } from 'formstate';

import './style.css';

export interface RadioGroupItemInterface {
    name: string,
    value: boolean | string,
    id?: string
}

interface Props {
    items: Array<RadioGroupItemInterface>,
    fieldState?: FieldState<any>,
    value?: boolean | string,
    onChange?: Function
}

@observer class RadioGroup extends React.Component<Props> {

    onChange(value: boolean | string) {
        const { fieldState, onChange } = this.props;

        if (fieldState) {
            fieldState.onChange(value);
        }

        if (onChange) {
            onChange(value);
        }
    }

    render() {
        const { items, fieldState, value } = this.props;

        return (
            <div className="RadioGroup">
                {
                    items.map((item, index) => {
                        const key = item.id || `rbtn-${item.value}-${index}`;

                        return (
                            <div key={index}>
                                <input
                                    id={key}
                                    type='radio'
                                    value={fieldState ? fieldState.value : value}
                                    checked={fieldState ? fieldState.value === item.value : value === item.value}
                                    onChange={(e) => this.onChange(item.value)}
                                />
                                <label htmlFor={key}>{item.name}</label>
                            </div>

                        )
                    })
                }
            </div>


        )
    }

}

export default RadioGroup;