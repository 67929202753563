import * as React from 'react';
import { observer } from 'mobx-react';
import BackButton from 'components/BackButton';
import ActionButton from 'components/ActionButton';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import Input from 'components/Forms/Input';
import { FieldState, FormState } from 'formstate';
import { required } from 'helpers/FormValidation';
import { groupPostStore, navigationStore } from 'stores';
import { Parse } from 'helpers/Parse';

@observer class CreatePostPage extends React.Component<any> {

    form = new FormState({
        text: new FieldState('').validators(required)
    })

    onCreatePost = async () => {
        const text = this.form.$.text.$;

        if (!text) {
            alert('Post cannot be empty.');
            return;
        }

        const userId = Parse.User.current()!.id;
        const groupId = this.props.match.params.groupId;

        await groupPostStore.createPost(userId, groupId, text);
        navigationStore.goBack();
    }

    render() {
        const form = this.form.$;

        return (
            <div className='w-full'>
                <div className='text-right mb-8'>
                    <div className='flex'>
                        <BackButton />

                        <div className="text-right w-full">
                            <ActionButton primary={true} width='wide' text='Send' onClick={this.onCreatePost} />
                        </div>
                    </div>
                </div>

                <Section>
                    <SectionItem title='Enter your message' hideSeparator={true}>
                        <Input fieldState={form.text} textArea={true} />
                    </SectionItem>
                </Section>
            </div>
        )
    }
}

export default CreatePostPage;