import * as React from 'react';
import { observer } from 'mobx-react';
import SectionTitle from 'components/SectionTitle';
import { Parse } from 'helpers/Parse';
import { getImage } from 'helpers/SportImageHelper';
import { readableActivity } from 'helpers/ActivitiesHelper';
import AvatarCell from 'components/Table/AvatarCell';
import Table from 'components/Table';
import Moment from 'moment';
import { navigationStore } from 'stores';

interface Props {
    gamePlayers: Array<Parse.Object>
}

@observer class Activities extends React.Component<Props> {

    onTableRowClicked = (gamePlayer: Parse.Object) => {
        const activity = gamePlayer.get('game');
        navigationStore.push(`/activitydetails/${activity.id}`);
    }

    render() {
        const { gamePlayers } = this.props;
        if (!gamePlayers || gamePlayers.length === 0) return null;

        const columns = [
            {
                Header: '',
                id: 'name',
                accessor: (gamePlayer: Parse.Object) => {
                    const activity = gamePlayer.get('game');
                    const sportKey = activity.get('sportKey')
                    const imageUrl = getImage(sportKey);
                    const title = activity.get('customTitle');
                    const subTitle = readableActivity(sportKey);

                    return <AvatarCell
                        imageUrl={imageUrl}
                        title={title}
                        subTitle={subTitle}
                    />
                }
            },
            {
                Header: 'Date',
                id: 'gameStart',
                width: 210,
                accessor: (gamePlayer: Parse.Object) => {
                    const activity = gamePlayer.get('game');
                    return Moment(activity.get('gameStart'))
                        .local()
                        .format('MM-DD-YYYY hh:mm A');
                },
                sortMethod: (a: any, b: any, desc: any) => {
                    if (Moment(a, "MM-DD-YYYY hh:mm A").isBefore(Moment(b, "MM-DD-YYYY hh:mm A"))) {
                        return -1;
                    }
                    else {
                        return 1;
                    }
                }
            },
            {
                Header: 'Checked In',
                id: 'playerCheckedIn',
                accessor: (gamePlayer: Parse.Object) => {
                    return gamePlayer.get('playerCheckedIn') ? 'Yes' : 'No'
                }
            }
        ]

        return (
            <div>
                <SectionTitle title='Activities' skipMarginBottom={true} />

                <Table
                    onRowClicked={this.onTableRowClicked}
                    data={gamePlayers}
                    columns={columns}
                />
            </div >
        )
    }

}

export default Activities;