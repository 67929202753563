import * as React from 'react';
import { observer } from 'mobx-react';
import { FormState, FieldState } from 'formstate';
import { activities } from 'helpers/ActivitiesHelper';
import SectionItem from 'components/Forms/SectionItem';
import Switch from 'components/Forms/Switch';
import InputModal from '../InputModal';
import Section from 'components/Forms/Section';
import _ from 'lodash';

interface Props {
    activitiesChanged: Function
}

interface State {
    values: string
}

@observer class SelectActivities extends React.Component<Props, State> {

    form = new FormState({}) as any;

    ANY_ACTIVITY_PLACEHOLDER: string = 'All';

    constructor(props: any) {
        super(props);

        this.state = {
            values: this.ANY_ACTIVITY_PLACEHOLDER
        }
    }

    componentDidMount() {
        const orderedActivities = _.orderBy(activities, (v) => v);

        Object.keys(orderedActivities).forEach((key) => {
            this.form.$[orderedActivities[key]] = new FieldState(false);
        })
    }

    updateSelectedActivities = () => {
        const { activitiesChanged } = this.props;
        const values: any = [];
        const form = this.form.$;

        Object.keys(activities).forEach((key) => {
            if (form[activities[key]].value === true) {
                values.push(activities[key]);
            }
        })

        activitiesChanged(this.form);

        this.setState({
            values: values.join(', ') || this.ANY_ACTIVITY_PLACEHOLDER
        })

    }

    render() {
        const form = this.form.$;
        if (!form) return null;
        const { values } = this.state;

        const items = [];
        for (let key in form) {
            const item = form[key];

            items.push(
                <SectionItem title={key} key={key}>
                    <Switch fieldState={item} className='justify-end' />
                </SectionItem>
            )
        }

        return (
            <InputModal
                label='Activities'
                id='groups'
                value={values}
                onClose={this.updateSelectedActivities}
                onClick={() => null}>

                <Section secondaryClassName='overflow-auto' secondaryStyle={{ maxHeight: '80vh' }}>
                    {items}
                </Section>

            </InputModal>
        )
    }

}

export default SelectActivities;