import * as React from 'react';
import { observer } from 'mobx-react';
import TopMenu from 'components/TopMenu';
import Activities from './Activities';
import Bookings from './Bookings';

enum TopMenuEnum {
    ACTIVITIES,
    BOOKINGS
}

interface State {
    activeTopMenu: TopMenuEnum
}

@observer class StatsPage extends React.Component<any, State> {

    state = { activeTopMenu: TopMenuEnum.ACTIVITIES }

    render() {
        const { activeTopMenu } = this.state;

        const menuItems = [
            {
                text: 'Activities',
                onClick: () => this.setState({ activeTopMenu: TopMenuEnum.ACTIVITIES }),
                isActive: activeTopMenu === TopMenuEnum.ACTIVITIES
            },
            {
                text: 'Bookings',
                onClick: () => this.setState({ activeTopMenu: TopMenuEnum.BOOKINGS }),
                isActive: activeTopMenu === TopMenuEnum.BOOKINGS
            }
        ]

        return (
            <div className='w-full'>
                <TopMenu items={menuItems} />

                {
                    activeTopMenu === TopMenuEnum.ACTIVITIES &&
                    <Activities />
                }

                {
                    activeTopMenu === TopMenuEnum.BOOKINGS &&
                    <Bookings />
                }

            </div>
        )
    }

}

export default StatsPage;