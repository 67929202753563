import * as React from 'react';
import { observer } from 'mobx-react';
import InputModal from '../InputModal';
import { FieldState } from 'formstate';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import DateInput from 'components/Forms/DateInput';
import Moment from 'moment';

interface Props {
    from: FieldState<Date | null>,
    to: FieldState<Date | null>,
}

@observer class SelectPeriod extends React.Component<Props> {

    formatDate(fs: FieldState<any>) {
        return Moment(fs.value).format('YYYY/MM/DD');
    }

    render() {
        const { from, to } = this.props;

        let val = ''
        if (from.value && to.value) {
            val = `${this.formatDate(from)} - ${this.formatDate(to)}`;
        }

        return (
            <InputModal
                label='Period'
                id='period'
                value={val}
                onClick={() => null}>

                <Section>
                    <SectionItem title='from' titleWidth='100px'>
                        <DateInput fieldState={from} options={{ dateFormat: 'Y/m/d' }} />
                    </SectionItem>
                    <SectionItem title='to' hideSeparator={true} titleWidth='100px'>
                        <DateInput fieldState={to} options={{ dateFormat: 'Y/m/d' }} />
                    </SectionItem>
                </Section>

            </InputModal>
        )
    }

}

export default SelectPeriod;