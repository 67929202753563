import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
    text: string,
    linkTo: string
}

@observer class UserGuide extends React.Component<Props> {

    render() {
        const { text, linkTo } = this.props;

        return (
            <a className='text-inactive hover:text-white no-underline font-thin' href={linkTo} target='_blank'>{text}</a>
        )
    }

}

export default UserGuide;