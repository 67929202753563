import * as React from 'react';
import Members from 'components/Members';
import { observer } from 'mobx-react';
import ActionButton from 'components/ActionButton';
import BackButton from 'components/BackButton';
import { navigationStore, groupMemberStore } from 'stores';

interface State {
    selectedIds: Array<string>
}

@observer class AddGroupMembersPage extends React.Component<any, State> {

    constructor(props: any) {
        super(props);

        this.state = {
            selectedIds: []
        }
    }

    componentDidMount() {
        navigationStore.setShowSecondaryMenu(false);
    }

    componentWillUnmount() {
        navigationStore.setShowSecondaryMenu(true);
    }

    onSelectedChanged = (selectedIds: Array<string>) => {
        this.setState({
            selectedIds: selectedIds
        })
    }

    getGroupIdByParams() {
        return this.props.match.params.groupId;
    }

    onAddMembersClicked = async () => {
        const { selectedIds } = this.state;
        if (selectedIds.length === 0) return;

        if (window.confirm(`Are you sure you want to add the ${selectedIds.length} selected user(s)?`)) {
            await groupMemberStore.addUsersToGroup(this.getGroupIdByParams(), selectedIds);
            navigationStore.goBack();
        }
    }

    render() {
        const { selectedIds } = this.state;
        const groupId = this.getGroupIdByParams();
        const isSelected = selectedIds.length > 0;

        return (
            <div className='w-full'>
                <div className='text-right mb-8'>
                    <div className='flex'>
                        <BackButton />

                        <div className="text-right w-full">
                            <ActionButton primary={isSelected} width='wide' text={isSelected ? `Add ${selectedIds.length} members` : 'No members selected'} onClick={this.onAddMembersClicked} />
                        </div>
                    </div>
                </div>
                <Members groupId={groupId} selectable={true} onSelectedChanged={this.onSelectedChanged} />
            </div>

        )
    }

}

export default AddGroupMembersPage;