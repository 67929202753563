import * as React from "react";
import { observer } from "mobx-react";
import SectionTitle from "components/SectionTitle";
import SectionItem from "components/Forms/SectionItem";
import Section from "components/Forms/Section";
import Input from "components/Forms/Input";
import ActionButton from "components/ActionButton";
import { FormState, FieldState } from "formstate";
import { required } from "helpers/FormValidation";
import Form from "components/Forms/Form";
import Moment from "moment";
import { emailNotificationStore } from "stores/EmailNotificationStore";
import { fitnessStore, GroupDetailsTopMenuEnum } from "stores";

interface MessageItemProps {
  name: string;
  date: string;
  message: string;
}

const MessageItem = (props: MessageItemProps) => (
  <SectionItem
    title={
      <div>
        <p>{props.name}</p>
        <p className="text-xs">
          {Moment(props.date).local().format("MM-DD-YYYY hh:mm A")}
        </p>
      </div>
    }
    hideSeparator={true}
  >
    <Input readOnlyValue={props.message} />
  </SectionItem>
);

@observer
class GroupEmailPage extends React.Component<any> {
  form = new FormState({
    subject: new FieldState("").validators(required),
    body: new FieldState("").validators(required),
  });

  onSendEmail = () => {
    const { subject, body } = this.form.$;
    const id = this.getGroupId();
    if (id) {
      emailNotificationStore.sendEmail(
        subject.$,
        body.$,
        "ATCustomEmailGroup",
        id,
        () => {
          this.form.reset();
        }
      );
    }
  };

  getGroupId = () => {
    return this.props.match.params.groupId;
  };

  componentDidMount() {
    fitnessStore.setSelectedTopMenu(GroupDetailsTopMenuEnum.EMAIL);

    // conversationStore.getConversation(this.getActivityIdFromParams(), true);
  }

  componentWillUnmount() {
    emailNotificationStore.resetStore();
  }

  render() {
    const messages = emailNotificationStore.emailNotifications || [];

    return (
      <div className="w-full">
        <div className="mt-10">
          <Section className="mb-10">
            <Form onValidSubmit={this.onSendEmail} formState={this.form}>
              <SectionItem title="Subject" hideSeparator={true}>
                <div className="pt-3">
                  <Input fieldState={this.form.$.subject} textArea={false} />
                </div>
              </SectionItem>
              <SectionItem title="Body" hideSeparator={true}>
                <div className="pt-3">
                  <Input fieldState={this.form.$.body} textArea={true} />
                </div>
              </SectionItem>
              <SectionItem title="" hideSeparator={true}>
                <div className="text-right mt-2">
                  <ActionButton primary={true} text="Send" width="narrow" />
                </div>
              </SectionItem>
            </Form>
          </Section>

          {messages.length > 0 && <SectionTitle title="Messages" />}

          {
            <Section>
              {messages.map((message, index) => (
                <MessageItem
                  key={index}
                  date={message.get("createdAt")}
                  name={message.get("sender").get("name")}
                  message={message.get("message")}
                />
              ))}
            </Section>
          }
        </div>
      </div>
    );
  }
}

export default GroupEmailPage;
