import * as React from 'react';
import { observer } from 'mobx-react';

import './style.css';
import SportTogether from './SportTogether.svg';
import StatItem from './StatItem';
import { authStore, navigationStore, homeStore, mainGroupStore } from 'stores';
import Moment from 'moment';

@observer class HomePage extends React.Component {

    componentDidMount() {
        navigationStore.setShowSecondaryMenu(false);
        if (authStore.customer && this.shouldShowStats()) {
            homeStore.getStats(authStore.customer.id);
        }
    }

    /**
     * Because it was very hard for Parse (backend) to process stats,
     * we had to add additional columns in the backend, for the sole purpose
     * of keeping stats updated. The data for these columns, will only be 
     * applied to new activities / participants, and therefore stats is no longer
     * relevant for customers created before the additional columns were rolled out in production.
     */
    shouldShowStats = () => {
        if (authStore.customer) {
            const momentDate = Moment(authStore.customer.get('createdAt'));
            return momentDate.year() >= 2020;
        }

        return false;
    }

    componentWillUnmount() {
        navigationStore.setShowSecondaryMenu(true);
    }

    pendingMembershipsClicked = () => {
        navigationStore.push('/members/pending');
    }

    render() {
        const { totalActivities, totalParticipants } = homeStore;

        return (
            <div className='HomePage w-full'>
                <div className='HomePage-intro text-center'>
                    <img src={SportTogether} alt='ATLETO - Sport Together' className='mb-5' />
                    <h1>
                        Welcome to ATLETO
                    </h1>
                    <p className='pt-5'>
                        With the ATLETO Dashboard you can manage your fitness and club sport activities with a few easy clicks from the menu.
                    </p>
                </div>

                <div className='HomePage-stats w-full lg:flex justify-center'>
                    <StatItem text='Active members' count={mainGroupStore.mainGroupCount} countClass='text-statsGreen' />

                    {
                        this.shouldShowStats() &&
                        <>
                            <div className='mx-2' />
                            <StatItem text='Total activities' count={totalActivities} countClass='text-statsGreen' />
                            <div className='mx-2' />
                            <StatItem text='Total participants' count={totalParticipants} countClass='text-statsGreen' />
                            {/* <StatItem text='Pending memberships' count={pendingMemberships} countClass={pendingMemberships > 0 ? 'text-orange' : 'text-statsGreen'} onClick={this.pendingMembershipsClicked} /> */}
                        </>
                    }

                </div>

            </div>
        )
    }

}

export default HomePage;