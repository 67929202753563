import * as React from 'react';
import { observer } from 'mobx-react';

import './style.css';

interface Props {
    text: string,
    onClick?: Function
    width: 'wide' | 'narrow' | 'wrap',
    className?: string,
    type?: 'submit' | 'reset' | 'button'
    primary?: boolean
    style?: Object
}

@observer class ActionButton extends React.Component<Props> {

    render() {
        const { onClick, text, width, className, primary, type, style } = this.props;


        return (
            <button
                style={style}
                type={type}
                className={`
                    ActionButton
                    ${primary ? 'bg-primaryAction' : 'bg-secondaryAction'}
                    ${`ActionButton-${width}`}
                    ${className || ''}
                `}
                onClick={(e) => onClick ? onClick(e) : null}>
                
                {text}
            </button>
        )
    }
    
}

export default ActionButton;