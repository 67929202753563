import { observable, action } from 'mobx';
import { Parse, Pointer } from 'helpers/Parse'; import LoadPromise from 'helpers/LoadPromise';
;

export class GroupPostStore {

    @observable posts: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.posts = [];
    }

    @action
    getPosts(groupId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const groupPostQuery = new Parse.Query('GroupPost');
                groupPostQuery.equalTo('group', Pointer('Group', groupId));

                groupPostQuery.include('createdBy');

                groupPostQuery.limit(9999);
                groupPostQuery.descending('createdAt');

                const posts = await groupPostQuery.find();
                this.posts = posts;

                resolve(posts);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    createPost(userId: string, groupId: string, text: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const GroupPost = Parse.Object.extend('GroupPost');
                const groupPost = new GroupPost();

                groupPost.set('createdBy', Pointer('_User', userId));
                groupPost.set('group', Pointer('Group', groupId));
                groupPost.set('text', text);

                const res = await groupPost.save();
                resolve(res);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }
}

export const groupPostStore = new GroupPostStore();