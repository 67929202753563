import * as React from 'react';
import { observer } from 'mobx-react';
import BackButton from 'components/BackButton';
import ActionButton from 'components/ActionButton';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import Input from 'components/Forms/Input';
import SelectWaiverStatus from 'components/CreateWaiverPage/SelectWaiverStatus';
import { WaiverStatusEnum, waiverStore, navigationStore } from 'stores';
import DateInput from 'components/Forms/DateInput';
import { FormState, FieldState } from 'formstate';
import _ from 'lodash';
import WaiverAgreementTable from './WaiverAgreementTable';
import Editor from 'components/Forms/Editor';

@observer class EditWaiverPage extends React.Component<any> {

    form = new FormState({
        id: new FieldState(''),
        title: new FieldState(''),
        content: new FieldState(''),
        status: new FieldState(''),
        publishedDate: new FieldState('')
    })

    async componentDidMount() {
        const waiverId = this.getWaiverId();
        await waiverStore.getWaiver(waiverId);
        const waiver = _.find(waiverStore.waivers, { id: waiverId }) as Parse.Object;

        if (waiver) {
            const form = this.form.$;
            form.id.reset(waiver.id);
            form.title.reset(waiver.get('title'));
            form.content.reset(waiver.get('content'));
            form.status.reset(waiver.get('status'));
            form.publishedDate.reset(waiver.get('publishedDate'));
        }

    }

    getWaiverId() {
        return this.props.match.params.waiverId;
    }

    onSaveChanges = async () => {
        const form = this.form.$;

        if (!form.title.$) {
            alert('Title must be filled out.')
            return;
        }

        if (form.status.$ === WaiverStatusEnum.ACTIVE && !form.publishedDate.$) {
            alert('If waiver should be active, you must set a publish date');
            return;
        }

        await waiverStore.updateWaiver(form.id.$, form.title.$, form.content.$, form.status.$ as WaiverStatusEnum, form.publishedDate.$);
        navigationStore.goBack();
    }

    render() {
        const form = this.form.$;

        return (
            <div className='w-full'>
                <div className='text-right mb-8'>
                    <div className='flex'>
                        <BackButton />

                        <div className="text-right w-full">
                            <ActionButton primary={true} width='wide' text='Save changes' onClick={this.onSaveChanges} />
                        </div>
                    </div>
                </div>

                <Section>
                    <SectionItem title='Title'>
                        <Input fieldState={form.title} />
                    </SectionItem>
                    <SectionItem title='Status' hideSeparator={form.status.$ !== WaiverStatusEnum.ACTIVE}>
                        <SelectWaiverStatus fieldState={form.status} />
                    </SectionItem>
                    {
                        form.status.$ === WaiverStatusEnum.ACTIVE &&
                        <SectionItem title='Active from' hideSeparator={true}>
                            <DateInput fieldState={form.publishedDate} />
                        </SectionItem>
                    }

                    <SectionItem title='Content' hideSeparator={true}>
                        {
                            form.content.$ &&
                            <Editor fieldState={form.content} readOnly={form.status.$ !== WaiverStatusEnum.DRAFT} />
                        }

                    </SectionItem>

                </Section>

                <WaiverAgreementTable waiverId={this.getWaiverId()} />

            </div>
        )
    }

}

export default EditWaiverPage;