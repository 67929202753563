import * as React from 'react';
import { observer } from 'mobx-react';

interface Props {
    text: string
    noMargin?: boolean
}

@observer class PrimarySectionTitle extends React.Component<Props> {

    render() {
        const { text, noMargin } = this.props;

        return (
            <p className={`${noMargin ? 'my-16px' : 'my-30px ml-16px'} text-18px font-medium text-white`}>
                {text}
            </p>
        )
    }

}

export default PrimarySectionTitle;