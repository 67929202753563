import * as React from 'react';
import { observer } from 'mobx-react';
import BackButton from 'components/BackButton';
import Separator from 'components/Separator';
import SectionTitle from 'components/SectionTitle';
import SectionItem from 'components/Forms/SectionItem';
import Section from 'components/Forms/Section';
import Input from 'components/Forms/Input';
import ActionButton from 'components/ActionButton';
import { FormState, FieldState } from 'formstate';
import { required } from 'helpers/FormValidation';
import Form from 'components/Forms/Form';
import Moment from 'moment';
import { conversationStore } from 'stores/ConversationStore';

interface MessageItemProps {
    name: string,
    date: string,
    message: string
}

const MessageItem = (props: MessageItemProps) => (
    <SectionItem
        title={
            <div>
                <p>{props.name}</p>
                <p className='text-xs'>{Moment(props.date).local().format('MM-DD-YYYY hh:mm A')}</p>
            </div>
        }
        hideSeparator={true} >
        <Input readOnlyValue={props.message} />
    </SectionItem>
)

@observer class PersonalMessageDetailsPage extends React.Component<any> {

    form = new FormState({
        message: new FieldState('').validators(required)
    })

    onSendMessage = () => {
        const message = this.form.$.message.$;
        conversationStore.sendMessage(message);
        this.form.reset();
    }

    getIdFromParams() {
        return this.props.match.params.id;
    }

    componentDidMount() {
        conversationStore.getConversationById(this.getIdFromParams());
    }

    componentWillUnmount() {
        conversationStore.resetStore();
    }

    render() {
        const conversation = conversationStore.conversation as any;
        if (!conversation) return null;

        const messages = conversationStore.messages || [];
        const userList = conversation._users.map((p: any) => p.get('name')).join(', '); // Users added to hidden property ._users in conversationStore

        return (
            <div className='w-full'>
                <div className='flex'>
                    <div>
                        <BackButton customUrl='/messages' />
                    </div>
                </div>

                <Separator />

                <div className='mt-4'>
                    <p className='mb-4 text-white'>Conversation with: {userList}</p>
                    <Section className='mb-10'>
                        <Form onValidSubmit={this.onSendMessage} formState={this.form}>
                            <SectionItem title='You' hideSeparator={true}>
                                <div className='pt-3'>
                                    <Input
                                        fieldState={this.form.$.message}
                                        textArea={true}
                                        placeholder='Write your message...'
                                    />
                                </div>
                                <div className='text-right mt-2'>
                                    <ActionButton
                                        primary={true}
                                        text='Send'
                                        width='narrow'
                                    />
                                </div>

                            </SectionItem>
                        </Form>
                    </Section>

                    {
                        messages.length > 0 &&
                        <SectionTitle title='Messages' />
                    }

                    <Section>
                        {
                            messages.map((message, index) => (
                                <MessageItem
                                    key={index}
                                    date={message.get('createdAt')}
                                    name={message.get('sender').get('name')}
                                    message={message.get('message')}
                                />
                            ))
                        }
                    </Section>
                </div>

            </div>
        )
    }
}

export default PersonalMessageDetailsPage;