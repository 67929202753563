import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';

interface Props {
    fieldState: FieldState<any>,
    className?: string
}

@observer class SelectDayOfWeek extends React.Component<Props> {

    render() {
        const { fieldState, className } = this.props;

        const opts = [
            {
                value: 1,
                name: "Monday"
            },
            {
                value: 2,
                name: "Tuesday"
            },
            {
                value: 3,
                name: "Wednesday"
            },
            {
                value: 4,
                name: "Thursday"
            },
            {
                value: 5,
                name: "Friday"
            },
            {
                value: 6,
                name: "Saturday"
            },
            {
                value: 7,
                name: "Sunday"
            }
        ]

        return (
            <Dropdown
                className={`Dropdown-light ${className || ''}`}
                fieldState={fieldState}
                type='number'
                skipSort={true}
                options={opts}
                />
        )
    }

}

export default SelectDayOfWeek;