import { observable, action } from 'mobx';
import { Parse } from 'helpers/Parse';
import LoadPromise from 'helpers/LoadPromise';

export interface StatsActivityRequestInterface {
    customerId: string,
    from: Date | null,
    to: Date | null,
    sportKeys: Array<string> | null,
    groups: Array<string> | null,
    hosts: Array<string> | null,
    vertical: string | null,
    searchQuery: string | null
}

export interface StatsBookingRequestInterface {

}

export interface StatsResponseItemInterface {
    current?: number,
    last?: number
}

export interface StatsActivityResponseInterface {
    activities: Object,
    checkIns: StatsResponseItemInterface,
    participants: Object,
    totalActivities: StatsResponseItemInterface,
    totalParticipants: StatsResponseItemInterface,
    uniqueParticipants: StatsResponseItemInterface,
}

export interface StatsBookingResponseInterface {
    bookings: number,
    checkedIn: number,
    noShows: Array<Parse.Object>,
    capacity: number
}

export class StatsStore {

    @observable activityData: StatsActivityResponseInterface | null = null;
    @observable bookingData: StatsBookingResponseInterface | null = null;

    @action
    resetStore() {
        this.activityData = null;
        this.bookingData = null;
    }

    @action
    getActivityStats(opts: StatsActivityRequestInterface) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const res = await Parse.Cloud.run('getCustomerStats', opts);
                if (res) {
                    this.activityData = res;
                    resolve(res);
                }

                reject('No stats');
            }
            catch (error) {
                reject(error.message);
            }
        });
    }

    @action
    getBookingStats(opts: StatsBookingRequestInterface) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const res = await Parse.Cloud.run('getBookingStats', opts);
                if (res) {
                    this.bookingData = res;
                    resolve(res);
                }

                reject('No stats');
            }
            catch (error) {
                reject(error.message);
            }
            resolve();
        })
    }

}

export const statsStore = new StatsStore();