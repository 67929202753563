import * as React from 'react';
import { observer } from 'mobx-react';

import './style.css';

interface MenuItem {
    text: string,
    onClick: Function,
    isActive?: boolean
}

interface Props {
    items: Array<MenuItem>
}

@observer class TopMenu extends React.Component<Props> {

    render() {
        const { items } = this.props;

        return (
            <div className='TopMenu-outer'>
                <ul className='pt-3 list-reset TopMenu-inner border-b-1 border-black w-full cursor-pointer'>
                    {
                        items.map((item, index) => {

                            let className = 'text-inactive hover:text-white';

                            if (item.isActive) {
                                className += ' TopMenu-inner-active';
                            }

                            return (
                                <li onClick={() => item.onClick()} className={className} key={index}>
                                    {item.text}
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        )
    }

}

export default TopMenu;