import * as React from 'react';
import { observer } from 'mobx-react';
import ActivityForm from 'components/Forms/ActivityForm';
import { activityStore, navigationStore, authStore, recurringActivityStore } from 'stores';

@observer class CreateActivityPage extends React.Component<any> {

    onSubmit = async (data: any, isRecurring: boolean) => {
        if (!isRecurring) {
            await activityStore.hostActivity(data);
        }
        else {
            try {
                await recurringActivityStore.hostRecurringActivity(authStore.customer.id, data);
                alert('Recurring template created. Please note that it can take up to 5 minutes, before appearing on the main page.');
            }
            catch(error) {
                alert(error.message);
            }
        }

        navigationStore.goBack();

    }

    getGroupIdFromParams() {
        return this.props.match.params.groupId;
    }

    render() {
        return (
            <div className='w-full'>
                <ActivityForm onSubmit={this.onSubmit} groupId={this.getGroupIdFromParams()} />
            </div>

        )
    }

}

export default CreateActivityPage;