import * as React from 'react';
import { observer } from 'mobx-react';

import './style.css';

interface Props {
    active: boolean,
    label: string,
    inlineStyle: string,
    onToggle: Function,
    position?: 'left' | 'right'
}

@observer class EditorButton extends React.Component<Props> {

    render() {
        const { active, onToggle, label, inlineStyle, position } = this.props;

        let className = 'EditorButton';

        if (active) {
            className += ' EditorButton-active';
        }

        if (position) {
            if (position === 'left') {
                className += ' EditorButton-left';
            }
            else if (position === 'right') {
                className += ' EditorButton-right';
            }
        }

        return (
            <button
                type='button'
                className={className}
                onClick={() => onToggle(inlineStyle)}>
                {label}
            </button>
        )
    }

}

export default EditorButton;