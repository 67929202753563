import * as React from 'react';
import { observer } from 'mobx-react';
import TopMenu from 'components/TopMenu';
import { Route } from 'react-router';
import ActivitiesPage from 'components/ActivitiesPage';
import GroupMembersPage from 'components/GroupMembersPage';
import { navigationStore, GroupDetailsTopMenuEnum, fitnessStore, mainGroupStore } from 'stores';
import TopActionButtons from 'components/TopActionButtons';
import CreateActivityPage from 'components/CreateActivityPage';
import RecurringActivitiesPage from 'components/RecurringActivitiesPage';
import WaiversPage from 'components/WaiversPage';
import GroupMessagesPage from 'components/GroupMessagesPage';
import GroupEmailPage from 'components/GroupEmailPage';

@observer class GroupDetailsWrapper extends React.Component<any> {

    goto = (fitnessTopMenu: GroupDetailsTopMenuEnum) => {
        fitnessStore.setSelectedTopMenu(fitnessTopMenu);

        const { groupId, groupType } = this.props.match.params;
        navigationStore.push(`/${groupType}/${groupId}/${fitnessTopMenu}`);
    }

    async addMainGroupIdToPath() {
        let id = mainGroupStore.mainGroupId;
        if (id) {
            navigationStore.replace(`/main/${id}/activities`);
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const params = nextProps.match.params;

        if (!params.groupId && params.groupType === 'main') {
            this.addMainGroupIdToPath();
        }
    }

    async componentDidMount() {
        const { groupId, groupType } = this.props.match.params;

        if (groupType === 'main' && !groupId) {
            this.addMainGroupIdToPath();
        }

        fitnessStore.setSelectedGroupId(groupId);
    }

    sectionRoute(section: string) {
        const baseUrl = '/:groupType(main|fitness|clubsport)/:groupId?';
        return `${baseUrl}/${section}`;
    }

    menuItem(label: string, topMenuEnum: GroupDetailsTopMenuEnum) {
        return {
            text: label,
            onClick: () => this.goto(topMenuEnum),
            isActive: fitnessStore.selectedTopMenu === topMenuEnum
        }
    }

    render() {
        const { groupId, groupType, section } = this.props.match.params;

        const menuItems = [
            this.menuItem('Activities', GroupDetailsTopMenuEnum.ACTIVITIES),
            this.menuItem('Recurring', GroupDetailsTopMenuEnum.RECURRING),
            this.menuItem('Messages', GroupDetailsTopMenuEnum.MESSAGES),
            this.menuItem('Email', GroupDetailsTopMenuEnum.EMAIL),
            this.menuItem('Waivers', GroupDetailsTopMenuEnum.WAIVERS)
        ]

        menuItems.push(this.menuItem('Members', GroupDetailsTopMenuEnum.MEMBERS))

        if (section === 'createactivity') {
            return (
                <Route exact path={this.sectionRoute('createactivity')} component={CreateActivityPage} />
            )
        }

        return (
            <div className='w-full'>
                <TopActionButtons groupId={groupId} groupType={groupType} />
                <TopMenu items={menuItems} />
                <Route exact path={this.sectionRoute('activities')} component={ActivitiesPage} />
                <Route exact path={this.sectionRoute('recurring')} component={RecurringActivitiesPage} />
                <Route exact path={this.sectionRoute('members')} component={GroupMembersPage} />
                <Route exact path={this.sectionRoute('waivers')} component={WaiversPage} />
                <Route exact path={this.sectionRoute('messages')} component={GroupMessagesPage} />
                <Route exact path={this.sectionRoute('email')} component={GroupEmailPage} />
            </div>
        )
    }

}

export default GroupDetailsWrapper;