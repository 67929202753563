import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';

interface Props {
    fieldState: FieldState<any>,
    className?: string
}

@observer class SelectRecurringVisibility extends React.Component<Props> {

    componentDidMount() {
        const { fieldState } = this.props;

        if (!fieldState.value) {
            fieldState.onChange(168);
        }
    }

    render() {
        const { fieldState, className } = this.props;

        const opts = [
            {
                value: 168,
                name: "1 week before game start"
            },
            {
                value: 72,
                name: "3 days before game start"
            },
            {
                value: 24,
                name: "1 day before game start"
            }
        ]

        return (
            <Dropdown
                className={`Dropdown-light ${className || ''}`}
                fieldState={fieldState}
                type='number'
                skipSort={true}
                options={opts}
            />
        )
    }

}

export default SelectRecurringVisibility;