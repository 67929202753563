import { observable, action } from 'mobx';
import LoadPromise from 'helpers/LoadPromise';
import { Pointer, Parse } from 'helpers/Parse';

export class WaiverAgreementStore {

    @observable agreements: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.agreements = [];
    }

    @action
    getAgreementsByWaiver(waiverId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const waiverAgreementQuery = new Parse.Query('WaiverAgreement');

                waiverAgreementQuery.equalTo('waiver', Pointer('Waiver', waiverId));
                waiverAgreementQuery.ascending('firstname');

                waiverAgreementQuery.include('user');

                const res = await waiverAgreementQuery.find();
                this.agreements = res;

                resolve(res);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getAgreementsByUser(userId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const waiverAgreementQuery = new Parse.Query('WaiverAgreement');

                waiverAgreementQuery.equalTo('user', Pointer('_User', userId));
                waiverAgreementQuery.include('waiver.group');

                const res = await waiverAgreementQuery.find();

                this.agreements = res;

                resolve(res);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }


}

export const waiverAgreementStore = new WaiverAgreementStore();