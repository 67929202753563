import ATSportTennis from './images/ATSportTennis.jpg';
import ATSportSoccer from './images/ATSportSoccer.jpg';
import ATSportFitness from './images/ATSportFitness.jpg';
import ATSportBasketball from './images/ATSportBasketball.jpg';
import ATSportCycling from './images/ATSportCycling.jpg';
import ATSportSquash from './images/ATSportSquash.jpg';
import ATSportRacquetball from './images/ATSportRacquetball.jpg';
import ATSportFootball from './images/ATSportFootball.jpg';
import ATSportYoga from './images/ATSportYoga.jpg';
import ATSportVolleyball from './images/ATSportVolleyball.jpg';
import ATSportMountainBiking from './images/ATSportMountainBiking.jpg';
import ATSportTriathlon from './images/ATSportTriathlon.jpg';
import ATSportSoftball from './images/ATSportSoftball.jpg';
import ATSportRugby from './images/ATSportRugby.jpg';
import ATSportUltimateFrisbee from './images/ATSportUltimateFrisbee.jpg';
import ATSportMartialArt from './images/ATSportMartialArt.jpg';
import ATSportSkateboarding from './images/ATSportSkateboarding.jpg';
import ATSportSkiing from './images/ATSportSkiing.jpg';
import ATSportGolf from './images/ATSportGolf.jpg';
import ATSportRunning from './images/ATSportRunning.jpg';
import ATSportLacrosse from './images/ATSportLacrosse.jpg';
import ATSportSurfing from './images/ATSportSurfing.jpg';
import ATSportBowling from './images/ATSportBowling.jpg';
import ATSportBaseball from './images/ATSportBaseball.jpg';
import ATSportBadminton from './images/ATSportBadminton.jpg';
import ATSportBoxing from './images/ATSportBoxing.jpg';
import ATSportClimbing from './images/ATSportClimbing.jpg';
import ATSportCricket from './images/ATSportCricket.jpg';
import ATSportDance from './images/ATSportDance.jpg';
import ATSportDodgeball from './images/ATSportDodgeball.jpg';
import ATSportFlagFootball from './images/ATSportFlagFootball.jpg';
import ATSportHandball from './images/ATSportHandball.jpg';
import ATSportHiking from './images/ATSportHiking.jpg';
import ATSportHockey from './images/ATSportHockey.jpg';
import ATSportIceHockey from './images/ATSportIceHockey.jpg';
import ATSportKayaking from './images/ATSportKayaking.jpg';
import ATSportKickball from './images/ATSportKickball.jpg';
import ATSportStandUpPaddle from './images/ATSportStandUpPaddle.jpg';
import ATSportSwimming from './images/ATSportSwimming.jpg';
import ATSportTableTennis from './images/ATSportTableTennis.jpg';
import ATSportWalking from './images/ATSportWalking.jpg';
import ATSportPokemonGo from './images/ATSportPokemonGo.jpg';
import ATSportTrailrun from './images/ATSportTrailrun.jpg';
import ATSportArchery from './images/ATSportArchery.jpg';
import ATSportQuidditch from './images/ATSportQuidditch.jpg';
import ATSportEvent from './images/ATSportEvent.jpg';
import ATSportESport from './images/ATSportESport.jpg';
import ATSportCheerleading from './images/ATSportCheerleading.jpg';
import ATSportFencing from './images/ATSportFencing.jpg';
import ATSportOpenRec from './images/ATSportOpenRec.jpg';
import ATSportWorkout from './images/ATSportWorkout.jpg';

let images = {
    ATSportTennis,
    ATSportSoccer,
    ATSportFitness,
    ATSportBasketball,
    ATSportCycling,
    ATSportSquash,
    ATSportRacquetball,
    ATSportFootball,
    ATSportYoga,
    ATSportVolleyball,
    ATSportMountainBiking,
    ATSportTriathlon,
    ATSportSoftball,
    ATSportRugby,
    ATSportUltimateFrisbee,
    ATSportMartialArt,
    ATSportSkateboarding,
    ATSportSkiing,
    ATSportGolf,
    ATSportRunning,
    ATSportLacrosse,
    ATSportSurfing,
    ATSportBowling,
    ATSportBaseball,
    ATSportBadminton,
    ATSportBoxing,
    ATSportClimbing,
    ATSportCricket,
    ATSportDance,
    ATSportDodgeball,
    ATSportFlagFootball,
    ATSportHandball,
    ATSportHiking,
    ATSportHockey,
    ATSportIceHockey,
    ATSportKayaking,
    ATSportKickball,
    ATSportStandUpPaddle,
    ATSportSwimming,
    ATSportTableTennis,
    ATSportWalking,
    ATSportPokemonGo,
    ATSportTrailrun,
    ATSportArchery,
    ATSportQuidditch,
    ATSportEvent,
    ATSportCheerleading,
    ATSportESport,
    ATSportFencing,
    ATSportOpenRec,
    ATSportWorkout
}

export function getImage(key: string) {
    return images[key];
}