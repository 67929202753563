import * as React from 'react';
import { observer } from 'mobx-react';
import Section from 'components/Forms/Section';
import ActionButton from 'components/ActionButton';
import { FormState, FieldState } from 'formstate';
import SelectPeriod from './SelectPeriod';
import Moment from 'moment';
import { required } from 'helpers/FormValidation';
import SelectGroups from './SelectGroups';
import SelectActivities from './SelectActivities';
import SelectHosts from './SelectHosts';
import SelectVertical, { VerticalEnum } from './SelectVertical';
import _ from 'lodash';
import { activities } from 'helpers/ActivitiesHelper';
import NumbersBox from './NumbersBox';
import Separator from 'components/Separator';
import { StatsActivityRequestInterface, statsStore, authStore, loadingStore } from 'stores';
import ActivitiesStats from './ActivitiesStats';
import { getPercentageChange } from 'helpers/NumbersHelper';
import ParticipantsStats from './ParticipantsStats';
import Input from 'components/Forms/Input';


@observer class Activities extends React.Component {

    form = new FormState({
        from: new FieldState<Date | null>(Moment().startOf('week').toDate()).validators(required),
        to: new FieldState<Date | null>((Moment().toDate())).validators(required),
        activities: new FormState<any>({}),
        groups: new FormState<any>({}),
        hosts: new FormState<any>({}),
        searchQuery: new FieldState<string | null>(null),
        vertical: new FieldState<VerticalEnum>(VerticalEnum.ANY)
    })

    componentWillUnmount() {
        statsStore.resetStore();
    }

    groupsChanged = (form: FormState<any>) => {
        this.form.$.groups = form;
    }

    activitiesChanged = (form: FormState<any>) => {
        this.form.$.activities = form;
    }

    hostsChanged = (form: FormState<any>) => {
        this.form.$.hosts = form;
    }

    getSelectedFieldStateKeys = (formState: FormState<any>) => {
        const result: Array<string> = [];

        Object.keys(formState.$).forEach((key) => {
            if (formState.$[key].value === true) {
                result.push(key);
            }
        })

        return result;
    }

    onCreateReport = () => {
        statsStore.resetStore();

        const form = this.form.$;
        if (Moment(form.from.$!).isAfter(Moment(form.to.$!))) {
            alert('Period to must be greater than from');
            return;
        }

        const activityKeys: Array<string> = [];

        Object.keys(form.activities.$).forEach((key) => {
            if (form.activities.$[key].value === true) {
                activityKeys.push(_.invert(activities)[key]);
            }
        })

        const data = {
            customerId: authStore.customer.id,
            from: form.from.$,
            to: form.to.$,
            groups: this.getSelectedFieldStateKeys(form.groups),
            hosts: this.getSelectedFieldStateKeys(form.hosts),
            sportKeys: activityKeys,
            vertical: form.vertical.$.toLowerCase(),
            searchQuery: form.searchQuery.$
        } as StatsActivityRequestInterface;

        statsStore.getActivityStats(data);
    }

    render() {
        const data = statsStore.activityData;
        const form = this.form.$;

        return (
            <div>
                <Section className='StatsPage mt-6'>
                    <div className='flex'>
                        <SelectPeriod from={form.from} to={form.to} />
                        <SelectGroups groupsChanged={this.groupsChanged} />
                        <SelectActivities activitiesChanged={this.activitiesChanged} />
                        <SelectHosts hostsChanged={this.hostsChanged} />
                        <SelectVertical vertical={form.vertical} />
                    </div>

                    <div className='mr-2 pb-5 flex'>
                        <div className='flex-auto text-left' style={{ maxWidth: '220px' }}>
                            <Input
                                placeholder='Search activity title...'
                                fieldState={form.searchQuery}
                            />
                        </div>
                        <div className='flex-auto text-right'>
                            {/* {
                                data !== null &&
                                <ActionButton text='Reset' width='narrow' onClick={() => window.location.reload()} className='hide-on-print mr-2' />
                            } */}

                            {
                                data !== null &&
                                <ActionButton text='Print' width='narrow' onClick={() => window.print()} className='hide-on-print mr-2' />
                            }
                            {

                                !loadingStore.isLoading &&
                                <ActionButton text={data !== null ? 'Update stats' : 'Create stats'} width='wide' primary={true} onClick={this.onCreateReport} className='hide-on-print' />
                            }
                        </div>

                    </div>

                </Section>

                {
                    loadingStore.isLoading &&
                    <div className='text-center mt-20'>
                        <h1 className='text-white uppercase mt-8'>
                            Loading... This may take a while...
                        </h1>
                    </div>
                }

                {
                    data &&
                    <>
                        <Separator className='my-8' />

                        <div className='w-full justify-between lg:flex'>
                            <NumbersBox title='Total activities' value={data.totalActivities.current || 0} percentage={getPercentageChange(data.totalActivities.last, data.totalActivities.current)} />
                            <NumbersBox title='Unique participants' value={data.uniqueParticipants.current || 0} percentage={getPercentageChange(data.uniqueParticipants.last, data.uniqueParticipants.current)} />
                            <NumbersBox title='Total participants' value={data.totalParticipants.current || 0} percentage={getPercentageChange(data.totalParticipants.last, data.totalParticipants.current)} />
                            <NumbersBox title='Check in' value={data.checkIns.current || 0} percentage={getPercentageChange(data.checkIns.last, data.checkIns.current)} />
                        </div>

                        <div className='w-full justify-between lg:flex mt-6 items-start'>
                            <ActivitiesStats activities={data.activities} />

                            <div className='mx-2' />
                            <ParticipantsStats participants={data.participants} />

                        </div>
                    </>
                }

            </div>
        )
    }

}

export default Activities;