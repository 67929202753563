import * as React from 'react';
import { observer } from 'mobx-react';
import BackButton from 'components/BackButton';
import ActionButton from 'components/ActionButton';
import { FieldState, FormState } from 'formstate';
import { required } from 'helpers/FormValidation';
import Members from 'components/Members';
import { conversationStore } from 'stores/ConversationStore';
import { navigationStore } from 'stores';
import Parse from 'parse';

interface State {
    selectedIds: Array<string>
}

@observer class CreatePersonalConversationPage extends React.Component<any, State> {

    state = {
        selectedIds: []
    }

    form = new FormState({
        text: new FieldState('').validators(required)
    })

    onSelectedChanged = (selectedIds: Array<string>) => {
        this.setState({
            selectedIds: selectedIds
        })
    }

    onMembersSelectedContinue = async () => {
        const { selectedIds } = this.state;
        if (selectedIds.length === 0) return;

        const conversation = await conversationStore.createOrGetPersonalConversation(selectedIds) as Parse.Object;
        navigationStore.push(`/messages/${conversation.id}`)
    }

    render() {
        const { selectedIds } = this.state;

        return (
            <div className='w-full'>
                <div className='text-right mb-8'>
                    <div className='flex'>
                        <BackButton />

                        <div className="text-right w-full">
                            <ActionButton
                                primary={selectedIds.length > 0}
                                width='wide' text={`Start (${selectedIds.length} selected)`}
                                onClick={this.onMembersSelectedContinue} />
                        </div>
                    </div>
                </div>

                <h1 className='text-white'>
                    Add recipients
                    </h1>

                <Members
                    selectable={true}
                    onSelectedChanged={this.onSelectedChanged}
                />
            </div>
        )

    }
}

export default CreatePersonalConversationPage;