import { observable, action } from 'mobx';
import { Parse, Pointer } from 'helpers/Parse';
import { Role, User } from 'parse';
import LoadPromise from 'helpers/LoadPromise';
import { customerRoleStore } from './CustomerRoleStore';
import { authStore } from './AuthStore';

export class SettingsStore {

    @observable slurps: Array<Parse.Object> = [];
    @observable admins: Array<Parse.Object> = [];

    @action
    resetStore() {
        this.slurps = [];
        this.admins = [];
    }

    @action
    getSlurps(customerId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const slurpQuery = new Parse.Query('CustomerUserSlurp');
                slurpQuery.equalTo('customer', Pointer('Customer', customerId));
                const slurps = await slurpQuery.find();

                this.slurps = slurps;
                resolve(slurps);
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    @action
    getAdmins(customerId: string) {
        return LoadPromise(async (resolve, reject) => {
            try {
                const roleQuery = new Parse.Query('_Role');
                roleQuery.equalTo('name', `customer-admins-${customerId}`);
                const roles = await roleQuery.first();

                if (roles) {
                    const userList: Array<Parse.Object> = [];
                    await roles.relation('users').query().each(function (user: Parse.Object) {
                        userList.push(user);
                    });

                    this.admins = userList;
                    resolve(userList);
                }
            }
            catch (error) {
                reject(error.message);
            }
        });
    }

    @action
    removeAdmin(userId: string, customerId: string) {
        return new Promise(async (resolve, reject) => {
            try {

                var roleQuery = new Parse.Query('_Role');
                roleQuery.equalTo('name', `customer-admins-${customerId}`);
                const role = await roleQuery.first() as Role;

                var userQuery = new Parse.Query('_User');
                userQuery.equalTo('objectId', userId);
                const user = await userQuery.first() as User;

                await customerRoleStore.deleteCustomerRole(authStore.customer.id, user.id);

                if (role && user) {
                    role.getUsers().remove(user);
                    role.save();

                    const admins = this.admins.filter((existingAdmin) => existingAdmin.id !== userId);
                    this.admins = admins;

                    resolve();
                }
                else reject('role or user does not exist');
            }
            catch (error) {
                reject(error.message);
            }
        })
    }

    addAdmin(userId: string, customerId: string) {
        return new Promise(async (resolve, reject) => {
            try {
                const roleQuery = new Parse.Query('_Role');
                roleQuery.equalTo('name', `customer-admins-${customerId}`);
                const role = await roleQuery.first() as Role;

                const userQuery = new Parse.Query('_User');
                userQuery.equalTo('objectId', userId);
                const user = await userQuery.first() as User;

                if (role && user) {
                    role.getUsers().add(user);
                    role.save();

                    this.admins.push(user);

                    resolve();
                }
                else reject ('role or user does not exist');
            }
            catch (error) {
                reject(error.message);
            }
        })
    }
}

export const settingsStore = new SettingsStore();