import * as React from 'react';
import { observer } from 'mobx-react';
import GoogleMaps from 'components/GoogleMaps';
import { FormState, FieldState } from 'formstate';
import Section from 'components/Forms/Section';
import SectionItem from 'components/Forms/SectionItem';
import Input from 'components/Forms/Input';
import Form from 'components/Forms/Form';
import ActionButton from 'components/ActionButton';
import { isPositiveInteger, required } from 'helpers/FormValidation';
import { authStore, venueStore, navigationStore, UpdateVenueInterface } from 'stores';
import _ from 'lodash';
import RadioGroup from 'components/Forms/RadioGroup';
import SelectGroup from 'components/SelectGroup';
// import BookingClosed from 'components/BookingClosed';

@observer class CreateVenuePage extends React.Component<any> {

    form = new FormState({
        id: new FieldState<any>(null),
        lat: new FieldState<number | null>(null),
        lng: new FieldState<number | null>(null),
        name: new FieldState('').validators(required),
        info: new FieldState(null),
        groupId: new FieldState<null | string>(null),
        showBookingsLinkInHomeFeed: new FieldState(false),
        hasCourt: new FieldState(false),
        dailyBookingLimit: new FieldState(1).validators(isPositiveInteger)
    })

    constructor(props: any) {
        super(props);

        this.state = {
            lat: null,
            lng: null
        }
    }

    getVenueId() {
        return this.props.match.params.id || null;
    }

    async componentDidMount() {
        if (this.getVenueId()) {
            await venueStore.getVenue(this.getVenueId());
            const venue = _.find(venueStore.venues, { id: this.getVenueId() }) as Parse.Object;

            if (venue) {
                const form = this.form.$;

                form.id.reset(venue.id);
                form.lat.reset(venue.get('geoLocation')._latitude);
                form.lng.reset(venue.get('geoLocation')._longitude);
                form.name.reset(venue.get('name'));
                form.info.reset(venue.get('info'));
                form.showBookingsLinkInHomeFeed.reset(venue.get('showBookingsLinkInHomeFeed'));
                form.hasCourt.reset(venue.get('hasCourt'));
                form.dailyBookingLimit.reset(venue.get('daily_booking_limit'));
            }

        } else if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const { latitude, longitude } = position.coords;
                const form = this.form.$;
                form.lat.reset(latitude);
                form.lng.reset(longitude);
              },
              (err) => {
              }
            );
        } 
    }

    onMarkerPlaced = (lat: number, lng: number) => {
        const form = this.form.$;

        form.lat.reset(lat);
        form.lng.reset(lng);

    }

    onSubmit = async () => {
        const form = this.form.$;
        if (!form.lat.$ || !form.lng.$) {
            alert('No marker has been set on map');
            return;
        }

        if (form.id.$) {
            await venueStore.updateVenue({
                id: form.id.$,
                name: form.name.$,
                info: form.info.$,
                lat: form.lat.$,
                lng: form.lng.$,
                showBookingsLinkInHomeFeed: form.showBookingsLinkInHomeFeed.$,
                hasCourt: form.hasCourt.$,
                dailyBookingLimit: form.dailyBookingLimit.$
            } as UpdateVenueInterface);
        }
        else {
            const groupId = form.groupId.$;
            if (!groupId) {
                alert('Group is required');
                return;
            }

            const customerId = authStore.customer.id;
            await venueStore.createVenue(
                customerId,
                groupId,
                {
                    lat: form.lat.$,
                    lng: form.lng.$,
                    name: form.name.$,
                    info: form.info.$,
                    showBookingsLinkInHomeFeed: form.showBookingsLinkInHomeFeed.$,
                    hasCourt: form.hasCourt.$,
                    dailyBookingLimit: form.dailyBookingLimit.$
                });
        }

        navigationStore.push('/venues');

    }

    render() {
        const form = this.form.$;
        const isNewVenue = !!!this.getVenueId()

        return (
            <div className='w-full mb-20'>


                <Form onValidSubmit={this.onSubmit} formState={this.form}>
                    <>
                        <h2 className='text-18px font-medium text-white mb-16px'>
                            Click on map to place venue
                        </h2>

                        <div className='w-full bg-white rounded p-2'>
                            <GoogleMaps
                                lat={form.lat.value}
                                lng={form.lng.value}
                                showMarkerOnClick={true}
                                onMarkerPlaced={this.onMarkerPlaced}
                            />
                        </div>

                        <Section title='Venue details'>
                            <SectionItem
                                title='Name of venue'>
                                <Input fieldState={form.name} id='name' />
                            </SectionItem>

                            {
                                isNewVenue &&
                                <SectionItem
                                    title='Group'>
                                    <SelectGroup fieldState={form.groupId} />
                                </SectionItem>
                            }

                            <SectionItem
                                title='Info'>
                                <Input fieldState={form.info} id='info' textArea={true} />
                            </SectionItem>

                            <SectionItem
                                hideSeparator={false}
                                title='Show bookings link in homefeed'>
                                <RadioGroup
                                    fieldState={form.showBookingsLinkInHomeFeed}
                                    items={[
                                        {
                                            id: 'showbookings-no',
                                            name: 'No',
                                            value: false
                                        },
                                        {
                                            id: 'showbookings-yes',
                                            name: 'Yes',
                                            value: true
                                        }
                                    ]} />
                            </SectionItem>

                            <SectionItem
                                hideSeparator={true}
                                title='Will be used for booking system'>
                                <RadioGroup
                                    fieldState={form.hasCourt}
                                    items={[
                                        {
                                            id: 'hasCourt-no',
                                            name: 'No',
                                            value: false
                                        },
                                        {
                                            id: 'hasCourt-yes',
                                            name: 'Yes',
                                            value: true
                                        }
                                    ]} />
                            </SectionItem>

                            <SectionItem
                                title='Daily booking limit (per user)'>
                                <Input
                                    type='number'
                                    fieldState={form.dailyBookingLimit}
                                />
                            </SectionItem>

                            <div className='text-center w-full mt-8 pb-8'>
                                <ActionButton
                                    text='Save details'
                                    type='submit'
                                    width='wide'
                                    primary={true} />
                            </div>
                        </Section>

                        {/* <BookingClosed venueId={this.getVenueId()} /> */}

                    </>
                </Form>
            </div>
        )
    }

}

export default CreateVenuePage;