import * as React from 'react';
import { observer } from 'mobx-react';
import RowBox from '../RowBox';
import { activities as activityHelper } from 'helpers/ActivitiesHelper';
import { getPercentageChange } from 'helpers/NumbersHelper';


interface Props {
    activities: Object | null
}

@observer class ActivitiesStats extends React.Component<Props> {

    render() {
        const { activities } = this.props;
        if (!activities) return null;

        const data: any = [];

        Object.keys(activities).forEach((sportKey) => {
            const item = activities[sportKey];

            if (item.current > 0) {
                data.push({
                    value: item.current,
                    label: activityHelper[sportKey],
                    percentage: getPercentageChange(item.last, item.current)
                })
            }

        })

        return (
            <RowBox title='Activities' data={data} />
        )
    }

}

export default ActivitiesStats;