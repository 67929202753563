import * as React from 'react';
import { observer } from 'mobx-react';
import Dropdown from 'components/Forms/Dropdown';
import { FieldState } from 'formstate';

interface Props {
    fieldState: FieldState<any>,
    className?: string
}

export enum ActivityDeadlineEnum {
    TIME_OF_EVENT = "0",
    ONE_HOUR_BEFORE = "1hour",
    ONE_DAY_BEFORE = "1day",
    END_TIME = "endTime"
}

@observer class SelectDeadline extends React.Component<Props> {

    render() {
        const { fieldState, className } = this.props;

        const opts = [
            {
                value: ActivityDeadlineEnum.TIME_OF_EVENT,
                name: "Time of event"
            },
            {
                value: ActivityDeadlineEnum.ONE_HOUR_BEFORE,
                name: "1 hour before"
            },
            {
                value: ActivityDeadlineEnum.ONE_DAY_BEFORE,
                name: "1 day before"
            },
            {
                value: ActivityDeadlineEnum.END_TIME,
                name: "Time of game ending"
            }
        ]

        return (
            <Dropdown
                className={`Dropdown-light ${className || ''}`}
                fieldState={fieldState}
                skipSort={true}
                options={opts}
            />
        )
    }

}

export default SelectDeadline;