import * as React from 'react';
import { observer } from 'mobx-react';
import { authStore, CustomerRoleAccessAreaEnum } from 'stores';

import LogoImg from './logo.svg';
import TopProfile from './TopProfile';
import { sidebarMenuStore } from 'stores/SidebarMenuStore';
import { canOnlyAccess } from 'helpers/VerticalHelper';


@observer class Header extends React.Component {

    onClick = () => {
        authStore.logout();
    }

    render() {
        return (
            <header className='bg-header mx-auto hide-on-print'>
                <div className='flex items-center justify-between mx-auto max-w-dashboard'>
                    <img src={LogoImg} className='my-30px hidden xl:block' alt='ATLETO - Sport together' height='24' width='167' />
                    {
                        !canOnlyAccess(CustomerRoleAccessAreaEnum.BOOKINGS) &&
                        <button className='xl:hidden text-inactive ml-16px' onClick={() => sidebarMenuStore.setVisible(!sidebarMenuStore.visible)}>
                            {sidebarMenuStore.visible ? 'Hide menu' : 'Show menu'}
                        </button>
                    }

                    <div></div>

                    <TopProfile />
                </div>
            </header>
        )
    }

}

export default Header;