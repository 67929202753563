import * as React from 'react';
import { observer } from 'mobx-react';
import { GroupMemberStatusEnum, groupMemberStore } from 'stores';

import './style.css'

interface Props {
    memberStatus: string,
    memberId: string,
    className?: string
}

@observer class SelectMemberStatus extends React.Component<Props> {

    onChange(value: string) {
        groupMemberStore.setGroupMemberStatus(this.props.memberId, value);
    }

    onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
    }

    render() {
        const { memberStatus } = this.props;

        const options = [
            {
                value: GroupMemberStatusEnum.ADMIN,
                name: 'Administrator'
            },
            {
                value: GroupMemberStatusEnum.PENDING,
                name: 'Pending'
            },
            {
                value: GroupMemberStatusEnum.MEMBER,
                name: 'Member'
            }
        ]

        return (
            <div className='SelectMemberStatus w-full py-2 leading-normal rounded'>
                <select
                    onClick={this.onClick}
                    className='w-full text-darkest bg-transparent'
                    value={memberStatus || undefined}
                    onChange={(e) => this.onChange(e.target.value)}>
                    {
                        options.map((opt, index) => {
                            return <option value={opt.value} key={index} style={{ height: '42px' }}>{opt.name}</option>
                        })
                    }
                </select>
            </div>

        )
    }

}

export default SelectMemberStatus;